import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CapacityType, CAPA_METHODS_TYPE } from 'types/capacity';

export interface CapaMethodState {
  method: CapacityType;
}

export const initialState: CapaMethodState = {
  method: CAPA_METHODS_TYPE.PERSON_TAGE,
};

const capaMethodSlice = createSlice({
  name: 'capaMethod',
  initialState,
  reducers: {
    update(state, action: PayloadAction<CapacityType>) {
      state.method = action.payload;
    },
  },
});
export const { update } = capaMethodSlice.actions;
export default capaMethodSlice.reducer;
