import { createAsyncThunk } from '@reduxjs/toolkit';
import store from 'store';
import { update, add, clear } from './filteredHashTags.slice';
import { HashTags } from 'models/ProjectModel';

export { update, add, clear };

export const addFilteredHashTags = createAsyncThunk(
  'filteredHashTags/update',
  async (args: HashTags[]) => {
    store.dispatch(update(args));
  }
);
