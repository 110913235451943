import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ConfirmEmailModel from 'models/ConfirmEmailModel';

export interface ConfirmEmailStoreState {
  eMail: ConfirmEmailModel;
  isLoading: boolean;
  error: string | null;
}

export const initialState: ConfirmEmailStoreState = {
  eMail: {
    createdAt: '',
  },
  isLoading: false,
  error: null,
};

const confirmEmailSlice = createSlice({
  name: 'confirmEmail',
  initialState,
  reducers: {
    reset: () => initialState,
    error(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    loading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    success(state, action: PayloadAction<ConfirmEmailModel>) {
      state.eMail = action.payload;
    },
  },
});

export const { error, loading, success, reset } = confirmEmailSlice.actions;
export default confirmEmailSlice.reducer;
