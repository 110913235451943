import React, { Fragment } from 'react';
import styled from 'styled-components';
import CompletedForm from './CompletedForm';
import ConfirmEmailForm from './input-fields/ConfirmEmailForm';
import ProjectForm from './input-fields/CreateProjectForm';
import SprintForm from './input-fields/CreateSprintForm';
import UsersForm from './input-fields/UsersLimitForm';
import { VoucherForm } from './input-fields/VoucherForm';
import { useReduxState } from 'hooks/useRedux';

export const Onboarding: React.FC = () => {
  const {
    locale,
    actions: {
      createProjectOnboarding: { selectedIndex: sIndex },
      jiraDataUserLimit: { isJiraProject },
    },
  } = useReduxState();

  const selectedIndex = sIndex || 0;

  const jiraProjectBoard = () => {
    return (
      <Fragment>
        {selectedIndex === 4 && (
          <Title>{locale?.strings?.select_users_info}</Title>
        )}
        {selectedIndex === 4 && <UsersForm />}

        {selectedIndex === 5 && <CompletedForm />}
      </Fragment>
    );
  };

  const defaultProjectBoard = () => {
    return <Fragment>{selectedIndex === 4 && <CompletedForm />}</Fragment>;
  };

  return (
    <div>
      {selectedIndex === 0 && <VoucherForm />}

      {selectedIndex === 1 && (
        <Title>{locale?.strings?.create_new_project}</Title>
      )}
      {selectedIndex === 1 && <ProjectForm />}
      {selectedIndex === 2 && (
        <div>
          <div className="email-img-container">
            <div className="email-img-container__img"></div>
          </div>
          <ConfirmEmailForm />
        </div>
      )}

      {selectedIndex === 3 && (
        <Title>{locale?.strings?.need_sprint_info}</Title>
      )}
      {selectedIndex === 3 && <SprintForm />}

      {isJiraProject ? jiraProjectBoard() : defaultProjectBoard()}
    </div>
  );
};
const Title = styled.h2`
  margin-bottom: 1.2rem;
`;

export default Onboarding;
