import React from 'react';
import FilterIcon from '@atlaskit/icon/glyph/filter';
import HashTagModal from 'components/Modals/HashTagModal';
import HashTagModalFilter from 'components/Modals/HashTagModal/filter';
import Tooltip from '@atlaskit/tooltip';
import Button from '@atlaskit/button/standard-button';
import { useReduxState } from 'hooks/useRedux';
import { HashTags } from 'models/ProjectModel';
import { useDispatch } from 'react-redux';
import { addFilteredHashTags } from 'store/slice/filteredHashTags';

import { WithOnBoardingTutorialTarget } from 'components/onBoarding/tutorial';
import { onBoardingTutorial } from 'types/onBoardingTutorial';
import WatchFilledIcon from '@atlaskit/icon/glyph/watch-filled';
import AddIcon from '@atlaskit/icon/glyph/add';
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle';

interface HashTagIconProps {
  openHashTagModal: () => void;
}
const HashTagIcon = WithOnBoardingTutorialTarget(
  ({ openHashTagModal }: HashTagIconProps) => {
    return (
      <Tooltip content="Add Tags">
        <Button appearance="link" spacing="none" onClick={openHashTagModal}>
          <AddCircleIcon label="Add Events" size="small"></AddCircleIcon>
        </Button>
      </Tooltip>
    );
  },
  onBoardingTutorial.CREATEHASHTAG
);

const HashTag: React.FC = () => {
  const [openHasTagModal, setOpenHashTagModal] = React.useState<boolean>(false);

  const {
    project: { hashTags },
    filteredHashTags,
  } = useReduxState();

  const closeHashTagModal = () => {
    setOpenHashTagModal(false);
  };

  const openHashTagModal = () => {
    setOpenHashTagModal(true);
  };

  const [openHasTagModalFilter, setOpenHashTagModalFilter] =
    React.useState<boolean>(false);

  const closeHashTagModalFilter = () => {
    setOpenHashTagModalFilter(false);
  };

  const openHashTagModalFilter = () => {
    setOpenHashTagModalFilter(true);
  };

  const dispatch = useDispatch();

  const updateFilteredHashTags = (values: HashTags) => {
    const newTags = [...filteredHashTags.hashTags];

    const delIndex = newTags.findIndex((ht) => ht.id === values.id);

    if (delIndex > -1) {
      newTags.splice(delIndex, 1);
    } else {
      newTags.push(values);
    }

    dispatch(addFilteredHashTags(newTags));
  };

  const renderAllHashTags = () => {
    return hashTags.map((ht) => {
      const appearance = !filteredHashTags.hashTags?.some(
        (fht) => fht.id === ht.id
      )
        ? undefined
        : 'primary';

      return (
        <div key={ht.id}>
          <Button
            className="hashtag__btn"
            spacing="compact"
            appearance={appearance}
            onClick={() => updateFilteredHashTags(ht)}>
            #{ht.name}
          </Button>
        </div>
      );
    });
  };

  return (
    <div>
      <div className="capa-overview">
        <div className="capa-overview__title items-center">
          <div className="flex items-center">
            <h4 style={{ paddingRight: '6px' }}>Hashtags</h4>
            {/* <Button
              appearance="default"
              spacing="compact"
              onClick={openHashTagModal}
              iconBefore={
                <>
                  <AddIcon label="watch icon" size="small" /> /
                  <CrossIcon label="watch icon" size="small" />
                </>
              }>
              Tags
            </Button> */}
            <HashTagIcon openHashTagModal={openHashTagModal} />
            {openHasTagModal && <HashTagModal close={closeHashTagModal} />}
          </div>
          {/* <Tooltip content="Filter">
            <Button
              appearance="primary"
              spacing="none"
              onClick={openHashTagModalFilter}>
              <FilterIcon label="filter icon" size="small" />
            </Button>
          </Tooltip>
          {openHasTagModalFilter && (
            <HashTagModalFilter close={closeHashTagModalFilter} />
          )} */}
        </div>

        <div className="capa-overview-table">
          <div className="hashtag__list">{renderAllHashTags()}</div>

          {/* <div className="capa-overview__block">{renderAllHashTags()}</div> */}
        </div>

        {/* <div className="capa-overview-table">
          <div className="capa-overview--head">
            <span>Name</span>
            <span>{renderTotalText}</span>
          </div>
          <div className="capa-overview__block">{renderHashtags()}</div>
        </div> */}
      </div>
    </div>
  );
};

export default HashTag;
