/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {
  Spotlight,
  SpotlightManager,
  SpotlightTarget,
  SpotlightTransition,
} from '@atlaskit/onboarding';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import { onBoardingTutorial } from 'types/onBoardingTutorial';
import { useReduxState } from 'hooks/useRedux';
import { N0 } from '@atlaskit/theme/colors';
import { EventBus, GET_STARTED_TUTORIAL } from 'Event';

import './onBoardingTutorial.scss';

const TOTAL_FEATURES = 7;
const WithOnBoardingTutorial = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const WithOnBoardingTutorial: React.FC<P> = (props) => {
    const [activeSpotlight, setActiveSpotlight] = React.useState<null | number>(
      null
    );
    const {
      locale,
      project: { users },
    } = useReduxState();

    const end = () => {
      setActiveSpotlight(null);
    }

      const start = () => setActiveSpotlight(0);
      const next = () => setActiveSpotlight((activeSpotlight || 0) + 1);
      const back = () => setActiveSpotlight((activeSpotlight || 1) - 1);

    React.useEffect(() => {
      // attaching the tutorial start function on event bus
      // so that it can be called from other places by emiting the events
      EventBus.on(GET_STARTED_TUTORIAL.FIRSTOPEN, start);
      // cleanup
      return function removeEvents() {
        EventBus.remove(GET_STARTED_TUTORIAL.FIRSTOPEN);
      };
    }, []);


    const renderActiveSpotlight = () => {
      let spotlights = [
        <Spotlight
          actionsBeforeElement={`1/${TOTAL_FEATURES - 1}`}
          actions={[
            {
              onClick: () => next(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_next,
            },
            {
              onClick: () => end(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_dismiss,
              appearance: 'subtle',
            },
          ]}
          heading={locale?.strings?.create_new_user}
          target={onBoardingTutorial.CREATENEWUSER}
          key={onBoardingTutorial.CREATENEWUSER}
          targetRadius={3}
          targetBgColor={N0}>
          {locale?.strings?.on_boarding_tutorial_add_new_team_member}
        </Spotlight>,
        <Spotlight
          actionsBeforeElement={`2/${TOTAL_FEATURES - 1}`}
          actions={[
            {
              onClick: () => next(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_next,
            },
            {
              onClick: () => back(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_back,
              appearance: 'subtle',
            },
            {
              onClick: () => end(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_dismiss,
              appearance: 'subtle',
            },
          ]}
          heading={locale?.strings?.add_sprint_goal}
          target={onBoardingTutorial.SPRINTGOAL}
          key={onBoardingTutorial.SPRINTGOAL}
          targetRadius={3}
          targetBgColor={N0}>
          {locale?.strings?.on_boarding_tutorial_add_sprint_goal}
        </Spotlight>,
        <Spotlight
          actionsBeforeElement={`3/${TOTAL_FEATURES - 1}`}
          actions={[
            {
              onClick: () => next(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_next,
            },
            {
              onClick: () => back(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_back,
              appearance: 'subtle',
            },
            {
              onClick: () => end(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_dismiss,
              appearance: 'subtle',
            },
          ]}
          heading={locale?.strings?.add_reference_tickets}
          target={onBoardingTutorial.TICKETREFERENCE}
          key={onBoardingTutorial.TICKETREFERENCE}
          targetRadius={3}
          targetBgColor={N0}>
          {locale?.strings?.on_boarding_tutorial_reference_tickets}
        </Spotlight>,
        <Spotlight
          actionsBeforeElement={`4/${TOTAL_FEATURES - 1}`}
          actions={[
            {
              onClick: () => next(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_next,
            },
            {
              onClick: () => back(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_back,
              appearance: 'subtle',
            },
            {
              onClick: () => end(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_dismiss,
              appearance: 'subtle',
            },
          ]}
          heading={locale?.strings?.add_events}
          target={onBoardingTutorial.CREATEEVENT}
          key={onBoardingTutorial.CREATEEVENT}
          targetRadius={3}
          targetBgColor={N0}>
          {locale?.strings?.on_boarding_tutorial_add_events}
        </Spotlight>,
        <Spotlight
          actionsBeforeElement={`5/${TOTAL_FEATURES - 1}`}
          actions={[
            {
              onClick: () => next(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_next,
            },
            {
              onClick: () => back(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_back,
              appearance: 'subtle',
            },
            {
              onClick: () => end(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_dismiss,
              appearance: 'subtle',
            },
          ]}
          heading={locale?.strings?.add_hashtags}
          target={onBoardingTutorial.CREATEHASHTAG}
          key={onBoardingTutorial.CREATEHASHTAG}
          targetRadius={3}
          targetBgColor={N0}>
          {locale?.strings?.on_boarding_tutorial_add_hashtags}
        </Spotlight>,
        <Spotlight
          actionsBeforeElement={`6/${TOTAL_FEATURES - 1}`}
          actions={[
            {
              onClick: () => end(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_end,
              appearance: 'subtle',
            },
            {
              onClick: () => back(),
              text: locale?.strings?.on_boarding_tutorial_tutorial_back,
              appearance: 'subtle',
            },
          ]}
          heading={locale?.strings?.hashtag_overview}
          target={onBoardingTutorial.HASHTAGOVERVIEW}
          key={onBoardingTutorial.HASHTAGOVERVIEW}
          targetRadius={3}
          targetBgColor={N0}>
          {locale?.strings?.on_boarding_tutorial_overview_hashtags}
        </Spotlight>,
      ];
      if (users?.length > 1) {
        // in case there are no any team members
        // there is always at least one user which is admin
        // so for cases where there is at least one user
        // add tutorial for updating the slots of the user
        spotlights = [
          <Spotlight
            actionsBeforeElement={`1/${TOTAL_FEATURES}`}
            actions={[
              {
                onClick: () => next(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_next,
              },
              {
                onClick: () => end(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_dismiss,
                appearance: 'subtle',
              },
            ]}
            heading="Add Team Members"
            target={onBoardingTutorial.ADDNEWMEMBER}
            key={onBoardingTutorial.ADDNEWMEMBER}
            targetRadius={3}
            targetBgColor={N0}>
            {locale?.strings?.on_boarding_tutorial_add_new_team_member}
          </Spotlight>,
          <Spotlight
            actionsBeforeElement={`2/${TOTAL_FEATURES}`}
            actions={[
              {
                onClick: () => next(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_next,
              },
              {
                onClick: () => back(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_back,
                appearance: 'subtle',
              },
              {
                onClick: () => end(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_dismiss,
                appearance: 'subtle',
              },
            ]}
            heading={locale?.strings?.update_capacity}
            target={onBoardingTutorial.UPDATE_CAPACITY}
            key={onBoardingTutorial.UPDATE_CAPACITY}
            targetRadius={3}
            targetBgColor={N0}>
            {locale?.strings?.on_boarding_tutorial_update_capacity}
          </Spotlight>,
          <Spotlight
            actionsBeforeElement={`3/${TOTAL_FEATURES}`}
            actions={[
              {
                onClick: () => next(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_next,
              },
              {
                onClick: () => back(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_back,
                appearance: 'subtle',
              },
              {
                onClick: () => end(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_dismiss,
                appearance: 'subtle',
              },
            ]}
            heading={locale?.strings?.add_sprint_goal}
            target={onBoardingTutorial.SPRINTGOAL}
            key={onBoardingTutorial.SPRINTGOAL}
            targetRadius={3}
            targetBgColor={N0}>
            {locale?.strings?.on_boarding_tutorial_add_sprint_goal}
          </Spotlight>,
          <Spotlight
            actionsBeforeElement={`4/${TOTAL_FEATURES}`}
            actions={[
              {
                onClick: () => next(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_next,
              },
              {
                onClick: () => back(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_back,
                appearance: 'subtle',
              },
              {
                onClick: () => end(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_dismiss,
                appearance: 'subtle',
              },
            ]}
            heading={locale?.strings?.add_reference_tickets}
            target={onBoardingTutorial.TICKETREFERENCE}
            key={onBoardingTutorial.TICKETREFERENCE}
            targetRadius={3}
            targetBgColor={N0}>
            {locale?.strings?.on_boarding_tutorial_reference_tickets}
          </Spotlight>,
          <Spotlight
            actionsBeforeElement={`5/${TOTAL_FEATURES}`}
            actions={[
              {
                onClick: () => next(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_next,
              },
              {
                onClick: () => back(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_back,
                appearance: 'subtle',
              },
              {
                onClick: () => end(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_dismiss,
                appearance: 'subtle',
              },
            ]}
            heading={locale?.strings?.add_events}
            target={onBoardingTutorial.CREATEEVENT}
            key={onBoardingTutorial.CREATEEVENT}
            targetRadius={3}
            targetBgColor={N0}>
            {locale?.strings?.on_boarding_tutorial_add_events}
          </Spotlight>,
          <Spotlight
            actionsBeforeElement={`6/${TOTAL_FEATURES}`}
            actions={[
              {
                onClick: () => next(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_next,
              },
              {
                onClick: () => back(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_back,
                appearance: 'subtle',
              },
              {
                onClick: () => end(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_dismiss,
                appearance: 'subtle',
              },
            ]}
            heading={locale?.strings?.add_hashtags}
            target={onBoardingTutorial.CREATEHASHTAG}
            key={onBoardingTutorial.CREATEHASHTAG}
            targetRadius={3}
            targetBgColor={N0}>
            {locale?.strings?.on_boarding_tutorial_add_hashtags}
          </Spotlight>,
          <Spotlight
            actionsBeforeElement={`7/${TOTAL_FEATURES}`}
            actions={[
              {
                onClick: () => end(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_end,
                appearance: 'subtle',
              },
              {
                onClick: () => back(),
                text: locale?.strings?.on_boarding_tutorial_tutorial_back,
                appearance: 'subtle',
              },
            ]}
            heading={locale?.strings?.hashtag_overview}
            target={onBoardingTutorial.HASHTAGOVERVIEW}
            key={onBoardingTutorial.HASHTAGOVERVIEW}
            targetRadius={3}
            targetBgColor={N0}>
            {locale?.strings?.on_boarding_tutorial_overview_hashtags}
          </Spotlight>,
        ];
      }

      if (activeSpotlight === null) {
        return null;
      }

      return spotlights[activeSpotlight];
    };
    return (
      <SpotlightManager>
        <div className="getting-started" onClick={() => start()}>
          <span className="flex items-center content-center">
            <p
              style={{
                transform: 'translateY(1px)',
                marginRight: '5px',
              }}>
              <QuestionCircleIcon
                label="Question icon"
                primaryColor="#E53030"
                size="small"
              />
            </p>
            {locale?.strings?.getting_started}
          </span>
        </div>
        <SpotlightTransition>{renderActiveSpotlight()}</SpotlightTransition>
        <WrappedComponent {...props} />
      </SpotlightManager>
    );
  };
  WithOnBoardingTutorial.displayName = `WithOnBoardingTutorial(${WrappedComponent.name})`;
  return WithOnBoardingTutorial;
};

export const WithOnBoardingTutorialTarget = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  name: string
) => {
  const WithOnBoardingTarget: React.FC<P> = (props) => {
    return (
      <SpotlightTarget name={name}>
        <WrappedComponent {...props} />
      </SpotlightTarget>
    );
  };
  WithOnBoardingTarget.displayName = `WithOnBoardingTarget(${WrappedComponent.name})`;
  return WithOnBoardingTarget;
};

export default WithOnBoardingTutorial;
