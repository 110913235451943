import Button, { LoadingButton } from '@atlaskit/button';
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle';
import Tooltip from '@atlaskit/tooltip';
import Modal, { Header, Body, Footer, ModalBackDrop } from 'lib/Modal';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { useDispatch } from 'react-redux';
import { useReduxState } from 'hooks/useRedux';
import {
  updateSprintCalculationTable,
  updateSprintReference,
} from 'store/slice/project';
import Textfield from '@atlaskit/textfield';
import React, { Fragment, useState } from 'react';
import '../ModalStyle.scss';
import Form, { Field, ErrorMessage } from '@atlaskit/form';
import Select from '@atlaskit/select';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Spinner from '@atlaskit/spinner';

export interface Props {
  close: () => void;
  sprintId: number;
}
export interface ReferenceInterface {
  ref: string;
  SP: number;
  edit?: boolean;
}

const MIN_REFRERENCE = 3;
const MAX_REFRERENCE = 6;
const DEFAULT_REFERENCE_VALUE = { ref: '', SP: 0, edit: false };

const FIB_SERIES = [0.5, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144];

const maxFibOptions = FIB_SERIES.map((n) => {
  return { label: n, value: n };
});

const sprintReferenceToJSON = (val: string) => {
  try {
    return JSON.parse(val);
  } catch (err) {
    return [];
  }
};

export const ReferenceModal: React.FC<Props> = (props) => {
  const { close, sprintId } = props;

  const {
    project: {
      project: { sprintReference, projectLink },
      sprints,
    },
    sprintEfficiencyAndCalculation,
    locale,
  } = useReduxState();

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const changeEditCalculation = () => setEdit(!edit);

  const dispatch = useDispatch();

  const sprint = sprints.find((spr) => spr.id === sprintId);

  const sprintCalcultionDetails = sprintEfficiencyAndCalculation.sprints.find(
    (spr) => spr.id === sprintId
  );

  const maxStoryPoints = sprintCalcultionDetails?.maxStoryPoints;
  const maxPersonDays = sprintCalcultionDetails?.maxPersonDays;
  const renderText = () => {
    if (!sprint || !sprint.sprintGoal) return <p>{locale?.strings?.no_sprint_goal}!</p>;
    return sprint.sprintGoal
      ?.split?.('\n')
      ?.map((str: string, index: number) => {
        return <p key={`${sprintId}_${index}`}>{str}</p>;
      });
  };
  const sprintReferenceJSON = sprintReferenceToJSON(sprintReference);

  const renderStoryPoints = () => {
    if (!maxStoryPoints || !maxPersonDays) return null;
    return FIB_SERIES.filter((e) => e <= maxStoryPoints).map((e) => {
      return <td key={e}>{e}</td>;
    });
  };

  const renderPersonDays = () => {
    if (!maxStoryPoints || !maxPersonDays) return null;
    return FIB_SERIES.filter((e) => e <= maxStoryPoints).map((e) => {
      return (
        <td key={e}>
          {Math.round(((e * maxPersonDays) / maxStoryPoints) * 100) / 100}
        </td>
      );
    });
  };

  const initialStateAfterReference = () => {
    if (sprintReferenceJSON?.length > MIN_REFRERENCE) {
      return sprintReferenceJSON?.map?.((e: ReferenceInterface) => {
        return { ...e, edit: false };
      });
    } else {
      return Array(MIN_REFRERENCE)
        .fill(0)
        ?.map((item: number, index: number) => {
          if (sprintReferenceJSON?.[index]?.ref) {
            return { ...sprintReferenceJSON?.[index], edit: false };
          }
          return { ...DEFAULT_REFERENCE_VALUE };
        });
    }
  };

  const [referenceData, setReferenceData] = useState<Array<ReferenceInterface>>(
    initialStateAfterReference()
  );

  const changeEdit = (index: number) => {
    setReferenceData((prevState: ReferenceInterface[]) => {
      const oldData = [...prevState];
      oldData[index].edit = !oldData[index].edit;
      return oldData;
    });
  };

  const onChange = (e: any, index: number) => {
    const oldData = [...referenceData];

    if (e?.target?.name === 'ref') {
      oldData[index].ref = e?.target?.value;
    } else {
      oldData[index].SP = e?.target?.value;
    }
    oldData[index].edit = true;
    setReferenceData(oldData);
  };

  const addReference = () => {
    if (referenceData?.length < MAX_REFRERENCE) {
      setReferenceData((prevState: ReferenceInterface[]) => {
        return [...prevState, { ...DEFAULT_REFERENCE_VALUE }];
      });
    }
  };

  const saveData = async () => {
    const submitData = referenceData
      .filter((e: ReferenceInterface) => {
        if (e?.ref && e?.SP) {
          return { ref: e.ref, SP: e.SP };
        }
        return null;
      })
      .map((e: ReferenceInterface) => {
        return { ref: e.ref, SP: e.SP };
      });

    dispatch(await updateSprintReference(JSON.stringify(submitData)));

    setReferenceData((prevState: ReferenceInterface[]) => {
      return prevState?.map?.((e: ReferenceInterface) => {
        return { ...e, edit: false };
      });
    });
  };

  const renderTableBody = () => {
    return referenceData?.map?.((e: ReferenceInterface, index: number) => {
      const isEdit = e?.edit || !e?.ref;

      return (
        <tr key={index}>
          <td>
            {isEdit ? (
              <Textfield
                // autoFocus
                className="input-small input-border--none"
                name="ref"
                defaultValue={e?.ref}
                onChange={(e: any) => onChange(e, index)}
                placeholder={locale?.strings?.add_ticket_reference}
                aria-label="Sprint Reference"
              />
            ) : (
              <div>{e?.ref || locale?.strings?.add_ticket_reference}</div>
            )}
          </td>
          <td align="center">
            {/* {e?.SP} */}

            {isEdit ? (
              <Textfield
                // autoFocus
                type="number"
                className="input-small input-border--none input-text--center"
                name="SP"
                placeholder={locale?.strings?.add_story_points}
                aria-label="Story Points"
                defaultValue={e?.SP ? e?.SP : undefined}
                onChange={(e: any) => onChange(e, index)}
              />
            ) : (
              <div>{e?.SP || locale?.strings?.add_story_points}</div>
            )}
          </td>
          <td align="center">
            {isEdit ? (
              <Tooltip content="Save">
                <Button
                  className="pos-inherit"
                  spacing="none"
                  onClick={saveData}>
                  <CheckIcon label={'CheckIcon'} size={'small'}></CheckIcon>
                </Button>
              </Tooltip>
            ) : (
              <Tooltip content="Edit">
                <Button
                  className="pos-inherit"
                  spacing="none"
                  onClick={() => changeEdit(index)}>
                  <EditFilledIcon
                    label={'EditFilledIcon'}
                    size={'small'}></EditFilledIcon>
                </Button>
              </Tooltip>
            )}
          </td>
        </tr>
      );
    });
  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    await dispatch(
      updateSprintCalculationTable({
        sprintId,
        maxStoryPoints: values.maxStoryPoints.value,
        maxPersonDays: Number(values.maxPersonDays),
      })
    );
    setLoading(false);
  };

  return (
    <Modal onClose={close}>
      <ModalBackDrop onClick={close}>
        <Header>
          <h3>{locale?.strings?.ticket_reference}</h3>
        </Header>
        <Body>
          <div className="ref-block">
            <Form onSubmit={onSubmit}>
              {({ formProps }) => (
                <form {...formProps}>
                  <div className="ref-table">
                    <div className="ref-table__values flex">
                      <div className="flex">
                        <div className="ref-table__values-item">
                          <Field
                            isRequired
                            label={'Max Story Points'}
                            name="maxStoryPoints"
                            isDisabled={!edit}
                            defaultValue={maxFibOptions.find(
                              (n) => n.value === maxStoryPoints
                            )}>
                            {({ fieldProps, error }: any) => {
                              return (
                                <Fragment>
                                  <Select
                                    {...fieldProps}
                                    placeholder={'Person Days'}
                                    options={maxFibOptions}
                                  />
                                  {error === 'EMPTY' && (
                                    <ErrorMessage>
                                      {
                                        locale?.strings
                                          ?.validation_iteration_length
                                      }
                                    </ErrorMessage>
                                  )}
                                </Fragment>
                              );
                            }}
                          </Field>
                        </div>
                        <div className="ref-table__values-item">
                          <Field
                            isDisabled={!edit}
                            isRequired
                            label={'Person Days'}
                            name="maxPersonDays"
                            defaultValue={maxPersonDays}>
                            {({ fieldProps, error }) => (
                              <Fragment>
                                <Textfield type="number" {...fieldProps} />
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                              </Fragment>
                            )}
                          </Field>
                        </div>
                      </div>

                      {!edit && (
                        <div className="ref-table__values-item">
                          <Tooltip content="Edit">
                            <Button
                              onClick={changeEditCalculation}
                              style={{
                                transform: 'translateY(-3px)',
                              }}
                              spacing="none">
                              <EditFilledIcon
                                label={'EditFilledIcon'}
                                size={'small'}></EditFilledIcon>
                            </Button>
                          </Tooltip>
                        </div>
                      )}

                      {loading && <Spinner size="small" />}

                      {edit && !loading && (
                        <>
                          <div className="ref-table__values-item">
                            <Tooltip content="Save">
                              <LoadingButton
                                isLoading={loading}
                                type="submit"
                                style={{
                                  transform: 'translateY(-3px)',
                                }}
                                spacing="none">
                                <CheckIcon
                                  label={'CheckIcon'}
                                  size={'small'}></CheckIcon>
                              </LoadingButton>
                            </Tooltip>
                          </div>

                          <div className="ref-table__values-item">
                            <Tooltip content="Cancel">
                              <Button
                                onClick={changeEditCalculation}
                                style={{
                                  transform: 'translateY(-3px)',
                                }}
                                spacing="none">
                                <CrossIcon
                                  label={'CrossIcon'}
                                  size={'small'}></CrossIcon>
                              </Button>
                            </Tooltip>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              )}
            </Form>

            <div className="ref-table">
              <table className="c-table c-table--bordered">
                <tr>
                  <th>Story Points (SP)</th>
                  {renderStoryPoints()}
                </tr>
                <tr>
                  <th>Person Days (PD)</th>
                  {renderPersonDays()}
                </tr>
              </table>
            </div>
            <div className="ref-info">
              <h3>{locale?.strings?.ticket_reference_note}:</h3>
              <p>{locale?.strings?.ticket_reference_notes}</p>
              <div className="ref-info__url">
                <h4>{locale?.strings?.ticket_reference_add_info}</h4>
                <div className="ref-info__url-sprint">
                  <div className="ref-info__url-btn">
                    <h4>{locale?.strings?.ticket_reference} Url</h4>

                    <Tooltip content="Add More Reference">
                      <Button
                        onClick={addReference}
                        spacing="none"
                        appearance="subtle-link">
                        <AddCircleIcon
                          label={'AddCircleIcon'}
                          size={'small'}></AddCircleIcon>
                      </Button>
                    </Tooltip>
                  </div>
                </div>

                <table className="c-table c-table--bordered">
                  <thead>
                    <tr>
                      <th align="left">URL</th>
                      <th>Story Points</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableBody()}</tbody>
                </table>
              </div>

              <div className="ref-info__url">
                <div className="ref-info__url-sprint">
                  <div className="ref-info__url-btn">
                    <h4>{locale?.strings?.sprint_goal}</h4>
                  </div>
                  {renderText()}
                </div>
              </div>
            </div>
          </div>
        </Body>
        <Footer>
          <span></span>
          <Button appearance="default" onClick={close}>
            {locale?.strings?.button_close}
          </Button>
        </Footer>
      </ModalBackDrop>
    </Modal>
  );
};
