import { createAsyncThunk } from '@reduxjs/toolkit';
import store from 'store';
import * as jiraService from 'services/jiradata.service';
import { error as globalError } from '../globalError/globaleError.slice';
import {
  create,
  createJiraCode,
  error,
  loading,
  updateJiraProject,
  createJiraSprintData,
  createJiraAuthenticationRedirectUrl,
  jiraProject,
  allJiraUsers,
} from './jiraData.slice';
import { next } from 'store/slice/createProjectOnboarding/createProjectOnboarding.slice';

export type jiraAuthCode = {
  code: string | null;
  state: string | null;
};

export type JiraProjectDetails = {
  projectId: string;
  projectKey: string;
  projectName: string | null;
};

export type JiraPostUsers = {
  displayName: string;
  emailAddress: string;
  accountId: string;
};

export const getJiraAuthUrl = createAsyncThunk(
  'jireAuthUrl/fetch',
  async () => {
    try {
      store.dispatch(error(false));
      store.dispatch(loading(true));
      const data = await jiraService.getJiraAuthUrl();
      store.dispatch(createJiraAuthenticationRedirectUrl(data));
    } catch (err: any) {
      store.dispatch(error(true));
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const jiraCode = createAsyncThunk(
  'jira/code',
  async (args: jiraAuthCode) => {
    try {
      const data = await jiraService.getJiraProjects(args);
      store.dispatch(create(data));
      store.dispatch(createJiraCode(args));
    } catch (err: any) {
      store.dispatch(error(true));
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const updateJiraProjectDetails = createAsyncThunk(
  'jira/project/details',
  async (args: JiraProjectDetails) => {
    try {
      const state =
        store.getState().global.actions.jiraDataUserLimit.jiraCode.state;
      const data = await jiraService.getprojectDetails(args, state);
      store.dispatch(updateJiraProject(data));
      store.dispatch(jiraProject(true));
    } catch (err: any) {
      store.dispatch(error(true));
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const getJiraProjectSprintData = createAsyncThunk(
  'jira/project/sprint/data',
  async () => {
    try {
      const state =
        store.getState().global.actions.jiraDataUserLimit.jiraCode.state;
      const data = await jiraService.getSprintData(state);
      store.dispatch(createJiraSprintData(data));
    } catch (err: any) {
      store.dispatch(error(true));
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const getJiraUser = createAsyncThunk(
  'jira/project/sprint/user',
  async () => {
    try {
      const state =
        store.getState().global.actions.jiraDataUserLimit.jiraCode.state;
      const projectId =
        store.getState().global.actions.createProject.project.id;
      const data = await jiraService.getJiraUser(state, projectId);
      store.dispatch(allJiraUsers(data));
    } catch (err: any) {
      store.dispatch(error(true));
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const postJiraUsers = createAsyncThunk(
  'jira/project/users/limit',
  async (args: any) => {
    try {
      store.dispatch(error(false));
      store.dispatch(loading(true));
      const projectId =
        store.getState().global.actions.createProject.project.id;
      await jiraService.postJiraUsers(args, projectId);
      store.dispatch(next(true));
    } catch (err: any) {
      store.dispatch(error(true));
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);
