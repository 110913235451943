import React from 'react';

const color = '#253858';
export const zeroCapacity = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-209.000000, -153.000000)"
        fill={color}>
        <path
          d="M221,153 C227.627417,153 233,158.372583 233,165 C233,171.627417 227.627417,177 221,177 C214.372583,177 209,171.627417 209,165 C209,158.372583 214.372583,153 221,153 Z M221,156.75 C216.443651,156.75 212.75,160.443651 212.75,165 C212.75,169.556349 216.443651,173.25 221,173.25 C225.556349,173.25 229.25,169.556349 229.25,165 C229.25,160.443651 225.556349,156.75 221,156.75 Z"
          id="Combined-Shape"></path>
      </g>
    </g>
  </svg>
);

export const halfCapacity = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <title>Combined Shape</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-127.000000, -153.000000)"
        fill={color}>
        <path
          d="M139,153 C145.627417,153 151,158.372583 151,165 C151,171.627417 145.627417,177 139,177 C132.372583,177 127,171.627417 127,165 C127,158.372583 132.372583,153 139,153 Z M140.623785,156.146098 L140.623785,173.853902 C144.819292,173.089435 148,169.416165 148,165 C148,160.583835 144.819292,156.910565 140.623785,156.146098 Z"
          id="Combined-Shape"></path>
      </g>
    </g>
  </svg>
);

export const fullCapacity = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-41.000000, -153.000000)"
        fill={color}>
        <circle id="Oval" cx="53" cy="165" r="12"></circle>
      </g>
    </g>
  </svg>
);

export const blockedCapacity = () => (
  <svg width="24px" height="4px" viewBox="0 0 24 4" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-161.000000, -164.000000)"
        fill={color}>
        <rect
          id="Rectangle"
          x="161"
          y="164"
          width="24"
          height="4"
          rx="2"></rect>
      </g>
    </g>
  </svg>
);

export const emojiOne = () => (
  <svg
    width="24px"
    height="4px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512">
    <circle cx="256" cy="256" r="256" fill="#ffd93b" />
    <path
      d="M512 256c0 141.44-114.64 256-256 256-80.48 0-152.32-37.12-199.28-95.28 43.92 35.52 99.84 56.72 160.72 56.72 141.36 0 256-114.56 256-256 0-60.88-21.2-116.8-56.72-160.72C474.8 103.68 512 175.52 512 256z"
      fill="#f4c534"
    />
    <path
      d="M413.904 136.048c20.752 14.512 34.352 38.512 34.352 65.696 0 44.432-35.792 80.224-80.224 80.224-50 0-88.48-45.472-78.656-95.632 23.984-27.824 90.24-48.864 124.528-50.288z"
      fill="#fff"
    />
    <g fill="#3e4347">
      <path d="M282.8 183.776l-11.472-18.496a10.789 10.789 0 013.488-14.88c5.056-3.136 11.744-1.584 14.864 3.488l5.44 8.752c37.408-24.384 88.48-36.08 139.312-31.36 5.936.544 10.32 5.808 9.76 11.744-.544 5.936-5.76 10.32-11.728 9.76-18.72-1.728-83.84-4.384-133.92 33.856-5.056 3.872-12.384 2.592-15.744-2.864z" />
      <circle cx="364.368" cy="231.568" r="34.992" />
    </g>
    <g fill="#fff">
      <path d="M348.096 213.184c-3.936 4.208-5.12 9.584-2.736 11.952 2.496 2.256 7.712.944 11.648-3.264 4.064-4.064 4.992-9.456 2.48-11.968-2.48-2.288-7.568-.816-11.392 3.28zM98.096 136.048c-20.752 14.512-34.352 38.512-34.352 65.696 0 44.432 35.792 80.224 80.224 80.224 50 0 88.48-45.472 78.656-95.632-23.984-27.824-90.24-48.864-124.528-50.288z" />
    </g>
    <g fill="#3e4347">
      <path d="M213.456 186.656c-50.016-38.24-115.088-35.6-133.92-33.856-5.968.512-11.184-3.84-11.728-9.776-.544-5.952 3.824-11.2 9.76-11.744 50.784-4.672 101.904 6.992 139.312 31.36l5.44-8.752c3.104-5.056 9.76-6.656 14.864-3.488 5.072 3.152 6.64 9.808 3.488 14.88L229.2 183.776c-3.376 5.472-10.704 6.72-15.744 2.88z" />
      <circle cx="147.632" cy="231.568" r="34.992" />
    </g>
    <path
      d="M163.904 213.184c3.936 4.208 5.12 9.584 2.736 11.952-2.496 2.256-7.712.944-11.648-3.264-4.064-4.064-4.992-9.456-2.48-11.968 2.48-2.288 7.568-.816 11.392 3.28z"
      fill="#fff"
    />
    <path
      d="M360.56 419.776c-69.632-13.056-139.44-13.056-209.232 0-8.368 1.744-15.44-6.624-13.184-15.68 16.88-63.744 62.048-97.68 116.736-97.68s102.16 33.936 118.88 97.856c2.24 8.88-4.848 17.232-13.2 15.504z"
      fill="#3e4347"
    />
    <g fill="#fff">
      <path d="M328.976 331.776c-20.88-16.72-46.56-25.376-74.112-25.376-27.856 0-53.184 8.912-73.616 25.984 2.608 3.696 6.224 6.8 10.768 8.288 41.904 13.488 83.792 13.488 125.696 0 4.816-1.568 8.656-4.896 11.264-8.896zM337.312 415.952c-1.888-6.992-4.096-13.968-7.008-20.944-2.464-5.824-7.056-10.464-12.592-12.64-41.904-16.128-83.792-16.128-125.696 0-5.52 2.16-10.128 6.816-12.592 12.64-2.96 7.104-5.216 14.208-7.104 21.328 55.04-8.24 110.064-8.384 164.992-.384z" />
    </g>
    <path
      d="M270.368 446.16c-8.208-2.24-17.776-2.208-29.36.16-5.296 1.12-10.672-2.368-11.792-7.792-1.104-5.408 2.384-10.688 7.792-11.792 14.672-3.008 27.328-2.96 38.64.16 5.328 1.456 8.464 6.96 7.008 12.288-1.472 5.328-7.008 8.448-12.288 6.976z"
      fill="#f4c534"
    />
  </svg>
);

export const emojiTwo = () => (
  <svg
    width="24px"
    height="4px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512">
    <circle cx="256" cy="256" r="256" fill="#ffd93b" />
    <path
      d="M512 256c0 141.44-114.64 256-256 256-80.48 0-152.32-37.12-199.28-95.28 43.92 35.52 99.84 56.72 160.72 56.72 141.36 0 256-114.56 256-256 0-60.88-21.2-116.8-56.72-160.72C474.8 103.68 512 175.52 512 256z"
      fill="#f4c534"
    />
    <g fill="#3e4347">
      <path d="M170.864 404.24c-7.344-4.912-9.312-14.848-4.4-22.192 37.056-55.36 94.24-55.36 173.392-55.36 8.832 0 16 7.168 16 16s-7.168 16-16 16c-80.528 0-120.416 1.744-146.8 41.168-4.944 7.36-14.88 9.264-22.192 4.384z" />
      <circle cx="163.2" cy="213.712" r="41.056" />
    </g>
    <path
      d="M186.416 206.576c-2.848 2.688-8.224 1.712-11.664-2.112-3.552-3.808-4.16-9.216-1.168-11.824 2.96-2.72 8.208-1.824 11.76 2 3.568 3.92 4.032 9.232 1.072 11.936z"
      fill="#5a5f63"
    />
    <circle cx="348.864" cy="213.712" r="41.056" fill="#3e4347" />
    <path
      d="M372.08 206.576c-2.848 2.688-8.224 1.712-11.664-2.112-3.552-3.808-4.16-9.216-1.168-11.824 2.96-2.72 8.208-1.824 11.76 2 3.568 3.92 4.032 9.232 1.072 11.936z"
      fill="#5a5f63"
    />
  </svg>
);

export const emojiThree = () => (
  <svg
    width="24px"
    height="4px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512">
    <circle cx="256" cy="256" r="256" fill="#ffd93b" />
    <path
      d="M512 256c0 141.44-114.64 256-256 256-80.48 0-152.32-37.12-199.28-95.28 43.92 35.52 99.84 56.72 160.72 56.72 141.36 0 256-114.56 256-256 0-60.88-21.2-116.8-56.72-160.72C474.8 103.68 512 175.52 512 256z"
      fill="#f4c534"
    />
    <g fill="#3e4347">
      <path d="M370.56 344.4c0 7.696-6.224 13.92-13.92 13.92H155.344c-7.616 0-13.92-6.224-13.92-13.92s6.304-13.92 13.92-13.92H356.64c7.712.016 13.92 6.224 13.92 13.92z" />
      <circle cx="174.512" cy="207.088" r="38.144" />
    </g>
    <ellipse
      transform="scale(-1) rotate(53.347 381.215 -379.522)"
      cx="190.667"
      cy="191.518"
      rx="10.016"
      ry="6.48"
      fill="#5a5f63"
    />
    <circle cx="337.44" cy="207.088" r="38.144" fill="#3e4347" />
    <ellipse
      transform="scale(-1) rotate(53.343 381.305 -703.919)"
      cx="353.586"
      cy="191.533"
      rx="10.016"
      ry="6.48"
      fill="#5a5f63"
    />
  </svg>
);

export const emojiFour = () => (
  <svg
    width="24px"
    height="4px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512">
    <circle cx="256" cy="256" r="256" fill="#ffd93b" />
    <path
      d="M512 256c0 141.44-114.64 256-256 256-80.48 0-152.32-37.12-199.28-95.28 43.92 35.52 99.84 56.72 160.72 56.72 141.36 0 256-114.56 256-256 0-60.88-21.2-116.8-56.72-160.72C474.8 103.68 512 175.52 512 256z"
      fill="#f4c534"
    />
    <circle cx="350.176" cy="225.6" r="40.176" fill="#3e4347" />
    <ellipse
      transform="scale(-1) rotate(23.917 981.086 -1707.156)"
      cx="361.564"
      cy="207.787"
      rx="12.16"
      ry="7.872"
      fill="#5a5f63"
    />
    <circle cx="161.824" cy="225.6" r="40.176" fill="#3e4347" />
    <ellipse
      transform="scale(-1) rotate(23.917 981.07 -817.807)"
      cx="173.206"
      cy="207.784"
      rx="12.16"
      ry="7.872"
      fill="#5a5f63"
    />
    <path
      d="M151.136 357.056c-4.832-4.544-5.056-12.144-.512-16.96 4.544-4.816 12.128-5.056 16.96-.512 49.584 46.672 127.248 46.672 176.832 0 4.832-4.544 12.416-4.304 16.96.512 4.544 4.832 4.32 12.416-.512 16.96-58.8 55.36-150.928 55.36-209.728 0z"
      fill="#3e4347"
    />
  </svg>
);

export const emojiFive = () => (
  <svg
    width="24px"
    height="4px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512">
    <circle cx="256" cy="256" r="256" fill="#ffd93b" />
    <path
      d="M407.68 352.848c-24.528 59.712-83.168 101.68-151.664 101.68S128.88 412.56 104.192 352.848c-2.32-5.552 1.536-12.032 7.552-13.264 96.128-17.904 192.4-17.904 288.368 0 6.016 1.232 9.872 7.712 7.568 13.264z"
      fill="#3e4347"
    />
    <path
      d="M512 256c0 141.44-114.64 256-256 256-80.48 0-152.32-37.12-199.28-95.28 43.92 35.52 99.84 56.72 160.72 56.72 141.36 0 256-114.56 256-256 0-60.88-21.2-116.8-56.72-160.72C474.8 103.68 512 175.52 512 256z"
      fill="#f4c534"
    />
    <g fill="#fff">
      <path d="M115.264 339.008c18.208 29.632 75.184 32.816 143.168 32.816 67.088 0 124.192-3.184 143.168-31.584-.512-.192-.928-.544-1.472-.656-94.816-17.696-189.904-17.824-284.864-.576z" />
      <ellipse cx="356.432" cy="205.264" rx="81.056" ry="81.008" />
    </g>
    <ellipse cx="356.4" cy="205.264" rx="44.192" ry="44.224" fill="#3e4347" />
    <g fill="#fff">
      <ellipse
        transform="scale(-1) rotate(45.004 454.003 -905.989)"
        cx="375.333"
        cy="188.084"
        rx="11.953"
        ry="8.064"
      />
      <ellipse cx="155.616" cy="205.264" rx="81.056" ry="81.008" />
    </g>
    <ellipse cx="155.584" cy="205.264" rx="44.192" ry="44.224" fill="#3e4347" />
    <ellipse
      transform="scale(-1) rotate(45.004 453.918 -421.252)"
      cx="174.516"
      cy="188.049"
      rx="11.953"
      ry="8.064"
      fill="#fff"
    />
  </svg>
);

export const Warning = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
    <g fillRule="evenodd">
      <path
        d="M12.938 4.967c-.518-.978-1.36-.974-1.876 0L3.938 18.425c-.518.978-.045 1.771 1.057 1.771h14.01c1.102 0 1.573-.797 1.057-1.771L12.938 4.967z"
        fill="#ff991f"></path>
      <path
        d="M12 15a1 1 0 01-1-1V9a1 1 0 012 0v5a1 1 0 01-1 1m0 3a1 1 0 010-2 1 1 0 010 2"
        fill="#ffffff"></path>
    </g>
  </svg>
);

export const Danger = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
    <g fillRule="evenodd">
      <path
        d="M13.416 4.417a2.002 2.002 0 00-2.832 0l-6.168 6.167a2.002 2.002 0 000 2.833l6.168 6.167a2.002 2.002 0 002.832 0l6.168-6.167a2.002 2.002 0 000-2.833l-6.168-6.167z"
        fill="#DE350B"></path>
      <path
        d="M12 14a1 1 0 01-1-1V8a1 1 0 012 0v5a1 1 0 01-1 1m0 3a1 1 0 010-2 1 1 0 010 2"
        fill="#ffffff"></path>
    </g>
  </svg>
);
