import React, { Fragment } from 'react';
import { useReduxState } from 'hooks/useRedux';
import './ProgressBar.scss';

const ProgressBar: React.FC = () => {
  const {
    actions: {
      createProjectOnboarding: { selectedIndex: sIndex },
      jiraDataUserLimit: { isJiraProject },
    },
    locale,
  } = useReduxState();
  const selectedIndex = sIndex || 0;

  const jiraProgressBar: string[] = [
    locale?.strings?.voucher,
    locale?.strings?.onboarding_create_project,
    locale?.strings?.onboarding_confirm_email,
    locale?.strings?.onboarding_define_iteration,
    locale?.strings?.onboarding_select_users,
    locale?.strings?.onboarding_done,
  ];

  const normalProgressBar: string[] = [
    locale?.strings?.voucher,
    locale?.strings?.onboarding_create_project,
    locale?.strings?.onboarding_confirm_email,
    locale?.strings?.onboarding_define_iteration,
    locale?.strings?.onboarding_done,
  ];

  const items: string[] = isJiraProject ? jiraProgressBar : normalProgressBar;

  const renderMenuItems = () => {
    return items?.map?.((item: string, index: number) => {
      const currentClass = selectedIndex === index ? 'current' : '';
      const lastStep = isJiraProject ? 5 : 4;
      const completedClass =
        selectedIndex > index || selectedIndex === lastStep ? 'completed' : '';
      return (
        <li
          className={`progress-bar__item ${currentClass} ${completedClass}`}
          key={index}>
          <span>{item}</span>
        </li>
      );
    });
  };

  return (
    <Fragment>
      <ol className="progress-bar progress-bar--icon">{renderMenuItems()}</ol>
    </Fragment>
  );
};

export default ProgressBar;
