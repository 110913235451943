import React from 'react';
import { ModalBackDrop, Modal } from 'lib/Modal';
import { OffDay } from 'models/ProjectModel';
import LeaveInformation from '../Information';
import AddLeave from './addLeave';
import UpdateLeave from './updateLeave';

interface Props {
  close: () => void;
  data: OffDay | null;
}

const LeaveModal: React.FC<Props> = (props) => {
  const { close, data } = props;
  const [edit, setEdit] = React.useState<boolean>(false);

  const openEditForm = () => {
    setEdit(true);
  };

  return (
    <Modal onClose={close}>
      <ModalBackDrop>
        {data &&
          (!edit ? (
            <LeaveInformation
              close={close}
              openEditForm={openEditForm}
              data={data}
            />
          ) : (
            <UpdateLeave close={close} data={data} />
          ))}
        {!data && !edit && <AddLeave close={close} />}
      </ModalBackDrop>
    </Modal>
  );
};

export default LeaveModal;
