import Form, { ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
import { ModalBackDrop, Modal, Header, Footer, Body } from 'lib/Modal';
import Textfield from '@atlaskit/textfield';
import React, { Fragment, useState } from 'react';
import Button, { LoadingButton } from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import { useReduxState } from 'hooks/useRedux';
import { useDispatch } from 'react-redux';
import { addHashTag, removeHashTagFromStore } from 'store/slice/project';
import Spinner from '@atlaskit/spinner';

export interface ContainerProps {
  children: React.ReactNode;
  className?: string;
}

interface HashTagModalProps {
  close: () => void;
}

export const HashTagModal: React.FC<HashTagModalProps> = (props) => {
  const { close } = props;

  const dispatch = useDispatch();

  const {
    locale,
    project: { hashTags },
  } = useReduxState();

  const [newTag, setNewTag] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [error, setError] = useState('');

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTag(e.target.value);
    setError(validate(e.target.value));
  };

  const validate = (value: string): string => {
    return value && value.length < 2
      ? locale?.strings?.validation_too_short
      : /^\S.*/.test(value) !== true
      ? locale?.strings?.validation_no_space
      : value.length > 20
      ? locale?.strings?.validation_max_20
      : '';
  };

  const addNewTag = async () => {
    setLoading(true);
    await dispatch(addHashTag(newTag));
    setLoading(false);
    setNewTag('');
    setError('');
  };

  const removeHashTag = async (htId: number) => {
    setDeleteLoading(true);
    await dispatch(removeHashTagFromStore(htId));
    setDeleteLoading(false);
  };

  const renderHashtags = () => {
    return hashTags.map((ht) => {
      return (
        <div className="hashtag__item" key={ht.id}>
          <h5>{ht.name}</h5>
          <Button
            appearance="subtle"
            spacing="none"
            onClick={() => removeHashTag(ht.id)}>
            <CrossCircleIcon label="close icon" size="small" />
          </Button>
        </div>
      );
    });
  };

  return (
    <Modal onClose={close}>
      <ModalBackDrop onClick={close}>
        <Header>
          <h3>Hashtags</h3>
        </Header>

        <Body>
          <Field label={locale?.strings?.enter_keyword} name="hashtag" isRequired>
            {({ fieldProps }: any) => (
              <Fragment>
                <Textfield
                  {...fieldProps}
                  value={newTag}
                  onChange={onChangeHandler}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}

                <HelperMessage>Eg. frontend, backend</HelperMessage>
              </Fragment>
            )}
          </Field>
          <br />
          <LoadingButton
            isDisabled={!newTag || Boolean(error)}
            isLoading={loading}
            iconBefore={<AddIcon label="add icon" size="small" />}
            appearance="primary"
            onClick={addNewTag}>
            {locale?.strings?.add_new_hashtag}
          </LoadingButton>
          <div className="hashtag">
            <h4>{locale?.strings?.all_hashtags}</h4>
            <div className="hashtag__list">{renderHashtags()}</div>
            {deleteLoading && <Spinner size="small" />}
          </div>
        </Body>

        <Footer>
          <span />
        </Footer>
      </ModalBackDrop>
    </Modal>
  );
};

export default HashTagModal;
