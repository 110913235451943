import { createAsyncThunk } from '@reduxjs/toolkit';
import store from 'store';
import * as CapacityService from 'services/capacity.service';
import { error as globalError } from '../globalError/globaleError.slice';
import {
  update,
  add,
  clear,
  sprintCapacityInterface,
} from './sprintCapacity.slice';

export { update, add, clear };

export const getSprintCapacity = createAsyncThunk(
  'sprintCapacity/fetch',
  async (args: CapacityService.GetCapacityBySprintArgs) => {
    try {
      const data = await CapacityService.getCapacityBySprint(args);
      const sprintCapacity: sprintCapacityInterface[] = [];
      Object.assign(
        sprintCapacity,
        store.getState().global.sprintCapacity?.sprints
      );
      const index = sprintCapacity?.findIndex(
        (e: sprintCapacityInterface) => e?.sprintId === args?.sprintId
      );
      if (index >= 0) {
        sprintCapacity[index] = {
          sprintId: args?.sprintId,
          capacity: data.capacity,
          hashTags: data.hashTags,
          users: data.users,
          userSlots: data.userSlots,
        };
      }
      store.dispatch(update(sprintCapacity));
    } catch (err: any) {
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);
