import React from 'react';
import Button from '@atlaskit/button/standard-button';
import AddIcon from '@atlaskit/icon/glyph/add';
import ScheduleIcon from '@atlaskit/icon/glyph/schedule';
import HolidayPopUp from '../../components/Modals/Holiday';
import LeavePopUp from '../../components/Modals/Holiday/Leave';
import { getProjectOffDaysList } from 'store/slice/project';
import { useDispatch } from 'react-redux';
import { useReduxState } from 'hooks/useRedux';
import FullCalendar, {
  EventClickArg,
  EventContentArg,
  EventSourceInput,
} from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { OffDayType } from 'types/enum';
import { OffDay, User } from 'models/ProjectModel';
import { Link } from 'react-router-dom';
import './Calendar.scss';
import listPlugin from '@fullcalendar/list';

interface CalendarProps {
  projectLink: string;
  projectId: string | number;
}
const Calendar: React.FC<CalendarProps> = (props) => {
  const {
    locale,
    project: {
      project: {
        id,
        projectLink: { link },
      },
      offDays,
    },
  } = useReduxState();

  const dispatch = useDispatch();

  const [eventState, setEventState] = React.useState<OffDay | null>(null);
  const [holidayModalPopUpOpen, setHolidayModalPopUpOpen] =
    React.useState<boolean>(false);
  const [leaveModalPopUpOpen, setLeaveModalPopUpOpen] =
    React.useState<boolean>(false);

  const openHolidayPopUp = () => {
    setHolidayModalPopUpOpen(true);
  };

  const closeHolidayPopUp = () => {
    setHolidayModalPopUpOpen(false);
    setEventState(null);
  };

  const openLeavePopUp = () => {
    setLeaveModalPopUpOpen(true);
  };

  const closeLeavePopUp = () => {
    setLeaveModalPopUpOpen(false);
    setEventState(null);
  };

  React.useEffect(() => {
    dispatch(
      getProjectOffDaysList({
        projectLink: props.projectLink,
        projectId: props.projectId,
      })
    );
  }, []);

  const renderNames = (affectedUsers: User[]) => {
    let names = '';

    affectedUsers.forEach((element, index) => {
      names =
        names +
        element.username +
        (index + 1 < affectedUsers.length ? ',' : '');
    });
    return names;
  };

  const mapOffDaysToReactCalendarProps: EventSourceInput = offDays.map(
    ({ id, startDate, endDate, type, description, affectedUsers }) => {
      return {
        id: String(id),
        start: startDate,
        end: endDate,
        affectedUsers,
        description,
        title: type,
      };
    }
  );

  const handleEventClick = (value: EventClickArg) => {
    const data: OffDay = {
      id: Number(value.event.id),
      startDate: value.event.start as unknown as Date,
      endDate: value.event.end as unknown as Date,
      type: value.event.title as unknown as OffDayType,
      description: value.event.extendedProps.description,
      affectedUsers: value.event.extendedProps.affectedUsers,
    };
    setEventState(data);
    if (data.type === OffDayType.HOLIDAY) {
      openHolidayPopUp();
    } else {
      openLeavePopUp();
    }
  };

  return (
    <>
      {holidayModalPopUpOpen && (
        <HolidayPopUp close={closeHolidayPopUp} data={eventState} />
      )}
      {leaveModalPopUpOpen && (
        <LeavePopUp close={closeLeavePopUp} data={eventState} />
      )}
      <div className="ct-block">
        <div className="ct--sidebar">
          <ul className="ct__list">
            <li className="ct__list-item">
              <Button
                className="ct__list-btn"
                appearance="primary"
                onClick={openLeavePopUp}
                iconBefore={<AddIcon label="add icon" size="small" />}>
                {locale?.strings?.apply_leave}
              </Button>
            </li>
            <li className="ct__list-item">
              <Button
                className="ct__list-btn"
                appearance="default"
                onClick={openHolidayPopUp}
                iconBefore={
                  <ScheduleIcon label="calendar icon" size="medium" />
                }>
                {locale?.strings?.add_holiday}
              </Button>
            </li>
          </ul>
          <div className="ct__info">
            <h4 className="ct__info__title">{locale?.strings?.upcoming_holidays}</h4>
            <ul>
              <li>Christmas</li>
              <li>Happy New Year 2022</li>
              <li>Easter Monday</li>
              <li>Labour Day</li>
            </ul>
          </div>
        </div>
        <div className="ct--content">
          <div className="ct-breadcrumb">
            <div className="ct-breadcrumb__item">
              <Link to={`/project/${id}/${link}`}>Home</Link>
              <span>/</span>
              <Link to={`/project/${id}/${link}/calendar`}>{locale?.strings?.calendar}</Link>
            </div>
            <h2 className="ct-breadcrumb__title">{locale?.strings?.calendar}</h2>
          </div>
          <div className="ct__calendar">
            <FullCalendar
              displayEventTime={false}
              dayMaxEventRows={2}
              dayMaxEvents={3}
              plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
              headerToolbar={{
                left: 'today',
                center: 'title',
                right: 'dayGridMonth,listMonth,prev,next',
              }}
              initialView="dayGridMonth"
              events={mapOffDaysToReactCalendarProps}
              eventContent={(value: EventContentArg) => {
                if (value.event.title === OffDayType.HOLIDAY) {
                  return (
                    <span
                      style={{
                        backgroundColor: '#36B37E',
                        padding: '1px 4px',
                        display: 'inline-block',
                        width: '100%',
                        color: 'white',
                        borderRadius: '3px',
                      }}>
                      {locale?.strings?.holiday}
                    </span>
                  );
                }
                return (
                  <b>
                    {(value.event.title === OffDayType.LEAVE) ? locale?.strings?.leave
                    : value.event.title} -
                    {renderNames(value.event.extendedProps.affectedUsers)}
                  </b>
                );
              }}
              eventClick={handleEventClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
