import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HashTags } from 'models/ProjectModel';

export interface FilteredHashTags {
  hashTags: HashTags[];
}

export const initialState: FilteredHashTags = {
  hashTags: [],
};

const filteredHashTagsSlice = createSlice({
  name: 'filteredHashTags',
  initialState,
  reducers: {
    add(state, action: PayloadAction<HashTags[]>) {
      state.hashTags = action.payload;
    },
    clear(state) {
      state.hashTags = [];
    },
    update(state, action: PayloadAction<HashTags[]>) {
      state.hashTags = action.payload;
    },
  },
});

export const { add, update, clear } = filteredHashTagsSlice.actions;
export default filteredHashTagsSlice.reducer;
