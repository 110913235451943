import Button from '@atlaskit/button';
import React from 'react';
import { useDispatch } from 'react-redux';

import { useReduxState } from 'hooks/useRedux';
import { addSprint } from 'store/slice/project';

const LoadSprintBtn: React.FC = () => {
  const dispatch = useDispatch();
  const { locale } = useReduxState();

  const loadSprints = async () => {
    dispatch(addSprint());
  };

  return (
    <Button
      style={{
        position: 'sticky',
        left: '20rem',
        top: 0,
      }}
      appearance="primary"
      onClick={loadSprints}>
      {locale?.strings?.next_sprint}
    </Button>
  );
};

export default LoadSprintBtn;
