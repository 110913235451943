type func = {
  (args: any): void;
};
class EventEmitter {
  private name: string;
  private callbacks: Record<string, func> = {};

  constructor(uname: string) {
    this.name = uname;
  }

  on(event: string, cb: func): void {
    this.callbacks[event] = cb;
  }

  emit(event: string, data: any): void {
    const fn = this.callbacks[event];
    if (fn && typeof fn === 'function') {      
      return fn(data);
    }
  }

  remove(event: string): void {
    const fn = this.callbacks[event];
    if (fn && typeof fn === 'function') {
      delete this.callbacks[event];
    }
  }
}

export const EventBus = new EventEmitter('REACT_EVENT_BUS');

export const EVENT_INFO_POP_UP_MODAL = {
  OPEN: 'event-info-popup-open',
  CLOSE: 'event-info-popup-close',
};

export const GET_STARTED_TUTORIAL = {
  FIRSTOPEN: 'get-started-tutorial-after-project-creation',
};
