import { Sprint } from 'models/ProjectModel';
import moment from 'moment';

export const activeSprintIndexCalculate = (
  sprints: Sprint[],
  date: Date
): number => {
  const checkDate: moment.Moment = moment(date);
  for (let index = 0; index < sprints.length; index++) {
    const sprint = sprints[index];
    const sprintStartDate: moment.Moment = moment(sprint.sprintStartDate);
    const intervalTime = Number(sprint.intervalTime);
    const sprintEndDate = sprintStartDate
      .startOf('day')
      .clone()
      .add(intervalTime, 'weeks');

    if (checkDate.isBetween(sprintStartDate, sprintEndDate)) {
      return index;
    }
  }
  //FIXME: Has to check if todays date is before first sprint or after last sprint
  return 0;
};
