import React, { SyntheticEvent, CSSProperties, MouseEventHandler } from 'react';
import ReactPortal from 'lib/Portal';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Tooltip from '@atlaskit/tooltip';

import './Modal.scss';

// @DONOT DELETE THIS COMMENTED CODE
// const fillNullIfNoValue = (object: any, keys: string[]) => {
//   keys.forEach((key) => {
//     object[key] = object[key] || null;
//   });
//   return object;
// };
// interface Components {
//   header: React.ReactNode;
//   body: React.ReactNode;
//   footer: React.ReactNode;
// }
interface ContextProps {
  // components?: Components;
  className?: string;
  size?: 'small' | 'medium' | 'small' | 'large' | 'xlarge';
  style?: CSSProperties;
  onClose: (...args: any) => void;
}

const ModalContext = React.createContext<ContextProps | null>(null);

export const Modal: React.FC<ContextProps> = (props) => {
  const { children, onClose, className, style, size = 'medium' } = props;

  React.useEffect(() => {
    // unset overflow effect on modal popup (mounting modal)
    document.body.style.overflow = 'hidden';

    // remove the overflow effect (unmounting modal)
    return function unsetOverflow() {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <ReactPortal>
      <ModalContext.Provider value={{ onClose, className, style, size }}>
        {children}
      </ModalContext.Provider>
    </ReactPortal>
  );
};

export default Modal;

export const useModalContext = (componentName: string) => {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error(`${componentName} must be wrapped inside Modal`);
  }

  return { ...context };
};

interface BackDropProps {
  className?: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler<Element> | undefined;
}

const preventEventPropagation = (e: SyntheticEvent) => {
  e.stopPropagation();
};

export const ModalBackDrop: React.FC<BackDropProps> = (props) => {
  const {
    className: modalClassName,
    style,
    size,
  } = useModalContext('ModalBackDrop');
  return (
    <div className="ct-modal" {...props}>
      <div className="ct-modal__backdrop"></div>
      <div className="ct-modal__container">
        <div
          style={{ ...style }}
          className={`${
            modalClassName
              ? `${modalClassName} ct-modal__block`
              : 'ct-modal__block'
          }  ct-modal__block--${size}`}
          onClick={preventEventPropagation}>
          <div className="ct-modal__content">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export const Header: React.FC = (props) => {
  const { onClose } = useModalContext('Header');

  return (
    <div className="ct-modal__header">
      {props.children}
      <Tooltip content="Close">
        <Button appearance="subtle" spacing="none" onClick={onClose}>
          <CrossIcon label="cross icon" size="medium" primaryColor="#999" />
        </Button>
      </Tooltip>
    </div>
  );
};

export const Body: React.FC = (props) => {
  return <div className="ct-modal__body">{props.children}</div>;
};

export const Footer: React.FC = (props) => {
  return <div className="ct-modal__footer">{props.children}</div>;
};
