import React from 'react';
import { Body, Header, Footer } from 'lib/Modal';
import Button from '@atlaskit/button';
import { OffDay } from 'models/ProjectModel';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import { getGermanFormatDate } from 'utils/date';
import { renderParticipants } from 'components/Modals/EventDetailModal/Info';
import { OffDayType } from 'types/enum';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import { useReduxState } from 'hooks/useRedux';

interface IInformationProps {
  close: () => void;
  openEditForm: () => void;
  data: OffDay;
}

const Info: React.FC<IInformationProps> = ({ close, openEditForm, data }) => {
  const { locale } = useReduxState();
  const start = getGermanFormatDate(data.startDate);
  const end = data.endDate && `- ${getGermanFormatDate(data.endDate)}`;
  return (
    <>
      <Header>
        <h3>
          {data.type}{' '}
          {data.type === OffDayType.HOLIDAY && `- ${data.description}`}
        </h3>
      </Header>
      <Body>
        <div className="event-info">
          <div className="event-info__calendar event-info__item">
            <div className="event-info__calendar-date">
              <CalendarIcon label="Date" size="medium"></CalendarIcon>
              <span>
                {start} {end}
              </span>
            </div>
          </div>

          <div className="event-info__item">
            <h4>{locale?.strings?.participants}</h4>
            <div className="c-avatar c-avatar--group">
              {renderParticipants(data?.affectedUsers)}
            </div>
          </div>
        </div>
      </Body>
      <Footer>
        <div className="ct-modal__footer--border">
          <div>
            <Button
              appearance="subtle"
              iconBefore={<EditFilledIcon label="Edit icon" size="small" />}
              onClick={openEditForm}>
              {locale?.strings?.edit}
            </Button>
          </div>
        </div>
      </Footer>
    </>
  );
};

export default Info;
