import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import EditorMoreIcon from '@atlaskit/icon/glyph/editor/more';
import CopyIcon from '@atlaskit/icon/glyph/copy';
import './ProjectLink.scss';
import { useReduxState } from 'hooks/useRedux';
import useOneClickOutside from 'hooks/useOneClickOutside';
import { Link } from 'react-router-dom';
import { routes } from 'utils/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';

export const ProjectLink: React.FC = () => {
  const {
    project,
    locale,
  } = useReduxState();

  const [showEventsDropdownMenu, setShowEventsDropdownMenu] =
    React.useState<boolean>(false);

  const showHideEventsMenu = () => {
    setShowEventsDropdownMenu(!showEventsDropdownMenu);
  };

  const Projectid = project.project.id;
  const Projectuser = project.project.projectLink.link;
  const domainname = window.origin;

  const projectUrl =
    domainname + '/' + 'project' + '/' + Projectid + '/' + Projectuser;

  const copy = async () => {
    await navigator.clipboard.writeText(projectUrl);
    toast(locale?.strings?.copied_clipboard);
  };

  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  useOneClickOutside(ref, () => setShowEventsDropdownMenu(false));

  const withProject = ['/project/' + Projectid + '/'];
  const { pathname } = useLocation();
  
  if (withProject.some((item) => pathname.includes(item)))
    return (
      <>
        <div className="ct-dropdown">
          <Tooltip content="More">
            <div className="ct-dropdown__head" onClick={showHideEventsMenu}>
              <EditorMoreIcon label="more" size="medium" />
            </div>
          </Tooltip>
          {showEventsDropdownMenu && (
            <div ref={ref} className="ct-dropdown__list active">
              <div className="ct-dropdown__list-item" onClick={copy}>
                {locale?.strings?.project_link}
                <CopyIcon label="copy" size="small" />
              </div>
              <div className="ct-dropdown__list-item">
                <Link to={routes.app.createProjectPage} onClick={showHideEventsMenu}>
                {locale?.strings?.create_project_new}
                </Link>
              </div>
            </div>
          )}
          <ToastContainer
            pauseOnHover={false}
            autoClose={1000}
            hideProgressBar={true}
          />
        </div>
      </>
    );
  return null;
};
