import React, { ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSprint, updateSprintCoveredCapacity } from 'store/slice/project';
import TextArea from '@atlaskit/textarea';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { ReferenceModal } from 'components/Modals/ReferenceModal';
import EventModal from 'components/Modals/EventModal';
import useCapaCalculation from 'hooks/useCapaCalculation';
import { sprintCapacityInterface } from 'store/slice/sprintCapacity/sprintCapacity.slice';
import { SprintEfficiencyAndCalculationInterface } from 'store/slice/sprintEfficiencyAndCalculation/sprintEfficiencyAndCalculation.slice';

import { useReduxState } from 'hooks/useRedux';
import { formatDate } from 'utils/date';
import {
  SprintEvents,
  SprintEventsWithOnBoardingTutorial,
  SprintGoal,
  SprintGoalWithOnBoardingTutorial,
  SprintTicketReference,
  SprintTicketReferenceWithOnBoardingTutorial,
  SprintHashTagsOverview,
  SprintHashTagsOverviewWithOnBoardingTutorial,
} from './SprintUtils';
import HashTagModalOverview from 'components/Modals/HashTagModal/overview';
import { InlineEditableTextfield } from '@atlaskit/inline-edit';

import './SprintCard.scss';
import { activeSprintIndexCalculate } from 'utils/getActiveSprint';
import SprintEventsOverview from 'components/Modals/EventModal/overview';

import Tooltip from '@atlaskit/tooltip';

export interface SprintCardProps {
  sprintIndex: number;
  projectName: string;
  projectLink: string;
  sprintGoal: string;
  sprintId: number;
  sprintStartDate: string;
  intervalTime: number;
}

export const SprintCard: React.FC<SprintCardProps> = (props) => {
  const {
    sprintId,
    sprintGoal,
    projectLink,
    sprintStartDate,
    intervalTime,
    sprintIndex,
  } = props;

  const dispatch = useDispatch();
  const useCapaCalculationHook = useCapaCalculation();

  const {
    sprintCapacity: sprintCapacityState,
    sprintEfficiencyAndCalculation,
    locale,
    project: { sprints, project },
  } = useReduxState();

  const sprintCapacity = sprintCapacityState?.sprints?.find(
    (e: sprintCapacityInterface) => e?.sprintId === sprintId
  );

  const newCapa = sprintCapacity
    ? useCapaCalculationHook?.calculateCapacityHashTags(
        sprintCapacity.capacity,
        sprintCapacity.users,
        sprintId
      )
    : 0;

  const sprintRecommendedSP =
    Math.round(
      (((sprintEfficiencyAndCalculation.sprints.find(
        (e: SprintEfficiencyAndCalculationInterface) => e?.id === sprintId
      )?.recommendedSprintVelocityPercentile || 100) *
        newCapa) /
        100) *
        2
    ) / 2;

  const sprint = sprints.find((spr) => spr.id === sprintId);

  const projectDate =
    locale?.strings?.period +
    ': ' +
    formatDate(sprintStartDate, Number(intervalTime));

  const [isEdit, setIsEdit] = useState(false);
  const [refOpen, setRefOpen] = useState(false);
  const [eventOpen, setEventOpen] = useState(false);
  const [hashTagOverviewOpen, setHashTagOverviewOpen] = useState(false);
  const [sprintsOverviewOpen, setSprintsOverviewOpen] = useState(false);

  const enableEdit = () => {
    setIsEdit(true);
  };

  const closeHashTagOverviewOpen = () => setHashTagOverviewOpen(false);
  const openHashTagOverviewOpen = () => setHashTagOverviewOpen(true);

  const closeSprintsOverviewOpen = () => setSprintsOverviewOpen(false);
  const openSprintsOverviewOpen = () => setSprintsOverviewOpen(true);

  const closeRefOpen = () => setRefOpen(false);

  const openReference = () => setRefOpen(true);

  const closeEventModal = () => setEventOpen(false);

  const openEventModal = () => setEventOpen(true);

  const onBlurSprintGoal = async (e: any) => {
    setIsEdit(false);
    dispatch(
      updateSprint({ sprintId, projectLink, sprintGoal: e?.target?.value })
    );
  };

  const renderText = (): Array<ReactNode> => {
    return sprintGoal?.split?.('\n')?.map((str: string, index: number) => {
      return <p key={`${sprintId}_${index}`}>{str}</p>;
    });
  };

  const renderCapacity = () => {
    return newCapa + ' ' + useCapaCalculationHook?.renderStringLong(newCapa);
  };

  const updateCompletedSP = (values: any) => {
    dispatch(
      updateSprintCoveredCapacity({
        sprintId,
        projectLink,
        coveredCapacity: Number(values),
      })
    );
  };

  const validate = (values: string) => {
    if (isNaN(Number(values))) return 'must be a number';
    return undefined;
  };

  const activeSprintIndex: number = activeSprintIndexCalculate(
    sprints,
    new Date()
  );

  return (
    <div className="card">
      <div className="flex items-end">
        <div className="name">
          <Tooltip content="{projectName}">
            <p>{project.projectName}</p>
          </Tooltip>
        </div>
        <div className="sprint-name">
          <p>Sprint {sprintIndex + 1}</p>
        </div>
      </div>

      <div className="points">
        <p>{renderCapacity()}</p>
        <div className="iteration">
          <p>estimated {sprintRecommendedSP}</p>
        </div>
      </div>
      <div className="iteration">
        <p>{projectDate}</p>
      </div>
      <InlineEditableTextfield
        testId="editable-text-field"
        defaultValue={sprint?.coveredCapacity}
        label={locale?.strings?.actual_story_points_completed}
        onConfirm={updateCompletedSP}
        placeholder={locale?.strings?.story_points_value}
        isCompact
        validate={validate}
      />

      <div className="sprint-goal">
        {sprintIndex === activeSprintIndex ? (
          <SprintGoalWithOnBoardingTutorial enableEdit={enableEdit} />
        ) : (
          <SprintGoal enableEdit={enableEdit} />
        )}
        {isEdit ? (
          <div>
            <TextArea
              resize="smart"
              ref={(ref: any) => {
                if (ref) {
                  ref.focus();
                }
              }}
              onBlur={onBlurSprintGoal}
              defaultValue={sprintGoal}
            />
            <p
              className="sprint-close-goal-textarea"
              onClick={onBlurSprintGoal}>
              <CheckIcon label="save" size="medium" />
            </p>
          </div>
        ) : (
          <div className="sprint-goal__desc">{renderText()}</div>
        )}
      </div>
      {refOpen && <ReferenceModal close={closeRefOpen} sprintId={sprintId} />}

      {hashTagOverviewOpen && (
        <HashTagModalOverview
          close={closeHashTagOverviewOpen}
          sprintIndex={sprintIndex}
          sprintId={sprintId}
        />
      )}

      {sprintsOverviewOpen && (
        <SprintEventsOverview
          openEventModal={openEventModal}
          close={closeSprintsOverviewOpen}
          sprintIndex={sprintIndex}
          // sprintId={sprintId}
          sprintStart={new Date(sprintStartDate)}
          sprintLength={intervalTime}
        />
      )}

      {eventOpen && (
        <EventModal
          close={closeEventModal}
          sprintStartDate={sprintStartDate}
          intervalTime={intervalTime}
        />
      )}
      {sprintIndex === activeSprintIndex ? (
        <SprintTicketReferenceWithOnBoardingTutorial
          openReference={openReference}
        />
      ) : (
        <SprintTicketReference openReference={openReference} />
      )}

      {sprintIndex === activeSprintIndex ? (
        <SprintHashTagsOverviewWithOnBoardingTutorial
          openHashTagOverviewOpen={openHashTagOverviewOpen}
        />
      ) : (
        <SprintHashTagsOverview
          openHashTagOverviewOpen={openHashTagOverviewOpen}
        />
      )}
      {sprintIndex === activeSprintIndex ? (
        <SprintEventsWithOnBoardingTutorial
          openSprintsOverviewOpen={openSprintsOverviewOpen}
        />
      ) : (
        <SprintEvents openSprintsOverviewOpen={openSprintsOverviewOpen} />
      )}
    </div>
  );
};

export default SprintCard;
