import React, { Fragment, useState } from 'react';
import { OffDayType } from 'types/enum';
import { Header, Body, Footer } from 'lib/Modal';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Button from '@atlaskit/button';
import { LoadingButton } from '@atlaskit/button';
import { updateOffDay, deleteOffDay } from 'store/slice/project';
import {
  useParticipantsOptions,
  filterCalenderDate,
  getValidationState,
  validate,
  isDateEqualAfter,
} from '../../EventModal/EventUtils';
import { useReduxState } from 'hooks/useRedux';
import { DatePicker } from '@atlaskit/datetime-picker';
import { CheckboxSelect } from '@atlaskit/select';
import ParticipantOption, {
  filterOptions,
  ParticipantOptions,
} from '../../EventModal/Participants';
import { useDispatch } from 'react-redux';
import { OffDay } from 'models/ProjectModel';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import Alert, { Actions } from 'lib/Alert';


export interface IFormValues {
  startDate: string;
  endDate: string;
  affectedUsers: Array<ParticipantOptions>;
}

interface IAddLeaveProps {
  close: () => void;
  data: OffDay;
}

const AddLeave: React.FC<IAddLeaveProps> = ({ close, data }) => {
  const { locale } = useReduxState();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState<boolean>(false);
  const changeDeleteWarning = () => setDeleteWarning(!deleteWarning);
  
  const onDeleteHoliday = async () => {    
    setDeleteWarning(false);
    close();
    await dispatch(deleteOffDay(data.id));
  };  

  const actions: Actions[] = [
    {
      appearance: 'danger',
      text: locale?.strings?.delete,
      onClick: onDeleteHoliday,
    },
    {
      text: locale?.strings?.cancel,
      onClick: changeDeleteWarning,
    },
  ];

  const dispatch = useDispatch();  

  const { OPTIONS: userIntervalOptions } = useParticipantsOptions(false);
  const startDateRef = React.useRef<string>();
  startDateRef.current = new Date(data.startDate).toISOString();

  const affectedUsers = data.affectedUsers.map(({ id, username, email }) => {
    return {
      label: username,
      value: id,
      email: email,
      type: 'user',
      avatar: null,
      filterValues: [username, email],
    };
  });

  const onSubmit = async (values: IFormValues) => {
    try {
      const startDate = new Date(values?.startDate).toISOString();
      const endDate = new Date(values?.endDate).toISOString();
      const holidayObject = {
        id: data.id,
        startDate,
        endDate,
        type: OffDayType.LEAVE,
        affectedUsers: values?.affectedUsers.map((user) => {
          return user.value;
        }),
      };      
      setIsLoading(true);
      await dispatch(updateOffDay(holidayObject));
      setIsLoading(false);
      close();
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <>
      {deleteWarning && (
        <Alert
          actions={actions}
          onClose={changeDeleteWarning}
          heading={locale?.strings?.delete_holiday}
          appearance="danger">
          {locale?.strings?.delete_holiday_message}
        </Alert>
      )}     
      <Header>
        <h3>{locale?.strings?.update_leave}</h3>
      </Header>
      <Form onSubmit={onSubmit}>
        {({ formProps }) => (
          <form {...formProps}>
            <Body>
              <div className="form-fields">
                <Field
                  isRequired
                  label={locale?.strings?.leave_start_date}
                  name="startDate"
                  defaultValue={new Date(data.startDate).toISOString()}
                  validate={(value) =>
                    !value
                      ? locale?.strings?.add_holiday_invalid_date
                      : undefined
                  }>
                  {({ fieldProps, error }) => (
                    <Fragment>
                      <DatePicker
                        {...fieldProps}
                        placeholder={locale?.strings?.date_placeholder}
                        dateFormat={locale?.strings?.date_format}
                        disabledDateFilter={filterCalenderDate}
                        weekStartDay={1}
                        onChange={(value) => {
                          fieldProps.onChange(value);
                          startDateRef.current = value;
                        }}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </Fragment>
                  )}
                </Field>

                <Field
                  isRequired
                  label={locale?.strings?.leave_end_date}
                  name="endDate"
                  defaultValue={
                    data.endDate
                      ? new Date(data.endDate).toISOString()
                      : new Date(data.startDate).toISOString()
                  }
                  validate={(value) =>
                    !value
                      ? locale?.strings?.add_holiday_invalid_date
                      : !isDateEqualAfter(value, startDateRef.current as string)
                      ? locale?.strings
                          ?.apply_leave_end_dategreater_than_start_error
                      : undefined
                  }>
                  {({ fieldProps, error }) => (
                    <Fragment>
                      <DatePicker
                        {...fieldProps}
                        placeholder={locale?.strings?.date_placeholder}
                        dateFormat={locale?.strings?.date_format}
                        disabledDateFilter={filterCalenderDate}
                        weekStartDay={1}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </Fragment>
                  )}
                </Field>

                <Field
                  isRequired
                  label={locale?.strings?.add_holiday_members}
                  name="affectedUsers"
                  defaultValue={affectedUsers}
                  validate={validate(1)}>
                  {({ fieldProps, error, meta: { valid } }: any) => (
                    <Fragment>
                      <CheckboxSelect
                        {...fieldProps}
                        options={userIntervalOptions}
                        getOptionValue={filterOptions}
                        formatOptionLabel={ParticipantOption}
                        placeholder={locale?.strings?.add_holiday_members}
                        validationState={getValidationState(error, valid)}
                        shouldFitContainer
                      />

                      {error === 'EMPTY' && (
                        <ErrorMessage>
                          {locale?.strings?.validation_participant_min_error.replace(
                            ':n',
                            '1'
                          )}
                        </ErrorMessage>
                      )}
                    </Fragment>
                  )}
                </Field>
              </div>
            </Body>
            <Footer>
            <div>
            <Button
              appearance="subtle"
              iconBefore={
                <TrashIcon label="Delete icon" size="small" />
              }
              onClick={changeDeleteWarning}>
              {locale?.strings?.delete}
            </Button>
            </div>           
              <span></span>
              <div>
                <Button className="secondary-btn" onClick={close}>
                {locale?.strings?.cancel}
                </Button>
                <LoadingButton
                  appearance="primary"
                  type="submit"
                  isLoading={isLoading}>
                  {locale?.strings?.create}
                </LoadingButton>
              </div>
            </Footer>
          </form>
        )}
      </Form>
    </>
  );
};

export default AddLeave;
