import { combineReducers } from 'redux';

// slice imports

// actions
import createProjectReducer, {
  initialState as createProjectInitialState,
} from './createProject/createProject.slice';

import createSprintReducer, {
  initialState as createSprintInitialState,
} from './createSprint/createSprint.slice';

import confirmEmailReducer, {
  initialState as confirmEmailInitialState,
} from './confirmEmail/confirmEmail.slice';

import createProjectOnboardingReducer, {
  initialState as createProjectOnboardingInitialState,
} from './createProjectOnboarding/createProjectOnboarding.slice';

import jiraDataUserLimitReducer, {
  initialState as jiraDataUserInitialState,
} from '../slice/JiraData/jiraData.slice';

// other redux states

import capaMethodReducer, {
  initialState as capaMethodInitialState,
} from '../slice/capaMethod/capaMethod.slice';

import filteredHashTagsReducer, {
  initialState as filteredHashTagsState,
} from './filteredHashTags/filteredHashTags.slice';

import globalErrorReducer, {
  initialState as globalErrorInitialState,
} from './globalError/globaleError.slice';

import localeReducer, {
  initialState as localeInitialState,
} from './locale/locale.slice';

import projectReducer, {
  initialState as projectInitialState,
} from './project/project.slice';

import sprintCapacity, {
  initialState as sprintCapacityState,
} from './sprintCapacity/sprintCapacity.slice';

import sprintEfficiencyAndCalculation, {
  initialState as sprintEfficiencyAndCalculationState,
} from './sprintEfficiencyAndCalculation/sprintEfficiencyAndCalculation.slice';

import validateVoucherReducer, {
  initialState as voucher,
} from '../slice/voucherPayment/voucherPayment.slice';

export const initialStoreState = {
  global: {
    actions: {
      createProject: createProjectInitialState,
      createSprint: createSprintInitialState,
      confirmEmail: confirmEmailInitialState,
      createProjectOnboarding: createProjectOnboardingInitialState,
      jiraDataUserLimit: jiraDataUserInitialState,
    },
    capaMethod: capaMethodInitialState,
    filteredHashTags: filteredHashTagsState,
    globalError: globalErrorInitialState,
    locale: localeInitialState,
    project: projectInitialState,
    sprintCapacity: sprintCapacityState,
    sprintEfficiencyAndCalculation: sprintEfficiencyAndCalculationState,
    validateVoucher: voucher,
  },
};

export default combineReducers({
  global: combineReducers({
    actions: combineReducers({
      createProject: createProjectReducer,
      createSprint: createSprintReducer,
      confirmEmail: confirmEmailReducer,
      createProjectOnboarding: createProjectOnboardingReducer,
      jiraDataUserLimit: jiraDataUserLimitReducer,
    }),
    capaMethod: capaMethodReducer,
    filteredHashTags: filteredHashTagsReducer,
    globalError: globalErrorReducer,
    locale: localeReducer,
    project: projectReducer,
    sprintCapacity,
    sprintEfficiencyAndCalculation,
    validateVoucher: validateVoucherReducer,
  }),
});
