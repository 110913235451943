import React, { Fragment, useState } from 'react';
import { ErrorMessage, Field } from '@atlaskit/form';
import { CreatableSelect, OptionsType } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import { LoadingButton } from '@atlaskit/button';
import { useDispatch } from 'react-redux';

import { ModalBackDrop, Modal, Header, Footer, Body } from 'lib/Modal';
import { useReduxState } from 'hooks/useRedux';
import { getValidationState } from '../EventModal/EventUtils';
import { addHashTag } from 'store/slice/project';
import {
  UserState,
  UserStateError,
  useValidationRule,
  key,
  userData,
} from './TeamMemberUtils';

import './TeamMemberAddModal.scss';

export interface ContainerProps {
  children: React.ReactNode;
  className?: string;
}
interface TeamMemberModalProps {
  close: () => void;
  onFormSubmit: (values: any) => void;
}

export const TeamMemberModal: React.FC<TeamMemberModalProps> = (props) => {
  const { close, onFormSubmit } = props;

  const {
    project: { hashTags },
    locale,
  } = useReduxState();
  const dispatch = useDispatch();
  const validationRule = useValidationRule();

  const [isLoading, setIsLoading] = useState(false);
  const hashtagOptions: OptionsType = hashTags.map((ht) => {
    return { label: ht.name, value: ht.id };
  });
  const [userDetails, setUserDetails] = useState<UserState>({
    username: '',
    role: '',
    hashTags: [],
  });
  const [error, setError] = useState<UserStateError>(() => {
    return { username: undefined, role: undefined };
  });

  const validate = (name: key, value: string): string | undefined => {
    return validationRule[name](value);
  };

  const validateStateData = (key?: key) => {
    if (key) {
      setError((prevState: UserStateError) => {
        return {
          ...prevState,
          [key]: validate(key, userDetails[key]),
        };
      });
      return;
    }
    setError((prevState: UserStateError) => {
      return {
        ...prevState,
        username: validate('username', userDetails.username),
        role: validate('role', userDetails.role),
      };
    });
  };

  const isFormDataValid = (): boolean => {
    validateStateData();
    return userData.some(
      (v: any) => validate(v, userDetails[v as unknown as key]) !== undefined
    );
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserDetails((prevState: UserState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onChangeHashTag = (values: any) => {
    setUserDetails((prevState: UserState) => {
      return {
        ...prevState,
        hashTags: values.map((ht: any) => ht.value),
      };
    });
  };

  const createOption = async (value: string) => {
    dispatch(addHashTag(value));
  };

  const onSubmit = async () => {
    if (isFormDataValid()) return;
    setIsLoading(true);
    await onFormSubmit(userDetails);
    setIsLoading(false);
  };

  return (
    <Modal onClose={close}>
      <ModalBackDrop onClick={close}>
        <Header>
          <h3>{locale?.strings?.create_team_member}</h3>
        </Header>
        <Body>
          <div className="form">
            <div className="form-field">
              <label>
                {locale?.strings?.username} <span>*</span>
              </label>
              <Textfield
                required
                name="username"
                className={error?.username ? 'error' : ''}
                value={userDetails?.username}
                onChange={onChangeHandler}
                onBlur={() => validateStateData('username')}
              />
              {error?.username && <ErrorMessage>{error.username}</ErrorMessage>}
            </div>
            <div className="form-field">
              <label>
                {locale?.strings?.role_position} <span>*</span>
              </label>
              <Textfield
                name="role"
                className={error?.role ? 'error' : ''}
                value={userDetails?.role}
                onChange={onChangeHandler}
                onBlur={() => validateStateData('role')}
              />
              {error?.role && <ErrorMessage>{error.role}</ErrorMessage>}
            </div>

            <Field label={locale?.strings?.hashtags} name="hashtags">
              {({ fieldProps, error, meta: { valid } }: any) => (
                <Fragment>
                  <CreatableSelect
                    {...fieldProps}
                    isMulti
                    onCreateOption={createOption}
                    options={hashtagOptions}
                    placeholder={locale?.strings?.hashtags}
                    validationState={getValidationState(error, valid)}
                    onChange={onChangeHashTag}
                  />
                  {error === 'EMPTY' && (
                    <ErrorMessage>
                      {locale?.strings?.validation_participant_min_error.replace(
                        ':n',
                        '2'
                      )}
                    </ErrorMessage>
                  )}
                </Fragment>
              )}
            </Field>
          </div>
        </Body>
        <Footer>
          <span />
          <LoadingButton
            onClick={onSubmit}
            appearance="primary"
            type="submit"
            isLoading={isLoading}>
            {locale?.strings?.button_add}
          </LoadingButton>
        </Footer>
      </ModalBackDrop>
    </Modal>
  );
};

export default TeamMemberModal;
