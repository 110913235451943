import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as ProjectModel from 'models/ProjectModel';
import { USER_TYPE } from 'types/enum';
import { sortDeletedUsers } from 'utils/sort';
export interface ProjectStoreState {
  project: ProjectModel.Project;
  users: ProjectModel.User[];
  sprints: ProjectModel.Sprint[];
  events: ProjectModel.Event[];
  hashTags: ProjectModel.HashTags[];
  offDays: ProjectModel.OffDay[];
  hasErrored: boolean;
  isLoading: boolean;
}

export const initialState: ProjectStoreState = {
  project: {
    id: 0,
    companyName: '',
    projectName: '',
    capacityMethode: '',
    sprintReference: '',
    projectLink: { id: -1, link: '', type: USER_TYPE.USER },
  },
  users: [],
  sprints: [],
  events: [],
  hashTags: [],
  offDays: [],
  hasErrored: false,
  isLoading: true,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    update(state, action: PayloadAction<ProjectModel.Project>) {
      state.project = action.payload;
    },
    error(state, action: PayloadAction<boolean>) {
      state.hasErrored = action.payload;
    },
    updateProjectWithItsSprints(
      state,
      action: PayloadAction<ProjectModel.Sprint[]>
    ) {
      const sprints = action.payload;
      state.sprints = sprints;
    },
    updateProjectWithItsUser(
      state,
      action: PayloadAction<ProjectModel.User[]>
    ) {
      const users = action.payload;
      state.users = sortDeletedUsers(users);
    },
    updateProjectWithItsEvents(
      state,
      action: PayloadAction<ProjectModel.Event[]>
    ) {
      const events = action.payload;
      state.events = events;
    },
    loading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateProjectWithItsHashTags(
      state,
      action: PayloadAction<ProjectModel.HashTags[]>
    ) {
      state.hashTags = action.payload;
    },
    updateProjectWithItsOffDays(
      state,
      action: PayloadAction<ProjectModel.OffDay[]>
    ) {
      state.offDays = action.payload;
    },
  },
});

export const {
  update,
  error,
  loading,
  updateProjectWithItsUser,
  updateProjectWithItsEvents,
  updateProjectWithItsSprints,
  updateProjectWithItsHashTags,
  updateProjectWithItsOffDays,
} = projectSlice.actions;
export default projectSlice.reducer;
