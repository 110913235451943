import axios from 'axios';
import { environment } from 'utils/environment';
import { ValidateVoucher, PaymentRequest } from 'models/voucherPayment';
import { PaymentResponse, VoucherResponse } from 'models/ResponseModel';
import store from 'store';

export const postVoucher = async (body: ValidateVoucher) => {
  try {
    const { data } = await axios.post<VoucherResponse>(
      `${environment.API_BASE_URL}voucher`,
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json',
          responseType: 'json',
        },
      }
    );
    return data;
  } catch (error: any) {
    store.dispatch(error(true));
    throw error;
  }
};

export const postPaymentRequest = async (body: PaymentRequest) => {
  const { data } = await axios.post<PaymentResponse>(
    `${environment.API_BASE_URL}payment`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
        responseType: 'json',
      },
    }
  );
  return data;
};
