import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { EventBus, EVENT_INFO_POP_UP_MODAL } from 'Event';
import Icon from '@atlaskit/icon';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import EmojiFrequentIcon from '@atlaskit/icon/glyph/emoji/frequent';
import Tooltip from '@atlaskit/tooltip';
import { capafield as capaFieldType, Capacity } from 'types/capafield';
import { getStartEndEventTime } from 'utils/date';
import { WithOnBoardingTutorialTarget } from 'components/onBoarding/tutorial';
import { onBoardingTutorial } from 'types/onBoardingTutorial';
import {
  PAGE_USER_INTERACTION_EVENTS,
  fireUserInteractionEvents,
} from 'tracking/userInteraction';
import useOneClickOutside from 'hooks/useOneClickOutside';
import {
  halfCapacity,
  zeroCapacity,
  fullCapacity,
  blockedCapacity,
} from 'assets/icons/icons';
import './CapaField.scss';

const renderEventTime = (
  eventStartDate: Date,
  duration: number,
  abbreviation: string
) => {
  const { endTime, startTime } = getStartEndEventTime(eventStartDate, duration);
  return (
    <span>
      {abbreviation}:{' '}
      <EmojiFrequentIcon label="Time" size="small"></EmojiFrequentIcon> (
      {startTime} - {endTime})
    </span>
  );
};

export interface CapaFieldProps {
  capafield: capaFieldType;
  sprintIndex: number;
  changeCapacity: (capafield: capaFieldType, isEvent: boolean) => void;
  disabledCapa: boolean;
}

export interface CapaFieldStates {}

interface FilteredSubEvents {
  parentId: number;
  id: number;
  time: Date;
  duration: number;
  abbreviation: string;
  inactive: boolean;
}

const capaIcons: any[] = [
  zeroCapacity,
  halfCapacity,
  fullCapacity,
  blockedCapacity,
];

const CapaField: React.FC<CapaFieldProps> = (props) => {
  const { capafield, changeCapacity, disabledCapa, sprintIndex } = props;

  const { path } = useRouteMatch();

  const slotTimeOfDay = moment(capafield.timeStamp).utc().format('A');

  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  useOneClickOutside(ref, () => setShowEventsDropdownMenu(false));

  const subEvents: FilteredSubEvents[] = capafield.subevent
    .filter((se) => {
      const eventStartShift = moment(se.time).format('A');
      return eventStartShift === slotTimeOfDay;
    })
    .map((se) => ({
      parentId: se?.event?.id,
      id: se?.id,
      time: se?.time,
      duration: se?.duration,
      abbreviation: se?.event?.abbreviation,
      inactive: se?.inactive,
    }));

  const [showEventsDropdownMenu, setShowEventsDropdownMenu] =
    React.useState<boolean>(false);

  const showHideEventsMenu = () => {
    setShowEventsDropdownMenu(!showEventsDropdownMenu);
  };

  const showCalendar = subEvents.length > 0;

  const getClasses = (): string => {
    let classes = 'capaField ';
    switch (capafield.capacity) {
      case Capacity.Zero:
        classes += 'capaFieldRed';
        break;
      case Capacity.Half:
        classes += 'capaFieldYellow';
        break;
      case Capacity.Full:
        classes += 'capaFieldGreen';
        break;
      case Capacity.Blocked:
        classes += 'capaFieldGray';
        break;
      default:
        break;
    }
    return classes;
  };

  const changeCapa = () => {
    const isEventAvailable = capafield.subevent.some((sv) => {
      const eventStartShift = moment(sv.time).format('A');
      const eventEndShift = moment(sv.time)
        .add(sv.duration - 1, 'minutes')
        .format('A');

      if (eventStartShift === slotTimeOfDay) return true;

      if (
        slotTimeOfDay === 'PM' &&
        eventStartShift === 'AM' &&
        eventEndShift === 'PM'
      )
        return true;

      return false;
    });
    fireUserInteractionEvents({
      action: PAGE_USER_INTERACTION_EVENTS.LANDING_PAGE.SPRINT.SLOT_UPDATE,
      url: path,
      sprintIteration: sprintIndex + 1,
    });
    changeCapacity(capafield, isEventAvailable);
  };

  const openEventPopUpModal = (index?: number) => {
    if (index) {
      EventBus.emit(EVENT_INFO_POP_UP_MODAL.OPEN, {
        event: [subEvents[index]],
        todaysEventList: null,
      });
    } else {
      EventBus.emit(EVENT_INFO_POP_UP_MODAL.OPEN, {
        event: subEvents,
        todaysEventList: null,
      });
    }
  };

  const calendarIconclick = () => {
    if (subEvents.length === 1) {
      openEventPopUpModal();
      return;
    }
    showHideEventsMenu();
  };
  return (
    <div
      className={
        disabledCapa ? 'capaBorder capaBorder--disable' : 'capaBorder '
      }>
      <div className={getClasses()} onClick={changeCapa}>
        <Icon
          glyph={capaIcons[capafield.capacity]}
          label="calendar icon"
          size="small"
        />
      </div>
      {showCalendar && (
        <div className="capa-event">
          <div className="capa-event-icon" onClick={calendarIconclick}>
            <Tooltip content="View Event">
              <CalendarIcon
                primaryColor={
                  subEvents.length === 1 && subEvents[0].inactive ? 'grey' : ''
                }
                label="events-available"
                size="medium"
              />
            </Tooltip>
          </div>
          {showEventsDropdownMenu && (
            <div ref={ref} className="capa-event__menu active">
              {subEvents?.map((se, index) => {
                return (
                  <div
                    className="capa-event__item"
                    onClick={() => openEventPopUpModal(index)}
                    key={se.id}>
                    {renderEventTime(se.time, se.duration, se?.abbreviation)}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const CapaFieldWithOnBoardingTutorial = WithOnBoardingTutorialTarget(
  CapaField,
  onBoardingTutorial.UPDATE_CAPACITY
);
export default CapaField;
