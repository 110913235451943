import store from 'store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as emailService from 'services/email.service';
import ConfirmEmailModel from 'models/ConfirmEmailModel';
import { next } from '../createProjectOnboarding/createProjectOnboarding.slice';
import { error as globalError } from '../globalError/globaleError.slice';
import { update as createProjectUpdate } from '../createProject';
import { initialState, error, loading, success } from './confirmEmail.slice';
import { USER_TYPE } from 'types/enum';

export type eMailData = {
  mailToken: string;
  mailCode: number;
};

export { initialState, error, loading, success };

export const confirmEmail = createAsyncThunk(
  'confirmEmail',
  async (args: eMailData) => {
    try {
      store.dispatch(error(null));
      store.dispatch(loading(true));
      const data = await emailService.eMailConfirmation(args);
      const project = store.getState().global.actions.createProject.project;
      const eMail: ConfirmEmailModel = {
        createdAt: data?.createdAt,
      };
      const adminLink = data.project.projectLinks.find((it) => {
        return it.type === USER_TYPE.ADMIN;
      });
      const userLink = data.project.projectLinks.find((it) => {
        return it.type === USER_TYPE.USER;
      });
      store.dispatch(loading(false));
      store.dispatch(success(eMail));
      store.dispatch(
        createProjectUpdate({
          ...project,
          adminLink: adminLink?.link as string,
          userLink: userLink?.link as string,
        })
      );
      store.dispatch(next(true));
      return;
    } catch (err: any) {
      store.dispatch(loading(false));
      if (err.response) {
        store.dispatch(error(err.response.data.error));
        return;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);
