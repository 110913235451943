import React, { Fragment, useState } from 'react';
import CapaFieldLegende from './components/capa-field-legende/CapaFieldLegende';
import NewUser from './components/new-user/NewUser';
import { ProjectNotFound } from './components/project-not-found/ProjectNotFound';
import SprintWrapper from './components/sprintWrapper/SprintWrapper';
import UserInfo from './components/user-info/UserInfo';
import WorkType from 'pages/Home/components/work-type/WorkType';
import CapaOverview from 'components/CapaOverview/CapaOverview';
import HashTag from 'components/HashTag';
import { useReduxState } from 'hooks/useRedux';
import Button from '@atlaskit/button';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import { User } from 'models/ProjectModel';
import 'moment/locales/de_locale';
import './ProjectPage.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import EventModalSetup from 'components/Modals/EventDetailModal';
import { activeSprintIndexCalculate } from 'utils/getActiveSprint';
import LoadSprintBtn from './components/load-sprint-btn/LoadSprintBtn';
import GetStarted from 'components/onBoarding/GetStarted';
import WithOnBoardingTutorial from 'components/onBoarding/tutorial';
import { reset } from 'store/slice/createProjectOnboarding/createProjectOnboarding.slice';
import { useDispatch } from 'react-redux';


const Projects: React.FC = () => {
  const {
    locale,
    project,
    filteredHashTags,
    actions: {
      createProjectOnboarding: { selectedIndex },
    },
  } = useReduxState();

  const [showPrevSprint, setShowPrevSprint] = useState<boolean>(false);
  const [showGetStartedBanner, setShowGetStartedBanner] = useState<boolean>(
    () => {
      return selectedIndex !== 0;
    }
  );

  const closeGetStartedBanner = () => {
    dispatch(reset());
    setShowGetStartedBanner(false);
  };

  const dispatch = useDispatch();
  const toggleShowSprint = () =>
    setShowPrevSprint((prevState: boolean) => !prevState);

  const activeSprintIndex: number = activeSprintIndexCalculate(
    project.sprints,
    new Date()
  );

  const renderSprint = () => {
    return project?.sprints?.map?.((sprint, sprintIndex) => {
      const oldSprint = sprintIndex < activeSprintIndex;

      if (!showPrevSprint && oldSprint) return null;
      return (
        <SprintWrapper
          disabledSprint={oldSprint}
          showIntroBlock={sprintIndex <= activeSprintIndex}
          key={sprintIndex}
          sprintIndex={sprintIndex}
          projectLink={project.project.projectLink.link}
          projectName={project.project.projectName}
          sprint={sprint}
          users={project.users}></SprintWrapper>
      );
    });
  };

  const renderUsers = () => {
    const userLists = [...project.users];
    const hashTagsList = filteredHashTags.hashTags.map((ht) => ht.id);
    return userLists.map((user: User, index: number) => {
      if (user.role.type !== 'user') return null;

      if (
        hashTagsList.length > 0 &&
        !user.hashTags.some((ht) => hashTagsList.includes(ht.id))
      )
        return null;

      return (
        <UserInfo
          key={index}
          user={user}
          projectLink={project.project.projectLink.link}
          role={user.role.projectRole}
        />
      );
    });
  };

  const renderSkeleton = () => {
    return (
      <SkeletonTheme color="#e8e7e7" highlightColor="#eee">
        <div className="main">
          <div className="main--sidebar">
            <div className="main__info">
              <div className="main__info-block">
                <div
                  className="main__info-skeleton"
                  style={{ marginTop: '6rem' }}>
                  <Skeleton height={20} />
                  <div className="skeleton-table">
                    <p>
                      <Skeleton width={80} height={10} />
                    </p>
                    <p>
                      <Skeleton width={80} height={10} />
                    </p>
                  </div>
                  <div className="skeleton-table">
                    <p>
                      <Skeleton width={80} height={10} />
                    </p>
                    <p>
                      <Skeleton width={80} height={10} />
                    </p>
                  </div>
                  <div className="skeleton-table">
                    <p>
                      <Skeleton width={80} height={10} />
                    </p>
                    <p>
                      <Skeleton width={80} height={10} />
                    </p>
                  </div>
                  <div className="skeleton-table">
                    <p>
                      <Skeleton width={80} height={10} />
                    </p>
                    <p>
                      <Skeleton width={80} height={10} />
                    </p>
                  </div>
                  <div className="skeleton-table">
                    <p>
                      <Skeleton width={80} height={10} />
                    </p>
                    <p>
                      <Skeleton width={80} height={10} />
                    </p>
                  </div>
                  <div className="skeleton-table">
                    <p>
                      <Skeleton width={80} height={10} />
                    </p>
                    <p>
                      <Skeleton width={80} height={10} />
                    </p>
                  </div>
                </div>
                <WorkType />
              </div>
            </div>
          </div>

          <div className="main--content w-100">
            <div className="main__team">
              <div className="main__head"></div>

              <div className="flex">
                <div className="team-skeleton">
                  <div
                    className="team-skeleton__item"
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '50%',
                      padding: '3px',
                    }}>
                    <Skeleton circle={true} height={42} width={42} />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="sprint-skeleton">
                <div className="sprint-skeleton--block">
                  <div>
                    <p>
                      <Skeleton width={70} height={10} />
                    </p>
                    <p>
                      <Skeleton width={70} height={10} />
                    </p>
                    <p>
                      <Skeleton width={180} height={10} />
                    </p>
                    <p>
                      <Skeleton height={10} />
                    </p>

                    <p style={{ paddingTop: '20px' }}>
                      <Skeleton width={180} height={160} />
                    </p>
                  </div>
                  <div style={{ paddingLeft: '8px' }}>
                    <Skeleton width={50} height={600} />
                  </div>
                  <div style={{ width: '100%', paddingLeft: '10px' }}>
                    <Skeleton height={600} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    );
  };

  const renderShowHideButton = () => {
    if (activeSprintIndex < 1) return null;

    if (showPrevSprint) {
      return (
        <Button
          className="sprint-showhide-btn"
          iconBefore={<ArrowDownIcon label="Left icon" size="small" />}
          appearance="warning"
          spacing="compact"
          onClick={toggleShowSprint}>
          {locale?.strings?.hide_prev_sprints}
        </Button>
      );
    }

    return (
      <Button
        className="sprint-showhide-btn"
        iconBefore={<ArrowUpIcon label="Left icon" size="small" />}
        appearance="warning"
        spacing="compact"
        onClick={toggleShowSprint}>
        {locale?.strings?.show_prev_sprints}
      </Button>
    );
  };

  const renderFragment = () => {
    if (project.isLoading) return renderSkeleton();

    if (project.hasErrored) return <ProjectNotFound />;

    return (
      <React.Fragment>
        <EventModalSetup />
        <div className="main">
          <div className="main--sidebar">
            <div className="main__info">
              <div className="main__info-block">
                <CapaFieldLegende />
                <CapaOverview />
                <HashTag />
                <WorkType />
              </div>
            </div>
          </div>

          <div className="main--content">
            <div className="main__team">
              <div className="main__head">
                <div className="main__head-btn">
                  <NewUser />
                  {renderShowHideButton()}
                </div>
              </div>
              <div className="main__team-block">{renderUsers()}</div>
            </div>
            <div>{renderSprint()}</div>
          </div>
        </div>

        <div
          style={{
            margin: '2rem 0 6rem',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <LoadSprintBtn />
        </div>
        {showGetStartedBanner && <GetStarted close={closeGetStartedBanner} />}
      </React.Fragment>
    );
  };

  return <Fragment>{renderFragment()}</Fragment>;
};

export default WithOnBoardingTutorial(Projects);
