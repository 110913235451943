import React, { Fragment, useEffect, useState } from 'react';
import { ErrorMessage, Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { useDispatch } from 'react-redux';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { Checkbox } from '@atlaskit/checkbox';
import Spinner from '@atlaskit/spinner';
import Button from '@atlaskit/button';
import { CreatableSelect, OptionsType } from '@atlaskit/select';

import { ModalBackDrop, Modal, Header, Body, Footer } from 'lib/Modal';
import { useReduxState } from 'hooks/useRedux';
import { UserInfo } from 'services/user.service';
import { getValidationState } from 'components/Modals/EventModal/EventUtils';
import * as ProjectActions from 'store/slice/project';
import { addHashTag } from 'store/slice/project';
import {
  UserState,
  UserStateError,
  useValidationRule,
  key,
  userData,
} from '../TeamMemberUtils';

import './TeamMemberEditModal.scss';
export interface ContainerProps {
  children: React.ReactNode;
  className?: string;
}

interface TeamMemberUpdateModalProps {
  close: () => void;
  onFormSubmit: (values: any) => Promise<void>;
  userInfo: UserInfo;
  handleDelete?: () => void;
}

export const TeamMemberUpdateModal: React.FC<TeamMemberUpdateModalProps> = (
  props
) => {
  // props
  const { close, onFormSubmit, userInfo, handleDelete } = props;

  const [userDetails, setUserDetails] = useState<UserState>({
    username: userInfo?.username,
    role: userInfo?.role,
    hashTags: userInfo?.hashTags?.map((ht: any) => ht.id) || [],
  });
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<UserStateError>(() => {
    return { username: undefined, role: undefined };
  });

  const dispatch = useDispatch();
  const validationRule = useValidationRule();
  const {
    locale,
    project: {
      users,
      hashTags,
      project: {
        id: projectId,

        projectLink: { link: projectLink },
      },
    },
  } = useReduxState();

  const hashtagOptions: OptionsType = hashTags.map((ht) => {
    return { label: ht.name, value: ht.id };
  });

  const defaultHashTags =
    userInfo.hashTags?.map((ht) => {
      return { label: ht.name, value: ht.id };
    }) || [];

  const sprintCapacityPreference = users?.find(
    (user) => user?.id === userInfo?.userId
  )?.sprints;

  useEffect(() => {
    if (
      sprintCapacityPreference?.every(
        (scp) => scp.capacontribution?.relevant !== false
      )
    ) {
      setSelectAll(true);
    }
  }, [sprintCapacityPreference]);

  const createOption = async (value: string) => {
    await dispatch(addHashTag(value));
  };

  const validate = (name: key, value: string): string | undefined => {
    return validationRule[name](value);
  };

  const validateStateData = (key?: key) => {
    if (key) {
      setError((prevState: UserStateError) => {
        return {
          ...prevState,
          [key]: validate(key, userDetails[key]),
        };
      });
      return;
    }
    setError((prevState: UserStateError) => {
      return {
        ...prevState,
        username: validate('username', userDetails.username),
        role: validate('role', userDetails.role),
      };
    });
  };

  const selectAllClick = async () => {
    setLoading(true);
    const response: any = await dispatch(
      ProjectActions.updateSprintCapacityCalculationPreferencesAllSprints({
        projectId,
        projectLink,
        userId: userInfo?.userId,
        relevant: !selectAll,
      })
    );
    setLoading(false);
    if (!response.error) {
      setSelectAll(!selectAll);
    }
  };

  const handleCapacityPreferenceToggle = async (
    sprintId: number,
    prev: boolean
  ) => {
    setLoading(true);

    await dispatch(
      ProjectActions.updateSprintCapacityCalculationPreferences({
        projectId,
        projectLink,
        sprintId,
        userId: userInfo?.userId,
        relevant: !prev,
      })
    );
    setLoading(false);
  };

  const isFormDataInValid = (): boolean => {
    validateStateData();
    return userData.some(
      (v: any) => validate(v, userDetails[v as unknown as key]) !== undefined
    );
  };

  const saveUserInformation = async () => {
    if (isFormDataInValid()) return;
    await onFormSubmit(userDetails);
  };

  const saveInfoOnClose = async () => {
    if (!isFormDataInValid()) {
      await onFormSubmit(userDetails);
    }
    close();
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserDetails((prevState: UserState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onChangeHashTag = (values: any) => {
    setUserDetails((prevState: UserState) => {
      return {
        ...prevState,
        hashTags: values.map((ht: any) => ht.value),
      };
    });
  };

  return (
    <Modal onClose={close}>
      <ModalBackDrop onClick={close}>
        <Header>
          <h3>{locale?.strings?.edit_team_member}</h3>
        </Header>
        <Body>
          <div className="form">
            <div className="form-field">
              <label>
                {locale?.strings?.username} <span>*</span>
              </label>
              <Textfield
                name="username"
                className={error?.username ? 'error' : ''}
                value={userDetails?.username}
                onChange={onChangeHandler}
                onBlur={saveUserInformation}
              />
              {error?.username && <ErrorMessage>{error.username}</ErrorMessage>}
            </div>
            <div className="form-field">
              <label>
                {locale?.strings?.role_position} <span>*</span>
              </label>
              <Textfield
                name="role"
                className={error?.role ? 'error' : ''}
                value={userDetails?.role}
                onChange={onChangeHandler}
                onBlur={saveUserInformation}
              />
              {error?.role && <ErrorMessage>{error.role}</ErrorMessage>}
            </div>

            <Field label={locale?.strings?.hashtags} name="hashtags">
              {({ fieldProps, error, meta: { valid } }: any) => (
                <Fragment>
                  <CreatableSelect
                    {...fieldProps}
                    isMulti
                    defaultValue={defaultHashTags}
                    onCreateOption={createOption}
                    options={hashtagOptions}
                    placeholder={locale?.strings?.hashtags}
                    validationState={getValidationState(error, valid)}
                    onBlur={saveUserInformation}
                    onChange={onChangeHashTag}
                  />
                  {error === 'EMPTY' && (
                    <ErrorMessage>
                      {locale?.strings?.validation_participant_min_error.replace(
                        ':n',
                        '2'
                      )}
                    </ErrorMessage>
                  )}
                </Fragment>
              )}
            </Field>

            <div className="form-sprint-info">
              <h4>
                {locale?.strings?.user_capacity_conttribution}
                <Button
                  className="sprint-select-all"
                  appearance="link"
                  onClick={selectAllClick}>
                  {!selectAll
                    ? locale?.strings?.select_all
                    : locale?.strings?.unselect_all}
                </Button>
              </h4>

              <p>{locale?.strings?.unselect_contribute_total_capacity}</p>
              <div className="sprint-list">
                {sprintCapacityPreference?.map((item, index) => {
                  const isChecked =
                    !item?.capacontribution || item?.capacontribution?.relevant;
                  return (
                    <Checkbox
                      key={item.id}
                      value={item.id}
                      label={`Sprint ${index + 1}`}
                      onChange={() =>
                        handleCapacityPreferenceToggle(item.id, isChecked)
                      }
                      isChecked={isChecked}
                      size="medium"
                      name="checkbox-default"
                      testId="cb-default"
                    />
                  );
                })}
              </div>
              {loading && <Spinner size="small" />}
            </div>
          </div>
        </Body>
        <Footer>
          <div className="ct-modal__footer--border">
            <Button
              appearance="subtle"
              iconBefore={<TrashIcon label="Delete icon" size="small" />}
              onClick={handleDelete}>
              {locale?.strings?.remove_team_member}
            </Button>

            <Button onClick={saveInfoOnClose}>{locale?.strings?.close}</Button>
          </div>
        </Footer>
      </ModalBackDrop>
    </Modal>
  );
};

export default TeamMemberUpdateModal;
