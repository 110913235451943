import React, { Fragment } from 'react';
import './IntroBlockNoCapacities.scss';
import PersonWithCrossIcon from '@atlaskit/icon/glyph/person-with-cross';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useReduxState } from 'hooks/useRedux';

const IntroBlockNoCapacities: React.FC = () => {
  const { locale } = useReduxState();
  return (
    <Fragment>
      <SkeletonTheme color="#202020" highlightColor="#444">
        <p>
          <Skeleton count={3} />
        </p>
      </SkeletonTheme>

      <div className="intro">
        <div className="intro__container">
          <h4 className="intro__title">
            <PersonWithCrossIcon
              label="PersonWithCrossIcon"
              primaryColor="#ccc"
              size="large"
            />
          </h4>
          <p className="intro__desc">{locale?.strings?.no_capacities}</p>
        </div>
      </div>
    </Fragment>
  );
};

export default IntroBlockNoCapacities;
