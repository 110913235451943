import React, { useEffect } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  getProjectDetails,
  getProjectUsers,
  getProjectEvents,
  getSprintEfficiencyAndCalculation,
} from 'store/slice/project';
import FeedbackButton from 'components/Feedback-Button/FeedbackButton';
import Projects from './ProjectPage';
import Event from '../event';
import Calendar from '../Calendar';
export interface IProjectRouteParams {
  projectLink: string;
  projectId: string;
}

const ProjectToolLandingPage: React.FC = () => {
  const dispatch = useDispatch();
  const { projectLink, projectId } = useParams<IProjectRouteParams>();
  const { path } = useRouteMatch();

  useEffect(() => {
    const pId = projectId as unknown as number;
    dispatch(getProjectDetails({ projectLink, projectId: pId }));
    dispatch(getProjectUsers({ projectLink, projectId: pId }));
    dispatch(getProjectEvents({ projectLink, projectId: pId }));
    dispatch(
      getSprintEfficiencyAndCalculation({ projectLink, projectId: pId })
    );
  }, []);
  
  return (
    <React.Fragment>
      <FeedbackButton />
      <Switch>
        <Route exact path={`${path}`} component={Projects} />
        <Route path={`${path}/events`} component={Event} />
        <Route
          path={`${path}/calendar`}
          render={() => (
            <Calendar projectLink={projectLink} projectId={projectId} />
          )}
        />
      </Switch>
    </React.Fragment>
  );
};

export default ProjectToolLandingPage;
