import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useHistory, withRouter } from 'react-router-dom';
import { LoadingButton, ButtonGroup } from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import Form, { Field, ErrorMessage, FormFooter } from '@atlaskit/form';
import * as projectService from 'services/project.service';
import {
  createProject,
  CreateProjectActionArgs,
  startProjectCreationTimer,
  updateDetailsFormTime,
} from 'store/slice/createProject';
import {
  jiraCode,
  getJiraProjectSprintData,
  getJiraAuthUrl,
  jiraAuthCode,
} from 'store/slice/JiraData';
import { useDispatch } from 'react-redux';
import { useReduxState } from 'hooks/useRedux';
import { fireProjectCreationTimeOnProjectDetailsForm } from 'tracking/createProject';
import JiraProjectModal from 'components/Modals/JiraProjectModal';
import { jiraVoucher } from 'store/slice/voucherPayment';
import qs from 'qs';

export interface IJiraRouteParams {
  code: string;
  state: string;
}

const ProjectForm: React.FC = () => {
  const {
    locale,
    actions: {
      createProject: createProjectState,
      jiraDataUserLimit: {
        ProjectDetailsFromJira,
        jiraAuthenticationUrl,
        isJiraProject,
      },
    },
    validateVoucher: { validateVoucher },
  } = useReduxState();

  const dispatch = useDispatch();

  const [jiraProjectModal, setJiraProjectModal] = useState(false);
  const closejiraProjectImport = () => setJiraProjectModal(false);
  const [jiraProjectDetails, setJiraProjectDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const jiraApi = () => {
    setIsLoading(true);
    dispatch(getJiraAuthUrl());
    setIsLoading(false);
  };

  useEffect(() => {
    if (jiraAuthenticationUrl.redirect_uri) {
      const url = jiraAuthenticationUrl.redirect_uri;
      const splitedUrl = url?.split('?');
      const paramObj = qs.parse(splitedUrl[1]);
      const voucher = validateVoucher.voucher;
      const newState = `${paramObj.state}voucher${voucher}`;

      const parameters = {
        audience: paramObj.audience,
        client_id: paramObj.client_id,
        scope: paramObj.scope,
        redirect_uri: paramObj.redirect_uri,
        state: newState,
        response_type: paramObj.response_type,
        prompt: paramObj.prompt,
      };
      const jiraUrl = `${splitedUrl[0]}?${qs.stringify(parameters)}`;
      window.open(`${jiraUrl}`, '_self');
    }
  }, [jiraAuthenticationUrl]);

  const { search } = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(search);

  const code = queryParams.get('code');
  const jiraState = queryParams.get('state');
  const jiraStateVoucher: string = jiraState?.split(
    'voucher'
  )[1] as unknown as string;
  const state: string = jiraState?.split('voucher')[0] as unknown as string;

  useEffect(() => {
    queryParams.delete('code');
    queryParams.delete('state');
    history.replace({
      search: queryParams.toString(),
    });
  }, []);

  const args: jiraAuthCode = {
    code: code,
    state: state,
  };

  useEffect(() => {
    if (code && jiraStateVoucher) {
      dispatch(jiraCode(args));
      dispatch(
        jiraVoucher({
          voucher: jiraStateVoucher,
        })
      );
      setJiraProjectModal(true);
    }
  }, [code, jiraStateVoucher]);

  const [projectDetails, setProjectDetails] = useState({
    projectName: '',
    companyName: '',
    username: '',
    email: '',
  });

  useEffect(() => {
    if (ProjectDetailsFromJira.teamName) {
      const projectDet = {
        projectName: ProjectDetailsFromJira.projectName,
        companyName: ProjectDetailsFromJira.teamName,
        username: ProjectDetailsFromJira.projectManager,
        email: ProjectDetailsFromJira.emailAddress,
      };
      setProjectDetails(projectDet);
      setJiraProjectDetails(true);
    }
  }, [ProjectDetailsFromJira]);

  const onChange = (event: any) => {
    setProjectDetails({
      ...projectDetails,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (data: projectService.ProjectData) => {
    const voucher = validateVoucher.voucher;
    const args: CreateProjectActionArgs = {
      companyName: data.companyName.trim(),
      projectName: data.projectName.trim(),
      username: data.username.trim(),
      email: data.email.trim(),
      voucher: voucher,
    };
    dispatch(createProject(args));
    isJiraProject && dispatch(getJiraProjectSprintData());
    return;
  };

  const [timerStarted, setTimerStarted] = React.useState<boolean>(false);

  const initializeProjectCreationTimer = () => {
    if (!timerStarted) {
      dispatch(startProjectCreationTimer());
      setTimerStarted(true);
    }
  };

  React.useEffect(() => {
    // when the project form unmounts
    return function timerTrackAndUpdateRedux() {
      // send time spend on initial form to the google analytics
      // also update the redux state with the time at which this form is completed
      const newtime = Date.now();
      fireProjectCreationTimeOnProjectDetailsForm(newtime);
      dispatch(updateDetailsFormTime(newtime));
    };
  }, []);

  return (
    <div>
      {jiraProjectModal && <JiraProjectModal close={closejiraProjectImport} />}
      <Form onSubmit={handleSubmit}>
        {({ formProps }) => (
          <form {...formProps}>
            <Field
              name="projectName"
              label={locale?.strings?.project_name}
              isRequired
              validate={(value) =>
                value && value.length < 1
                  ? locale?.strings?.validation_too_short
                  : value && value.length > 30
                  ? locale?.strings?.validation_too_long
                  : undefined
              }
              defaultValue={
                projectDetails
                  ? projectDetails?.projectName
                  : createProjectState?.project?.projectName
              }>
              {({ fieldProps, error }) => (
                <Fragment>
                  <TextField
                    autoComplete="off"
                    {...fieldProps}
                    onBlur={initializeProjectCreationTimer}
                    value={projectDetails.projectName}
                    onChange={onChange}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </Fragment>
              )}
            </Field>
            <Field
              name="companyName"
              label={locale?.strings?.organization_name}
              isRequired
              validate={(value) =>
                value && /^[A-Za-z0-9_äÄöÖüÜß]/.test(value) !== true
                  ? locale?.strings?.validation_no_special_characters
                  : value && value.length < 1
                  ? locale?.strings?.validation_too_short
                  : value && value.length > 30
                  ? locale?.strings?.validation_too_long
                  : undefined
              }
              defaultValue={
                createProjectState?.project?.companyName ||
                projectDetails?.companyName
              }>
              {({ fieldProps, error }) => (
                <Fragment>
                  <TextField
                    autoComplete="off"
                    {...fieldProps}
                    onBlur={initializeProjectCreationTimer}
                    value={projectDetails.companyName}
                    onChange={onChange}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </Fragment>
              )}
            </Field>
            <Field
              name="username"
              label={locale?.strings?.project_manager}
              isRequired
              validate={(value) =>
                value && value.length < 1
                  ? locale?.strings?.validation_too_short
                  : value && /^\S.*/.test(value) !== true
                  ? locale?.strings?.validation_no_space
                  : value && value.length > 20
                  ? locale?.strings?.validation_too_long
                  : undefined
              }
              defaultValue={
                createProjectState?.project?.username ||
                projectDetails?.username
              }>
              {({ fieldProps, error }) => (
                <Fragment>
                  <TextField
                    autoComplete="off"
                    {...fieldProps}
                    onBlur={initializeProjectCreationTimer}
                    value={projectDetails?.username}
                    onChange={onChange}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </Fragment>
              )}
            </Field>
            <Field
              name="email"
              label={locale?.strings?.email_address}
              defaultValue={
                createProjectState?.project?.email || projectDetails?.email
              }
              isRequired
              validate={(value) =>
                value &&
                //FIXME: Validator in Funktion auslagern --> No-useless-escape
                // RegEx from -> https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
                /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                  value
                ) !== true
                  ? locale?.strings?.validation_email
                  : undefined
              }>
              {({ fieldProps, error }) => (
                <Fragment>
                  <TextField
                    {...fieldProps}
                    onBlur={initializeProjectCreationTimer}
                    value={projectDetails?.email}
                    onChange={onChange}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </Fragment>
              )}
            </Field>
            <FormFooter>
              {jiraProjectDetails ? (
                <LoadingButton
                  isDisabled={createProjectState?.isLoading}
                  appearance="primary"
                  type="submit"
                  isLoading={createProjectState?.isLoading}>
                  {locale?.strings?.next}
                </LoadingButton>
              ) : (
                <ButtonGroup>
                  <LoadingButton onClick={jiraApi} isLoading={isLoading}>
                    {locale?.strings?.integrate_jira}
                  </LoadingButton>
                  <LoadingButton
                    isDisabled={createProjectState?.isLoading}
                    appearance="primary"
                    type="submit"
                    isLoading={createProjectState?.isLoading}>
                    {locale?.strings?.create_project}
                  </LoadingButton>
                </ButtonGroup>
              )}
            </FormFooter>
          </form>
        )}
      </Form>
    </div>
  );
};

export default withRouter(ProjectForm);
