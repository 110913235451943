import React from 'react';
import EventDetailModal, { EventState } from './Info';
import { EventBus, EVENT_INFO_POP_UP_MODAL } from 'Event';

const initialState: EventState = {
  event: null,
  todaysEventList: null,
};

const EventModalSetup: React.FC = () => {
  const [EventPopUp, setEventPopUp] = React.useState<boolean>(false);
  const [eventState, setEventState] = React.useState<EventState>(() => {
    return initialState;
  });

  const closeEventPopUpModal = () => {
    setEventState(initialState);
    setEventPopUp(false);
  };

  const openEventPopUpModal = (args: EventState) => {
    setEventState(args);
    setEventPopUp(true);
  };

  React.useEffect(() => {
    // attach this listeners on component mount
    EventBus.on(EVENT_INFO_POP_UP_MODAL.OPEN, openEventPopUpModal);
    EventBus.on(EVENT_INFO_POP_UP_MODAL.CLOSE, closeEventPopUpModal);

    // cleanup
    return function detach() {
      EventBus.remove(EVENT_INFO_POP_UP_MODAL.OPEN);
      EventBus.remove(EVENT_INFO_POP_UP_MODAL.CLOSE);
    };
  }, []);

  return (
    <React.Fragment>
      {EventPopUp && (
        <EventDetailModal
          eventState={eventState}
          close={closeEventPopUpModal}
        />
      )}
    </React.Fragment>
  );
};

export default EventModalSetup;
