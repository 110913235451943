import React from 'react';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import { getInitials } from 'utils/string';
import { IAvatarUserGroup } from 'utils/interfaces';

const UserDropDown: React.FC<IAvatarUserGroup> = ({ data }) => {
  return (
    <div className="c-avatar__item">
      <DropdownMenu trigger={<p>+{data.length}</p>} position="bottom right">
        <DropdownItemGroup>
          {data?.map((datum) => {
            return (
              <DropdownItem key={datum?.key}>
                <div className="c-avatar__dd_item flex items-center">
                  <p>{getInitials(datum.name)}</p> {datum.name}
                </div>
              </DropdownItem>
            );
          })}
        </DropdownItemGroup>
      </DropdownMenu>
    </div>
  );
};

export default UserDropDown;
