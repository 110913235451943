import { OptionsPropType } from '@atlaskit/radio/types';

import {
  emojiOne,
  emojiTwo,
  emojiThree,
  emojiFour,
  emojiFive,
} from 'assets/icons/icons';

export const SUGGESTIONS: OptionsPropType = [
  { name: 'topic', value: 'bug', label: 'Bug' },
  { name: 'topic', value: 'suggestion', label: 'Suggestions' },
  { name: 'topic', value: 'other', label: 'Others' },
];

export const EMOJI_LIST = [
  {
    label: 'Angry',
    value: 1,
    component: emojiOne,
  },
  {
    label: 'Sad',
    value: 2,
    component: emojiTwo,
  },
  {
    label: 'Confused',
    value: 3,
    component: emojiThree,
  },
  {
    label: 'Good',
    value: 4,
    component: emojiFour,
  },
  {
    label: 'Happy',
    value: 5,
    component: emojiFive,
  },
];
