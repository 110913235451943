import React, { Fragment, useState } from 'react';
import Button from '@atlaskit/button';
import { ModalBackDrop, Modal, Header, Body, Footer } from 'lib/Modal';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import { LoadingButton } from '@atlaskit/button';
import Textfield from '@atlaskit/textfield';
import { useReduxState } from 'hooks/useRedux';
import { useDispatch } from 'react-redux';
import '../ModalStyle.scss';
import { payment } from 'store/slice/voucherPayment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  close: () => void;
}

const PaymentRequestModal: React.FC<Props> = (props) => {
  const { close } = props;

  const { locale } = useReduxState();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = (values: any) => {
    try {
      const requestPaymentObject = {
        userEmail: values?.email,
        text: values?.text,
      };
      setIsLoading(true);
      dispatch(payment(requestPaymentObject));
      setIsLoading(false);
      toast(locale?.strings?.voucher_email);
      close();
    } catch (err) {
      console.log({ err });
    }
  };

  return (
        <Modal onClose={close}>
          <ModalBackDrop>
            <Header>
              <h3> {locale?.strings?.request_voucher}</h3>
            </Header>
            <Form onSubmit={onSubmit}>
              {({ formProps }) => (
                <form {...formProps}>
                  <Body>
                    <div className="form-fields">
                      <Field
                        isRequired
                        name="email"
                        defaultValue=""
                        label={locale?.strings?.email_address}
                        validate={(value) =>
                          value &&
                          // eslint-disable-next-line no-control-regex

                          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                            value
                          ) !== true
                            ? locale?.strings?.validation_email
                            : undefined
                        }>
                        {({ fieldProps, error }) => (
                          <Fragment>
                            <Textfield {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </Fragment>
                        )}
                      </Field>
                      <Field
                        isRequired
                        label={locale?.strings?.message}
                        name="text"
                        defaultValue=""
                        validate={(value) =>
                          value && value.length < 1
                            ? locale?.strings?.validation_too_short
                            : undefined
                        }>
                        {({ fieldProps, error }) => (
                          <Fragment>
                            <Textfield {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </Fragment>
                        )}
                      </Field>
                    </div>
                  </Body>
                  <Footer>
                    <span></span>
                    <div>
                      <Button className="secondary-btn" onClick={close}>
                        {locale?.strings?.cancel}
                      </Button>
                      <LoadingButton
                        appearance="primary"
                        type="submit"
                        isLoading={isLoading}>
                        {locale?.strings?.request}
                      </LoadingButton>
                    </div>
                  </Footer>
                </form>
              )}
            </Form>
          </ModalBackDrop>
        </Modal>
  );
};

export default PaymentRequestModal;
