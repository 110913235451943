import { environment } from 'utils/environment';
import axios from 'axios';
import store from 'store';
import { EventResponse, SubEventResponse } from 'models/ResponseModel';

export type eventData = {
  name: string;
  abbreviation: string;
  recurring: boolean;
  interval?: number;
  time: string;
  duration: number;
  users: Array<string | number>;
  link?: string;
  eventId?: number;
};

export interface subeventData {
  time: string;
  duration: number;
  users: Array<string | number>;
  link?: string;
  subeventId: number;
}

export const createEvent = async (data: eventData) => {
  const {
    global: {
      project: {
        project: {
          id,
          projectLink: { link },
        },
      },
    },
  } = store.getState();
  const response = await axios.post<EventResponse>(
    `${environment.API_BASE_URL}event/${id}?projectLink=${link}`,
    data
  );
  return response.data;
};

export const editEvent = async (data: eventData) => {
  const {
    global: {
      project: {
        project: {
          id,
          projectLink: { link },
        },
      },
    },
  } = store.getState();

  const updateData = {
    name: data.name,
    abbreviation: data.abbreviation,
    time: data.time,
    interval: data.interval,
    recurring: data.recurring,
    duration: data.duration,
    users: data.users,
    link: data.link,
  };

  const response = await axios.put<EventResponse>(
    `${environment.API_BASE_URL}event/${id}/${data.eventId}?projectLink=${link}`,
    updateData
  );
  return response.data;
};

export const deleteEvent = async (eventId: number) => {
  const {
    global: {
      project: {
        project: {
          id,
          projectLink: { link },
        },
      },
    },
  } = store.getState();

  const response = await axios.delete<EventResponse>(
    `${environment.API_BASE_URL}event/${id}/${eventId}?projectLink=${link}`
  );
  return response.data;
};

export const editSubevent = async (data: subeventData) => {
  const {
    global: {
      project: {
        project: {
          id,
          projectLink: { link },
        },
      },
    },
  } = store.getState();

  const updateData = {
    time: data.time,
    duration: data.duration,
    users: data.users,
    link: data.link,
  };

  const response = await axios.put<SubEventResponse>(
    `${environment.API_BASE_URL}event/subevent/${id}/${data.subeventId}?projectLink=${link}`,
    updateData
  );
  return response.data;
};

export const deleteSubevent = async (subeventId: number) => {
  const {
    global: {
      project: {
        project: {
          id,
          projectLink: { link },
        },
      },
    },
  } = store.getState();

  const response = await axios.delete<SubEventResponse>(
    `${environment.API_BASE_URL}event/subevent/${id}/${subeventId}?projectLink=${link}`
  );
  return response.data;
};
