import React from 'react';
import ReactDOM from 'react-dom';

interface Props {
  parent?: Document;
  className?: string;
}

const ReactPortal: React.FC<Props> = ({ className, parent, children }) => {
  const element = React.useMemo(() => {
    return document.createElement('div');
  }, []);

  React.useEffect(() => {
    const target = parent && parent.appendChild ? parent : document.body;
    const classList = ['ct__portal'];
    if (className) className.split(' ').forEach((it) => classList.push(it));
    classList.forEach((it) => element.classList.add(it));

    target.appendChild(element);

    // unmounting
    return function unmountPortal() {
      target.removeChild(element);
    };
  }, [element, parent, className]);

  return ReactDOM.createPortal(children, element);
};

export default ReactPortal;
