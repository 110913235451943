import React from 'react';
import AddIcon from '@atlaskit/icon/glyph/add';
import Button from '@atlaskit/button';
import { WithOnBoardingTutorialTarget } from 'components/onBoarding/tutorial';
import { onBoardingTutorial } from 'types/onBoardingTutorial';
import { useReduxState } from 'hooks/useRedux';

interface ICustomButtonrops {
  open: () => void;
}
export const CustomButton = WithOnBoardingTutorialTarget(
  ({ open }: ICustomButtonrops) => {
    const { locale } = useReduxState();
    return (
      <Button
        className="intro__btn"
        iconBefore={<AddIcon label="Star icon" size="small" />}
        appearance="primary"
        onClick={open}>
        {locale?.strings?.welcome_add_team_member}
      </Button>
    );
  },
  onBoardingTutorial.CREATENEWUSER
);

interface ICustomAddIconProps {
  open: () => void;
}
export const CustomAddIcon = WithOnBoardingTutorialTarget(
  ({ open }: ICustomAddIconProps) => {
    const { locale } = useReduxState();
    return (
      <div className="container-user" onClick={open}>
        <div className="container-user-button">
          <AddIcon label="" size="small"></AddIcon>
        </div>
        <p className="container-user-title">{locale?.strings?.add_member}</p>
      </div>
    );
  },
  onBoardingTutorial.ADDNEWMEMBER
);
