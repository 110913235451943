import axios from 'axios';
import { environment } from 'utils/environment';

import { JiraAuthenticationUrl } from 'models/jiraAuthenticationModel';
import {
  JiraProjectResponse,
  JiraProjectDetailsResponse,
  JiraSprintProjectData,
} from 'models/ResponseModel';

import { jiraCode } from 'store/slice/JiraData';
export type jiraCode = {
  code: string | null;
  state: string | null;
};

export type jiraProjectCode = {
  projectId: string;
  projectKey: string;
  projectName: string | null;
};

export type jiraUserPost = {
  displayName: string;
  emailAddress: string;
};

export const getJiraAuthUrl = async () => {
  const { data } = await axios.get<JiraAuthenticationUrl>(
    `${environment.API_BASE_URL}/jira/oauth/initialize`
  );
  return data;
};

export const getJiraProjects = async (args: jiraCode) => {
  const { data } = await axios.get<JiraProjectResponse>(
    `${environment.API_BASE_URL}jira/oauth/projects/${args.code}/${args.state}`
  );
  return data.projects;
};

export const getprojectDetails = async (
  args: jiraProjectCode,
  state: string | null
) => {
  const { data } = await axios.put<JiraProjectDetailsResponse>(
    `${environment.API_BASE_URL}jira/oauth/project/${state}`,
    JSON.stringify(args),
    {
      headers: {
        'Content-Type': 'application/json',
        responseType: 'json',
      },
    }
  );
  return data;
};

export const getSprintData = async (state: string | null) => {
  const { data } = await axios.get<JiraSprintProjectData>(
    `${environment.API_BASE_URL}jira/oauth/project/sprint/${state}`
  );
  return data;
};

export const getJiraUser = async (
  state: string | null,
  projectId: number | null
) => {
  const { data } = await axios.get(
    `${environment.API_BASE_URL}/jira/oauth/project/users/${state}/${projectId}`
  );
  return data;
};

export const postJiraUsers = async (
  args: string[] | null,
  projectId: number | null
) => {
  const user = {
    users: args,
  };
  const { data } = await axios.post<any>(
    `${environment.API_BASE_URL}jira/oauth/project/users/sync/${projectId}`,
    user
  );
  return data;
};
