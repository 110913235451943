import { createAsyncThunk } from '@reduxjs/toolkit';
import store from 'store';
import * as voucherService from 'services/voucherPayment.service';
import { error as globalError } from '../globalError/globaleError.slice';
import {
  error,
  paymentRequest,
  loading,
  validateVoucher,
} from './voucherPayment.slice';
import { next } from '../createProjectOnboarding/createProjectOnboarding.slice';
import { ValidateVoucher, PaymentRequest } from 'models/voucherPayment';

export const voucher = createAsyncThunk(
  'voucher',
  async (args: ValidateVoucher) => {
    try {
      store.dispatch(error(false));
      store.dispatch(loading(true));
      const data = await voucherService.postVoucher(args);
      store.dispatch(validateVoucher({voucher:data?.voucher}));
      store.dispatch(next(true));
      store.dispatch(loading(false));
    } catch (err: any) {
      store.dispatch(error(true));
      if (err.response) {
        console.log(err);
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const jiraVoucher = createAsyncThunk(
  'voucherStore',
  async (args: ValidateVoucher) => {
    try {
      store.dispatch(error(false));
      store.dispatch(loading(true));
      store.dispatch(validateVoucher(args));
      store.dispatch(next(false));
      store.dispatch(loading(false));
    } catch (err: any) {
      store.dispatch(error(true));
      if (err.response) {
        console.log(err);
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const payment = createAsyncThunk(
  'payment',
  async (args: PaymentRequest) => {
    try {
      store.dispatch(error(false));
      store.dispatch(loading(true));
      const data = await voucherService.postPaymentRequest(args);
      store.dispatch(paymentRequest(data));
      store.dispatch(loading(false));
    } catch (err: any) {
      store.dispatch(error(true));
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);
