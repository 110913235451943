import React, { useEffect, useState } from 'react';
import ProjectOnboardingPage from './components/CreateProjectOnboarding';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import './NewProjectPage.scss';
import { useReduxState } from 'hooks/useRedux';

export const NewProjectPage: React.FC = () => {
  const {
    actions: {
      createProjectOnboarding: { selectedIndex: sIndex },
    },
  } = useReduxState();

  const [progressBar, setprogressBar] = useState(false);

  useEffect(() => {
    if (sIndex != 0) {
      setprogressBar(true);
    }
  }, [sIndex]);

  return (
    <div>
      <div className="background-container__bar"></div>
      <div className="background-container">
        <div className="background-container__image"></div>
        <div className="background-container__bar"></div>
      </div>
      <div className="container">
        <div className="container-card">
          <div className="container-tracker">
            {progressBar && <ProgressBar />}
            {/* <ProgressTracker items={items} /> */}
          </div>
          <ProjectOnboardingPage />
        </div>
      </div>
    </div>
  );
};
