import React from 'react';
import Badge from '@atlaskit/badge';
import Avatar from './avatar/Avatar';
import * as UserService from 'services/user.service';
import { HashTags } from 'models/ProjectModel';
import { useReduxState } from 'hooks/useRedux';
import './userInfo.scss';
import { deleteUser } from 'store/slice/project';
import { useDispatch } from 'react-redux';
export interface UserInfoProps {
  user: {
    id: number;
    email: string;
    username: string;
    deletedDate: null | string;
    hashTags: HashTags[];
  };
  projectLink: string;
  role: string;
}

const UserInfo: React.FC<UserInfoProps> = (props) => {
  const { user, projectLink, role } = props;
  const dispatch = useDispatch();
  const userInfo = {
    userId: user.id,
    username: user.username,
    hashTags: user.hashTags,
    role,
  };

  const { locale } = useReduxState();

  const handleDelete = async () => {
    const args: UserService.DeleteUserArgs = {
      userId: user.id,
      projectLink: projectLink,
    };
    dispatch(deleteUser(args));
  };

  return (
    <div className="userInfo">
      <div className="userInfo__avatar">
        <Avatar
          handleDelete={handleDelete}
          userInfo={userInfo}
          isUserDeleted={Boolean(user.deletedDate)}></Avatar>
        <div className="userInfo__avatar-badge">
          {Boolean(user.deletedDate) && (
            <Badge>{locale?.strings?.user_inactive}</Badge>
          )}
        </div>
      </div>
      <div className="userInfo-username">
        <p>{user.username}</p>
      </div>
      <div className="userInfo-role">
        <p>{role}</p>
      </div>
      <div className="userInfo-container">
        <div className="userInfo-container__time">
          {locale?.strings?.morning}
        </div>
        <div className="userInfo-container__time">
          {locale?.strings?.evening}
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
