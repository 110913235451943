import store from 'store';
import { error as globalError } from '../globalError/globaleError.slice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { next } from '../createProjectOnboarding/createProjectOnboarding.slice';
import { postProject } from 'services/project.service';
import {
  update,
  error,
  create,
  loading,
  initializeProjectTimer,
  updateDetailsFormCompletedTime,
  updateConfirmFormCompletedTime,
} from './createProject.slice';
import CreateProjectModel from 'models/CreateProjectModel';

export { update, error, loading };

export const startProjectCreationTimer: any = createAsyncThunk(
  'project/timer/initialize',
  async () => {
    store.dispatch(initializeProjectTimer());
  }
);

export const updateDetailsFormTime: any = createAsyncThunk(
  'project/time/details-form',
  async (duration: number) => {
    store.dispatch(updateDetailsFormCompletedTime(duration));
  }
);

export const updateConfirmFormTime: any = createAsyncThunk(
  'project/time/confirm-email-form',
  async (duration: number) => {
    store.dispatch(updateConfirmFormCompletedTime(duration));
  }
);

export type CreateProjectActionArgs = {
  companyName: string;
  projectName: string;
  username: string;
  email: string;
  voucher: string;
};
export const createProject: any = createAsyncThunk(
  'project/create',
  async (body: CreateProjectActionArgs) => {
    try {
      store.dispatch(error(false));
      store.dispatch(loading(true));
      const data = await postProject(body);
      const project: CreateProjectModel = {
        id: data.id,
        companyName: data.companyName,
        projectName: data.projectName,
        username: data.username,
        email: data.email,
        mailToken: data.emailToken.mailToken,
        adminLink: '',
        userLink: '',
      };
      store.dispatch(create(project));
      store.dispatch(next(true));
    } catch (err: any) {
      store.dispatch(error(true));
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
        throw err;
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);
