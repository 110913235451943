/* eslint-disable no-unused-vars */
enum StorageKeys {
  USERPROFILECOLORS = 'user-profile-color',
}

const INITIAL_LOCAL_STATE = {
  [StorageKeys.USERPROFILECOLORS]: {},
};

const storeItemInLocalStorage = (key: string, data: any) => {
  window.localStorage.setItem(key, JSON.stringify(data));
  return;
};
const getItemFromLocalStorage = (key: string) => {
  const data = window.localStorage.getItem(key);
  return data ? JSON.parse(data) : data;
};

export const useUserProfileColors = (projectId: number) => {
  const projectLocalState =
    getItemFromLocalStorage(projectId as unknown as string) ||
    INITIAL_LOCAL_STATE;
  const userColors = projectLocalState[StorageKeys.USERPROFILECOLORS] || {};
  const getUserColor = (id: number | string) => {
    return userColors[id];
  };
  const saveUserColor = (id: number | string, color: string) => {
    userColors[id] = color;
    projectLocalState[StorageKeys.USERPROFILECOLORS] = userColors;
    storeItemInLocalStorage(projectId as unknown as string, projectLocalState);
  };
  return {
    getUserColor,
    saveUserColor,
  };
};

export default useUserProfileColors;
