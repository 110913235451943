import { useReduxState } from 'hooks/useRedux';

export interface UserState {
  username: string;
  role: string;
  hashTags: number[];
}

export interface UserStateError {
  username: undefined | string;
  role: undefined | string;
}

export type fn = (value: string) => string | undefined;
export type key = 'username' | 'role';
export const userData = ['username', 'role'];

export const useValidationRule = (): Record<key, fn> => {
  const { locale } = useReduxState();
  return {
    username: (value: string): string | undefined =>
      !value
        ? locale?.strings?.validation_not_empty
        : value.length < 1
        ? locale?.strings?.validation_too_short
        : value && /^\S.*/.test(value) !== true
        ? locale?.strings?.validation_no_space
        : value && value.length > 20
        ? locale?.strings?.validation_max_20
        : undefined,
    role: (value: string): string | undefined =>
      !value
        ? locale?.strings?.validation_not_empty
        : value.length < 1
        ? locale?.strings?.validation_too_short
        : value && /^\S.*/.test(value) !== true
        ? locale?.strings?.validation_no_space
        : value && value.length > 20
        ? locale?.strings?.validation_max_20
        : undefined,
  };
};
