import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import ProjectToolLandingPage from 'pages/project-page/';
import { NewProjectPage } from 'pages/Create-Project-Form/NewProjectPage';
import  ProjectForm  from 'pages/Create-Project-Form/components/input-fields/CreateProjectForm';
import { Navbar } from 'components/Navbar/Navbar';
// import { EmailConfirmPage } from 'pages/email-confirm-page/EmailConfirmPage';
import { GlobalErrorWarning } from 'components/Global-Error-Warning/GlobalErrorWarning';
import Tracker from './components/Tracker';

import 'assets/styles/style.scss';
export const App = () => {
  return (
    <BrowserRouter>
      <GlobalErrorWarning></GlobalErrorWarning>
      <Navbar></Navbar>
      <Tracker>
        <Switch>
          <Route exact path="/create-project" component={NewProjectPage} />
          <Route
            path="/project/:projectId/:projectLink"
            component={ProjectToolLandingPage}
          />
          <Route path="/?code=:code/:state"
           component={ProjectForm}
          />
          {/* <Route
            exact
            path="/email/confirmed/:id"
            component={EmailConfirmPage}
          /> */}
          <Route component={NewProjectPage} />
        </Switch>
      </Tracker>
    </BrowserRouter>
  );
};

export default App;
