import { Capacity } from 'types/capacity';
import { useReduxState } from 'hooks/useRedux';
import { userCapa } from 'store/slice/sprintCapacity/sprintCapacity.slice';

const useCapaCalculation = () => {
  const {
    filteredHashTags,
    capaMethod: { method: capaMethod },
    locale,
    project: { users: allUsers },
    sprintEfficiencyAndCalculation,
  } = useReduxState();

  const calculateCapacity = (
    capacity: Capacity | undefined,
    sprintId: number
  ) => {
    if (capaMethod === 'PT') {
      return capacity?.[capaMethod] || 0;
    } else {
      const sprint = sprintEfficiencyAndCalculation.sprints.find(
        (sp) => sp.id === sprintId
      );

      if (sprint?.maxStoryPoints && sprint?.maxPersonDays && capacity) {
        return (
          Math.round(
            ((capacity.PT * sprint.maxStoryPoints) / sprint.maxPersonDays) * 2
          ) / 2
        );
      }
      return 0;
    }
  };

  const calculateCapacityHashTags = (
    capacity: Capacity | undefined,
    users: userCapa[],
    sprintId: number
  ) => {
    if (!filteredHashTags.hashTags.length) {
      return calculateCapacity(capacity, sprintId);
    } else {
      const hashCapa = { SP: 0, PT: 0 };

      const hashTagNames = filteredHashTags.hashTags.map((ht) => ht.name);

      const relevantUsers: any[] = allUsers
        .map((user) => {
          if (user.hashTags.some((ht) => hashTagNames.includes(ht.name)))
            return user.id;
        })
        .filter((ids: number | undefined) => ids);

      relevantUsers.forEach((userId: number) => {
        const capa = users.find((user) => user.id === userId);

        if (capa) {
          hashCapa.SP = hashCapa.SP + capa.capacity.SP;
          hashCapa.PT = hashCapa.PT + capa.capacity.PT;
        }
      });
      return calculateCapacity(hashCapa, sprintId);
    }
  };

  const renderStringLong = (capacity: number) => {
    if (capaMethod === 'PT') {
      if (capacity === 1) {
        return locale?.strings?.person_day;
      }
      return locale?.strings?.person_days;
    } else {
      if (capacity === 1) {
        return locale?.strings?.story_point;
      }
      return locale?.strings?.story_points;
    }
  };

  const renderStringExact = () => {
    if (capaMethod === 'PT') {
      return locale?.strings?.person_days;
    } else {
      return locale?.strings?.story_points;
    }
  };

  const renderStringShort = () => {
    return capaMethod;
  };
  return {
    calculateCapacity,
    renderStringLong,
    renderStringShort,
    renderStringExact,
    calculateCapacityHashTags,
  };
};

export default useCapaCalculation;
