import React from 'react';
import { OptionType } from '@atlaskit/select';
import Avatar from '@atlaskit/avatar';

export const filterOptions = (props: ParticipantOptions) => {
  if (props.filterValues && props.filterValues.length) {
    return props.filterValues.join(', ');
  } else {
    return props.label;
  }
};

export interface ParticipantOptions extends OptionType {
  email: string;
  type: string;
  avatar: string | null;
  filterValues: Array<string>;
}

const ParticipantOption = (props: ParticipantOptions) => {
  const { label, value, avatar, email, type } = props;
  return (
    <div className="field-member">
      <Avatar size="xsmall" />
      <div className="field-member__name">{label}</div>
    </div>
  );
};

export default ParticipantOption;
