import axios from 'axios';
import { environment } from 'utils/environment';
import { DeleteHashTagResponse, HashTagResponse } from 'models/ResponseModel';
export interface createHashTagArgs {
  projectId: number;
  projectLink: string;
  hashTag: string;
}

export interface deleteHashTagArgs {
  projectId: number;
  projectLink: string;
  hashTagId: number;
}

export const createHashTag = async (args: createHashTagArgs) => {
  const { data } = await axios.post<HashTagResponse>(
    `${environment.API_BASE_URL}hashtag/${args.projectId}?projectLink=${args.projectLink}`,
    { name: args.hashTag }
  );
  return data;
};

export const deleteHashTag = async (args: deleteHashTagArgs) => {
  const { data } = await axios.delete<DeleteHashTagResponse>(
    `${environment.API_BASE_URL}hashtag/${args.projectId}/${args.hashTagId}?projectLink=${args.projectLink}`
  );
  return data;
};
