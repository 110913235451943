import { useReduxState } from 'hooks/useRedux';
import { isWeekend, getXDaysBeforeDate } from 'utils/date';
import { ValidationState } from '@atlaskit/select/types';
import { USER_TYPE } from 'types/enum';
import { ParticipantOptions } from './Participants';

export interface ContainerProps {
  children: React.ReactNode;
  className?: string;
}

export interface IFormValues {
  name: string;
  abbreviation: string;
  recurring: boolean;
  interval?: {
    label: string;
    value: number;
  };
  eventStartDate: string;
  eventStartTime: string;
  eventEndTime: string;
  participants: Array<ParticipantOptions>;
  link?: string;
}

export const filterCalenderDate = (date: string) => {
  if (
    new Date(date) < new Date(getXDaysBeforeDate(new Date().toISOString(), 1))
  )
    return true;
  else return isWeekend(date);
};

export const isDateEqualAfter = (n1: string, n2: string) => {
  return new Date(n1) >= new Date(n2);
};

export const validate =
  (min = 2) =>
  (value: any) =>
    !value ? 'EMPTY' : value?.length < min ? 'EMPTY' : undefined;

export const getValidationState = (
  error: any,
  valid: boolean
): ValidationState => {
  if (!error && !valid) {
    return 'default';
  }
  if (valid === true) {
    return 'success';
  }
  return 'error';
};

export const useRecurringIntervalOptions = () => {
  const { locale } = useReduxState();
  const OPTIONS = [
    {
      label: locale?.strings?.event_repeat_daily,
      value: 1,
    },
    {
      label: locale?.strings?.event_repeat_weekly,
      value: 7,
    },
    {
      label: locale?.strings?.event_repeat_second_weekly,
      value: 14,
    },
    {
      label: locale?.strings?.event_repeat_fourth_weekly,
      value: 21,
    },
    {
      label: locale?.strings?.event_repeat_once_month,
      value: 28,
    },
  ];
  return OPTIONS;
};

export const useParticipantsOptions = (showAdmin = true) => {
  const {
    project: { users },
  } = useReduxState();
  let defaultSelectedParticipant;
  const OPTIONS = users
    .filter((u) => {
      if (!showAdmin && u.role.type === USER_TYPE.ADMIN) return false;
      return !u?.deletedDate;
    }) // restrict deleted users to praticipate
    .map((user) => {
      const userOption = {
        label: user?.username,
        value: user?.id,
        email: user?.email,
        type: user?.role?.projectRole,
        avatar: null,
        filterValues: [user?.username, user?.email],
      };
      // @TODO after implementing user management change this logic
      // to include the currently logged in user
      // for now insert the admin since admin can only create events
      if (user.role.type === USER_TYPE.ADMIN) {
        defaultSelectedParticipant = userOption;
      }
      return userOption;
    });

  return { OPTIONS, defaultSelectedParticipant };
};
