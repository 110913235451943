import React, { Fragment } from 'react';
import './IntroBlock.scss';
import NewUser from 'pages/project-page/components/new-user/NewUser';
import { useReduxState } from 'hooks/useRedux';

const IntroBlock: React.FC = () => {
  const {
    locale,
    project: { sprints },
  } = useReduxState();

  return (
    <Fragment>
      <div className={sprints?.length === 4 ? 'intro--fourth' : 'intro'}>
        <div className="intro__container">
          <div className="intro__img"></div>
          <h4 className="intro__title">
            {locale?.strings?.welcome_message}{' '}
            <span className="intro__capateam-logo">capa.team</span>
          </h4>
          <p className="intro__desc">
            {locale?.strings?.welcome_add_team_member_intro}
          </p>
          <NewUser fromIntroBlock={true} />
        </div>
      </div>
    </Fragment>
  );
};

export default IntroBlock;
