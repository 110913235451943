const deutsch = {
  onboarding_create_project: 'Projekt Anlegen',
  onboarding_confirm_email: 'E-Mail Bestätigen',
  onboarding_define_iteration: 'Iteration Festlegen',
  onboarding_select_users: 'Benutzer auswählen',
  onboarding_done: 'Fertig',
  new_project: 'Neues Projekt',
  create_new_project: 'Lege ein neues Projekt an',
  project_name: 'Projektname',
  organization_name: 'Organisation/Teamname',
  project_manager: 'Projektverantwortliche:r',
  email_address: 'E-Mailadresse',
  confirm_email: 'E-Mail Bestätigen',
  create_project: 'Projekt Anlegen',
  second_email_sent_text:
    'Du hast soeben eine E-Mail mit einem Admin- sowie einem User-Link für dieses Projekt erhalten.',
  set_iteration: 'Iteration festlegen',
  ready: 'Fertig',
  field_types: 'Die Verschieden Feldtypen',
  full_available: 'Voll Verfügbar',
  half_available: 'Teilweise Verfügbar',
  not_available: 'Nicht Verfügbar',
  no_working_day: 'Kein Arbeitstag',
  morning: 'Vormittag',
  evening: 'Nachmittag',
  iteration: 'Iteration',
  percentage: 'Personentage',
  period: 'Zeitraum',
  create_team_member: 'Teammitglied hinzufügen',
  button_add: 'Hinzufügen',
  edit_team_member: 'Team-Mitglied bearbeiten',
  user_capacity_conttribution: 'Kapazität Contribution',
  username: 'Benutzername',
  role_position: 'Rolle/Position',
  create: 'Anlegen',
  close: 'schließen',
  user_inactive: 'Inaktiv',
  remove_team_member: 'Teammitglied Entfernen',
  select_all: 'Alle Auswählen',
  unselect_all: 'Auswahl Entfernen',
  delete_user: 'Benutzer Löschen',
  delete_user_warning:
    'Gelöschte User können danach nicht wieder hergestellt werden!',
  cancel: 'abbrechen',
  delete: 'löschen',
  save: 'Save',
  need_sprint_info:
    'Wir brauchen noch ein paar Informationen zu Euren Iterationen/Sprints',
  select_users_info: 'Benutzer aus der Liste auswählen',
  validation_not_empty: 'Field cannot be empty',
  validation_min_characters: 'mind. 4 Zeichen',
  validation_too_short: 'zu kurz',
  validation_too_long: 'zu lang',
  validation_no_space: 'darf nicht mit einem Leerzeichen beginnen',
  validation_no_special_characters: 'darf keine Sonderzeichen enthalten',
  validation_email: 'bitte trage eine gültige E-Mailadresse ein',
  name_and_surname_add_later: 'Name & Nachname (kann später ergänzt werden)',
  confirmation_code: 'Bestätigungscode',
  confimation_email_text:
    'Wir haben Dir soeben eine E-Mail mit einem Bestätigungscode geschickt. Bitte prüfe Dein {0}Postfach und gebe den fünfstelligen Besätigungscode in das dafür vorgesehene Feld ein.',
  week: 'Woche',
  weeks: 'Wochen',
  interval_length: 'Sprintdauer',
  interval_length_info:
    'Aus wie vielen Wochen besteht ein Sprint? 1,2,3 oder 4 Wochen?',
  sprint_start_date: 'Sprintstart',
  sprint_start_day:
    'An welchem Tag startet bei Euch der erste Sprint bzw. der nächste Sprint?',
  create_sprint: 'Sprint anlegen',
  select_users: 'Benutzer auswählen',
  validation_start_date: 'bitte wähle ein Startdatum!',
  validation_iteration_length: 'bitte wähle ein Iterationsinverval!',
  next_sprint: 'Nächster Sprint',
  validation_all_characters: 'alle Zeichen erlaubt, mind. :num Zeichen',
  validation_max_20: 'darf nicht mehr als 20 Zeichen enthalten',
  validation_max_users: 'Das Limit von :n Usern wurde bereits erreicht',
  overview_of_capacity: 'Übersicht der Kapazität',
  person_day: 'Personentag',
  person_days: 'Personentage',
  story_point: 'Story Point',
  story_points: 'Story Points',
  email_not_received: `E-Mail nicht erhalten?`,
  check_spam: '{0}Bitte überprüfe Deinen Spam Ordner oder',
  resend_confirmation_code: 'sende den Bestätigungscode erneut',
  resend_confirmation_code_info:
    'Die E-Mail wurde erneut gesendet. Bitte überprüfen Sie Ihre E-Mail',
  project_created_successfully: 'Projekt erfolgreich erstellt',
  go_to_project: 'Zum Projekt',
  back: 'Zurück',
  capa_estimation_method: 'Schätzmethode',
  capa_estimation_method_persondays: 'Personentage (PT)',
  capa_estimation_method_storypoints: 'Story Points (SP)',
  welcome_message: 'Willkommen bei',
  welcome_message_help: `Wir zeigen Dir die wichtigsten Features von capa.team. In unter einer Minute.`,
  welcome_message_show: 'Führe mich herum.',
  welcome_add_team_member_intro:
    'Bevor der Sprint starten kann, brauchen wir ein paar Teammitglieder:innen!',
  welcome_add_team_member: 'Teammitglieder:innen hinzufügen',
  you_have_feedback: 'Du hast Feedback?',
  how_satisfied_with_capa: 'Wie zufrieden bist Du mit',
  specific_topic_for_feedback:
    'Gibt es ein konkretes Thema zu welchem Du uns Feedback geben möchtest?',
  please_provide_detail_for_topic:
    'Bitte nenne uns ein paar Details bzgl. Deines Themas:',
  please_provide_feedback_placeholder: 'Bitte schreibe hier dein Feedback rein',
  button_send: 'Senden',
  feedback_success_info: 'Wir haben Dein Feedback erhalten!',
  ticket_reference: 'Referenztickets',
  add_ticket_reference: 'Referenzticket hinzufügen',
  add_story_points: 'Story Points hinzufügen',
  ticket_reference_notes: `Sollte Dein Team bislang den eigenen Arbeitsaufwand in Stunden oder Tagen geschätzt haben, kannst Du diese Referenztabelle nutzen, um ein besseres Gefühl für Story Points zu bekommen. Bitte begehe dabei nicht den Fehler, Stunden oder Tage eins zu eins in Story Points zu übersetzen. Teams vergeben eine entsprechende Anzahl an Story Points in Relation zur Komplexität der Arbeit, der Menge an Aufgaben, den bestehenden Risiken sowie dem Grad an Unsicherheit. Story Points stellen immer eine relative Schätzung dar.`,
  ticket_reference_note: 'Anmerkung',
  ticket_reference_add_info: `Solltest du User Storys als Referenz für den Wert von Story Points haben, kannst du diese hier eintragen:`,
  button_close: 'Schließen',
  feedback_thank_you: 'Vielen Dank!',
  your_contact_email: 'Deine Kontakt E-Mail',
  please_select_one: 'Bitte auswählen',
  create_event: 'Meeting erstellen',
  edit_event: 'Meeting bearbeiten',
  event_name: 'Name des Meetings',
  event_short_code: 'Kürzel des meetings',
  recurring_event: 'Wiederkehrendes Meeting',
  event_recurring_interval: 'Intervall',
  event_link: 'Link zu Zoom/Teams/...',
  event_date: 'Datum',
  event_start_time: 'Start',
  event_end_time: 'Ende',
  end_time: 'Ende',
  event_interval_info:
    'Wie oft tritt dieses Ereignis auf ? 1, 2, 3 oder 4 Wochen',
  event_participants: 'Teilnehmer:innen',
  participants: 'Teilnehmer',
  hashtags: 'Hashtags',
  validation_min_characters_error:
    'zu kurz, muss mindestens :n Zeichen lang sein',
  validation_max_characters_error:
    'zu lang, darf nicht mehr als :n Zeichen enthalten',
  validation_invalid_date:
    'Ungültiges Datum, Datum muss innerhalb des aktuellen Sprints liegen',
  validation_invalid_time: 'Ungültige Zeit',
  validation_end_time_error: 'Die Endzeit muss größer sein als die Startzeit',
  validation_participant_min_error: 'Bitte wählen Sie mindestens :n Teilnehmer',
  validation_event_duration_range_error:
    'Die Dauer der Sitzung muss zwischen 1 Stunde und 8 Stunden liegen.',
  event_repeat_weekly: 'Jede Woche',
  event_repeat_every_n_weekly: 'Alle :n Wochen',
  validation_event_duration_range_error_for_dailys:
    'Meeting duration must be between 15 minutes and 4 hours',
  validation_url: 'Please enter a valid url',
  event_repeat_daily: 'Jeden Tag',
  event_repeat_second_weekly: 'Jede zweite Woche',
  event_repeat_fourth_weekly: 'Jeden vierte Woche',
  event_repeat_once_month: 'Einmal im Monat',

  sprint_meeting: 'Sprint-Treffen',
  event_occurance_every_day: 'Occurs every day',
  event_occurance_single_info: 'Tritt alle 1 Woche auf',
  event_occurance_multi_info: 'Tritt alle :n Wochen auf',
  non_recurring_event_info: 'Einmaliges Ereignis',
  sprint_reference: 'Sprint reference',
  on_boarding_tutorial_tutorial_back: 'zurück',
  on_boarding_tutorial_tutorial_next: 'weiter',
  on_boarding_tutorial_tutorial_dismiss: 'abbrechen',
  on_boarding_tutorial_tutorial_end: 'Ende',
  on_boarding_tutorial_add_new_team_member:
    'Klicke hier, um ein Teammitglied hinzuzufügen.',
  on_boarding_tutorial_add_sprint_goal:
    'Hier kannst du das aktuelle Sprint Goal hinzufügen.',
  on_boarding_tutorial_reference_tickets:
    'Hier kannst du Links zu vergangenen Tickets/User Stories inkl. der Aufwandsschätzung in Story Points hinterlegen, um den Teammitgliedern Referenzwerte für künftige Schätzungen aufzuzeigen.',
  on_boarding_tutorial_add_feedback:
    'Was können wir verbessern? Klicke hier, um uns ein Feedback zu hinterlassen.',
  on_boarding_tutorial_add_hashtags:
    'Füge den Teammitgliedern Hashtags hinzu, um mit der Filterfunktion nach ihren Rollen/Funktionen suchen zu können.',
  on_boarding_tutorial_overview_hashtags:
    'Übersicht aller Hashtags in diesem Sprint',
  on_boarding_tutorial_add_events:
    'Klicke hier, um dem Kalender ein Meeting hinzuzufügen.',
  on_boarding_tutorial_update_capacity:
    'Hier klicken, um die Kapazität der Teammitglieder anzupassen.',
  add_holiday: 'Feiertag hinzufügen',
  update_holiday: 'Urlaub anpassen',
  add_holiday_date: 'Date',
  add_holiday_name: 'Name',
  add_holiday_members: 'Mitglieder',
  apply_leave: 'Urlaub eintragen',
  update_leave: 'Urlaub anpassen',
  leave_start_date: 'Beginn',
  leave_end_date: 'Ende',
  add_holiday_invalid_date: 'Ungültiges Datum',
  apply_leave_end_dategreater_than_start_error:
    'Das Enddatum muss größer oder gleich dem Startdatum sein',
  apply_leave_add_at_least_one_team_member:
    'Bitte füge mindestens ein Teammitglied hinzu',
  date_format: 'DD/MM/YYYY',
  jira_project: 'Jira-Projekt',
  select_jira_project: 'Wählen Sie Jira-Projekt aus',
  next: 'Nächster',
  users_limit_heading: '*Aktuell können maximal 11 Benutzer ausgewählt werden. In einer zukünftigen Version werden mehr Slots zur Verfügung stehen.',
  select_users_limit: 'Bitte wählen Sie die Benutzer aus.',
  max_users_limit: 'Sie haben eine maximale Anzahl von Benutzern zur Auswahl.',
  actual_story_points_completed:
    'Wie viele Storypoints wurden tatsächlich abgeschlossen?',
  story_points_value: 'Hier Wert eingeben',
  new_events: 'Neues Meeting',
  no_events: 'Aktuell keine Meetings.',
  upcoming_holidays: 'Bevorstehende Feiertage',
  copied_clipboard: 'In die Zwischenablage kopiert',
  hashtag_overview: 'Hashtag Übersicht',
  hashtag_overview_of_sprint: 'Hashtag Übersicht von',
  getting_started: 'Erste Schritte',
  add_hashtags: 'Hashtags erstellen',
  enter_keyword: 'Neuen Hashtag anlegen',
  create_new_user: 'Nutzer anlegen',
  add_sprint_goal: 'Sprintziel hinzufügen',
  sprint_goal: 'Sprintziel',
  add_reference_tickets: 'Referenzticket hinzufügen',
  add_events: 'Meeting erstellen',
  no_sprint_goal: 'Kein Sprintziel',
  integrate_jira: 'Integrieren Sie Jira-Daten',
  events_overview_of: 'Veranstaltungen im Überblick',
  no_capacities: 'Es gibt noch keine Kapazitäten.',
  feedback: 'Feedback',
  all_hashtags: 'Alle Hashtags',
  add_new_hashtag: 'Neuen Hashtag hinzufügen',
  unselect_contribute_total_capacity: '*Deaktivieren Sie diese Option, um nicht zur Gesamtkapazität beizutragen.',
  add_member: 'Mitglied hinzufügen',
  edit: 'Bearbeiten',
  project_link: 'Projektlink',
  create_project_new: 'Neues Projekt erstellen',
  voucher: 'Gutschein',
  message: 'Botschaft',
  request_voucher: 'Wie können wir Dir helfen?',
  request: 'Anfrage',
  validate_voucher: 'Gutschein validieren',
  validate: 'Gutschein',
  easy_capacity_planning: 'Einfache Kapazitätsplanung',
  contact_us_now: 'Kontaktiere uns jetzt',
  price: 'nur 4,99€/Monat',
  do_you_have_any_voucher: 'Haben Sie einen Gutscheincode?',
  enter_voucher: 'Gutschein eingeben',
  invalid_voucher: 'Ungültige Gutschein',
  voucher_email: 'Gutscheinanforderungs-E-Mail wurde erfolgreich gesendet',
  events: 'Meetings',
  event: 'Meeting',
  calendar: 'Kalender',
  delete_holiday: 'Feiertag löschen',
  delete_holiday_message: 'Gelöschte Feiertage können nachträglich nicht wiederhergestellt werden!',
  delete_event: 'Meeting löschen',
  delete_event_message: 'Gelöschte können nachträglich nicht wiederhergestellt werden!',
  save_details: 'Einzelheiten speichern',
  save_particular_meeting: 'Speichern Sie für alle oder für dieses bestimmte Meeting',
  holiday: 'Feiertag',
  leave: 'Urlaub',
  update_capacity: 'Kapazität anpassen',
  copied: 'Kopiert!!!',
  copy_project_link:'Projektlink kopieren' ,
  show_prev_sprints: 'Vorherigen Sprints anzeigen ',
  hide_prev_sprints: 'Vorherige Sprints ausblenden',
  suggestions: 'Anregungen',
  others: 'Others',
};

export default deutsch;