import store from 'store';
import { TAGMANAGER_EVENTS } from './constants';

export const CREATE_PROJECT_FORM_EVENTS = {
  CREATE_PROJECT_FORM: 'initial-form-submitted',
  CONFIRM_EMAIL_FORM: 'email-confirmed',
  RESEND_CONFIRMATION_CODE: 'resend-confirmation-code-clicked',
  CREATE_SPRINT_FORM: 'sprint-created',
  PROJECT_CREATED: 'project-created',
};

interface ProjectCreationProps {
  id: null | number;
  duration: number;
  action: string;
}
const fireProjectCreationEvents = (data: ProjectCreationProps) => {
  window.dataLayer.push({
    event: TAGMANAGER_EVENTS.PROJECT_CREATED,
    project: {
      ...data,
      user: 'admin',
      duration: data.duration / 1000, // seconds
    },
  });
};

export const fireProjectCreationTimeOnProjectDetailsForm = (time: number) => {
  if (global.window && global.window.dataLayer) {
    const {
      global: {
        actions: {
          createProject: {
            projectCreationStarted,
            project: { id },
          },
        },
      },
    } = store.getState();
    const data = {
      id,
      action: CREATE_PROJECT_FORM_EVENTS.CREATE_PROJECT_FORM,
      duration: time - projectCreationStarted,
    };
    fireProjectCreationEvents(data);
  }
};

export const fireProjectCreationTimeOnConfirmEmailForm = (time: number) => {
  if (global.window && global.window.dataLayer) {
    const {
      global: {
        actions: {
          createProject: {
            initialFormSubmitted,
            project: { id },
          },
        },
      },
    } = store.getState();

    const data = {
      id,
      action: CREATE_PROJECT_FORM_EVENTS.CONFIRM_EMAIL_FORM,
      duration: time - initialFormSubmitted,
    };
    fireProjectCreationEvents(data);
  }
};

export const fireProjectCreationTimeOnCreateSprintForm = (time: number) => {
  if (global.window && global.window.dataLayer) {
    const {
      global: {
        actions: {
          createProject: {
            confirmEmailFormSubmited,
            project: { id },
          },
        },
      },
    } = store.getState();

    const data = {
      id,
      action: CREATE_PROJECT_FORM_EVENTS.CREATE_SPRINT_FORM,
      duration: time - confirmEmailFormSubmited,
    };
    fireProjectCreationEvents(data);
  }
};

export const fireProjectCreatedTimeEvent = (time: number) => {
  if (global.window && global.window.dataLayer) {
    const {
      global: {
        actions: {
          createProject: {
            projectCreationStarted,
            project: { id },
          },
        },
      },
    } = store.getState();
    const data = {
      id,
      action: CREATE_PROJECT_FORM_EVENTS.PROJECT_CREATED,
      duration: time - projectCreationStarted,
    };
    fireProjectCreationEvents(data);
  }
};
