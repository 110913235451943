export interface Capacity {
  SP: number;
  PT: number;
}

export enum CAPA_METHODS_TYPE {
  STORY_POINTS = 'SP',
  PERSON_TAGE = 'PT',
}

export type CapacityType =
  | CAPA_METHODS_TYPE.PERSON_TAGE
  | CAPA_METHODS_TYPE.STORY_POINTS;
