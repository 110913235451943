import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CreateProjectModel from 'models/CreateProjectModel';

export interface CreateProjectStoreState {
  project: CreateProjectModel;
  projectCreationStarted: number;
  initialFormSubmitted: number;
  confirmEmailFormSubmited: number;
  hasErrored: boolean;
  isLoading: boolean;
}

export const initialState: CreateProjectStoreState = {
  project: {
    id: null,
    companyName: '',
    projectName: '',
    username: '',
    email: '',
    mailToken: '',
    adminLink: '',
    userLink: '',
  },
  projectCreationStarted: 0,
  initialFormSubmitted: 0,
  confirmEmailFormSubmited: 0,
  hasErrored: false,
  isLoading: false,
};

const createProjectSlice = createSlice({
  name: 'createproject',
  initialState,
  reducers: {
    reset: () => initialState,
    initializeProjectTimer(state) {
      state.projectCreationStarted = Date.now();
    },
    updateDetailsFormCompletedTime(state, action: PayloadAction<number>) {
      state.initialFormSubmitted = action.payload;
    },
    updateConfirmFormCompletedTime(state, action: PayloadAction<number>) {
      state.confirmEmailFormSubmited = action.payload;
    },
    error(state, action: PayloadAction<boolean>) {
      state.hasErrored = action.payload;
    },
    loading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    update(state, action: PayloadAction<CreateProjectModel>) {
      state.project = action.payload;
    },
    create(state, action: PayloadAction<CreateProjectModel>) {
      state.project = action.payload;
    },
    // clearProject(state, action: PayloadAction) {
    //   state = { ...initialState };
    // },
  },
});

export const {
  update,
  error,
  loading,
  create,
  initializeProjectTimer,
  reset,
  updateDetailsFormCompletedTime,
  updateConfirmFormCompletedTime,
} = createProjectSlice.actions;
export default createProjectSlice.reducer;
