import moment from 'moment';

export const formatDate = (date: string, lengthInWeeks: number): string => {
  const startDate: moment.Moment = moment(date);
  let endDate: moment.Moment = startDate.clone();
  endDate = endDate.add(lengthInWeeks * 7 - 1, 'days');

  let format = 'DD. MMMM YYYY';
  if (startDate.format('YY') === endDate.format('YY')) {
    if (startDate.format('MM') === endDate.format('MM')) {
      format = 'DD. ';
    } else {
      format = 'DD. MMMM';
    }
  } else {
    format = 'DD. MMMM YYYY';
  }

  const formattedDate: string =
    startDate.format(format) + ' - ' + endDate.format('DD. MMMM YYYY');
  return formattedDate;
};

export const isWeekend = (date: string) => {
  const dayOfWeek = moment(date).weekday();
  return dayOfWeek === 0 || dayOfWeek === 6;
};

export const getSprintEndDate = (
  date: string,
  lengthInWeeks: number
): string => {
  const startDate: moment.Moment = moment(date);
  let endDate: moment.Moment = startDate.clone();
  endDate = endDate.add(lengthInWeeks * 7 - 1, 'days');
  return endDate.toISOString();
};

export const getXDaysBeforeDate = (date: string, days: number) => {
  const startDate: moment.Moment = moment(date);
  let beforeDate: moment.Moment = startDate.clone();
  beforeDate = beforeDate.subtract(days, 'days');
  return beforeDate.toISOString();
};

export const getGermanFormatDate = (dat: Date) => {
  const date = new Date(dat);
  return (
    date?.getDate() + '.' + (date?.getMonth() + 1) + '.' + date?.getFullYear()
  );
};

export const getStartEndEventTime = (dat: Date, duration: number) => {
  // duration is in minutes
  const date = moment(dat);
  const startHour = date?.get('hours');
  const startMin = date?.get('minutes');

  const newD = moment(dat).add(duration, 'minutes');
  const endHour = newD?.get('hours');
  const endMinutes = newD?.get('minutes');

  return {
    startTime: `${startHour}:${startMin < 10 ? '0' + startMin : startMin}`,
    endTime: `${endHour}:${endMinutes < 10 ? '0' + endMinutes : endMinutes}`,
  };
};
