import React, { Fragment, useState } from 'react';
import { TimePicker, DatePicker } from '@atlaskit/datetime-picker';
import Select from '@atlaskit/select';
import Button from '@atlaskit/button';
import { ModalBackDrop, Modal, Header, Body, Footer } from 'lib/Modal';
import Form, { ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { CheckboxSelect } from '@atlaskit/select';
import { LoadingButton } from '@atlaskit/button';
import { useReduxState } from 'hooks/useRedux';
import {
  getSprintEndDate,
  getStartEndEventTime,
  getXDaysBeforeDate,
} from 'utils/date';
import {
  useRecurringIntervalOptions,
  filterCalenderDate,
  getValidationState,
  validate,
  IFormValues,
  useParticipantsOptions,
} from './EventUtils';
import { deleteEvent, editEvent } from 'store/slice/project';
import { useDispatch } from 'react-redux';
import '../ModalStyle.scss';
import { Event, SubEvent } from 'models/ProjectModel';
import Alert, { Actions } from 'lib/Alert';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import ParticipantOption, { filterOptions } from './Participants';

interface Props {
  close: () => void;
  eventDetails: Event;
  sprintStartDate?: string;
  intervalTime?: number;
  subEvent: SubEvent;
}

const EventModalEdit: React.FC<Props> = (props) => {
  const { close, sprintStartDate, eventDetails, intervalTime, subEvent } =
    props;

  const { locale } = useReduxState();

  const [isLoading, setIsLoading] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState<boolean>(false);
  const changeDeleteWarning = () => setDeleteWarning(!deleteWarning);

  const onDeleteSubevent = async () => {
    await dispatch(deleteEvent(eventDetails.id));
  };

  const actions: Actions[] = [
    {
      appearance: 'danger',
      text: locale?.strings?.delete,
      onClick: onDeleteSubevent,
    },
    {
      text: locale?.strings?.cancel,
      onClick: changeDeleteWarning,
    },
  ];

  const dispatch = useDispatch();

  const onSubmit = async (values: IFormValues) => {
    try {
      const startTime = values?.eventStartTime.split(':').map((e) => Number(e));
      const endTime = values?.eventEndTime.split(':').map((e) => Number(e));
      const date = new Date(
        new Date(values?.eventStartDate).setHours(startTime[0])
      ).setMinutes(startTime[1]);
      const duration =
        (endTime[0] - startTime[0]) * 60 + (endTime[1] - startTime[1]); // minutes
      const eventObject: any = {
        eventId: eventDetails?.id,
        name: values?.name,
        abbreviation: values?.abbreviation,
        recurring: eventDetails?.recurring,
        interval: eventDetails?.recurring ? values?.interval?.value : undefined,
        duration,
        users: values?.participants.map((user) => {
          return user.value;
        }),
        time: new Date(date).toISOString(),
      };

      setIsLoading(true);
      await dispatch(editEvent(eventObject));
      setIsLoading(false);
    } catch (err) {
      console.log({ err });
    }
  };

  const recurringIntervalOptions = useRecurringIntervalOptions();

  const { startTime, endTime } = getStartEndEventTime(
    subEvent.time,
    subEvent.duration
  );

  const { OPTIONS: userIntervalOptions, defaultSelectedParticipant } =
    useParticipantsOptions();

  return (
    <>
      {deleteWarning && (
        <Alert
          actions={actions}
          onClose={changeDeleteWarning}
          heading={'Delete event'}
          appearance="danger">
          Deleted events cannot be restored afterwards!
        </Alert>
      )}

      <Modal onClose={close}>
        <ModalBackDrop>
          <Header>
            <h3>{locale?.strings?.edit_event}</h3>
          </Header>
          <Form onSubmit={onSubmit}>
            {({ formProps }) => (
              <form {...formProps}>
                <Body>
                  <div className="form-fields">
                    <Field
                      isRequired
                      label={locale?.strings?.event_name}
                      name="name"
                      defaultValue={eventDetails?.name}
                      validate={(value) =>
                        value && value.length < 4
                          ? locale?.strings?.validation_min_characters_error.replace(
                              ':n',
                              '4'
                            )
                          : value && /^\S.*/.test(value) !== true
                          ? locale?.strings?.validation_no_space
                          : value && value.length > 20
                          ? locale?.strings?.validation_max_characters_error.replace(
                              ':n',
                              '20'
                            )
                          : undefined
                      }>
                      {({ fieldProps, error }) => (
                        <Fragment>
                          <Textfield {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </Fragment>
                      )}
                    </Field>

                    <Field
                      isRequired
                      label={locale?.strings?.event_short_code}
                      name="abbreviation"
                      defaultValue={eventDetails?.abbreviation}
                      validate={(value) =>
                        value && value.length < 2
                          ? locale?.strings?.validation_min_characters_error.replace(
                              ':n',
                              '2'
                            )
                          : value && /^\S.*/.test(value) !== true
                          ? locale?.strings?.validation_no_space
                          : value && value.length > 5
                          ? locale?.strings?.validation_max_characters_error.replace(
                              ':n',
                              '5'
                            )
                          : undefined
                      }>
                      {({ fieldProps, error }) => (
                        <Fragment>
                          <Textfield {...fieldProps} />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </Fragment>
                      )}
                    </Field>

                    {eventDetails.recurring && (
                      <Field
                        isRequired
                        label={locale?.strings?.event_recurring_interval}
                        name="interval"
                        defaultValue={recurringIntervalOptions.find(
                          (interval) =>
                            interval.value === eventDetails?.interval
                        )}
                        validate={(value, state: any) => {
                          if (state?.recurring) {
                            return validate()(value);
                          }
                          return undefined;
                        }}>
                        {({ fieldProps, error, meta: { valid } }: any) => {
                          return (
                            <Fragment>
                              <Select
                                {...fieldProps}
                                placeholder={
                                  locale?.strings?.event_recurring_interval
                                }
                                options={recurringIntervalOptions}
                                validationState={getValidationState(
                                  error,
                                  valid
                                )}
                              />
                              {error === 'EMPTY' && (
                                <ErrorMessage>
                                  {locale?.strings?.validation_iteration_length}
                                </ErrorMessage>
                              )}
                            </Fragment>
                          );
                        }}
                      </Field>
                    )}

                    <Field
                      isRequired
                      label={locale?.strings?.participants}
                      name="participants"
                      validate={validate()}>
                      {({ fieldProps, error, meta: { valid } }: any) => (
                        <Fragment>
                          <CheckboxSelect
                            {...fieldProps}
                            isMulti
                            defaultValue={defaultSelectedParticipant}
                            getOptionValue={filterOptions}
                            formatOptionLabel={ParticipantOption}
                            options={userIntervalOptions}
                            placeholder={locale?.strings?.event_participants}
                            validationState={getValidationState(error, valid)}
                            shouldFitContainer
                          />
                          {error === 'EMPTY' && (
                            <ErrorMessage>
                              {locale?.strings?.validation_participant_min_error.replace(
                                ':n',
                                '2'
                              )}
                            </ErrorMessage>
                          )}
                        </Fragment>
                      )}
                    </Field>

                    <div className="form-row">
                      <div className="form-row__col">
                        <Field
                          isRequired
                          label={locale?.strings?.event_date}
                          name="eventStartDate"
                          defaultValue={new Date(subEvent.time).toISOString()}
                          validate={(value) =>
                            !value
                              ? locale?.strings?.validation_invalid_date
                              : filterCalenderDate(value)
                              ? locale?.strings?.validation_invalid_date
                              : undefined
                          }>
                          {({ fieldProps, error }) => (
                            <Fragment>
                              {sprintStartDate && intervalTime ? (
                                <DatePicker
                                  {...fieldProps}
                                  placeholder={
                                    locale?.strings?.date_placeholder
                                  }
                                  dateFormat={locale?.strings?.date_format}
                                  minDate={getXDaysBeforeDate(
                                    sprintStartDate,
                                    1
                                  )}
                                  maxDate={getSprintEndDate(
                                    sprintStartDate,
                                    intervalTime
                                  )}
                                  disabledDateFilter={filterCalenderDate}
                                  weekStartDay={1}
                                />
                              ) : (
                                <DatePicker
                                  {...fieldProps}
                                  placeholder={new Date().toLocaleDateString()}
                                  disabledDateFilter={filterCalenderDate}
                                  weekStartDay={1}
                                />
                              )}
                              {error && <ErrorMessage>{error}</ErrorMessage>}
                            </Fragment>
                          )}
                        </Field>
                      </div>
                      <span>&nbsp;&nbsp;&nbsp;</span>
                      <div className="form-row__col">
                        <Field
                          isRequired={true}
                          label={locale?.strings?.start_time}
                          defaultValue={startTime}
                          name="eventStartTime"
                          validate={(value) =>
                            !value
                              ? locale?.strings?.validation_invalid_time
                              : undefined
                          }>
                          {({ fieldProps, error }) => (
                            <Fragment>
                              <TimePicker
                                placeholder={'9:30'}
                                timeFormat="HH:mm"
                                timeIsEditable
                                {...fieldProps}
                              />
                              {error && <ErrorMessage>{error}</ErrorMessage>}
                            </Fragment>
                          )}
                        </Field>
                      </div>

                      <span>&nbsp;&nbsp;&nbsp;</span>
                      <div className="form-row__col">
                        <Field
                          isRequired={true}
                          label={locale?.strings?.end_time}
                          defaultValue={endTime}
                          name="eventEndTime"
                          validate={(value, state: any) => {
                            const startArray = (
                              state?.eventStartTime
                                ? state?.eventStartTime
                                : '0:0'
                            )
                              .split(':')
                              .map((t: string) => Number(t || 0));
                            const endArray = (value ? value : '0:0')
                              .split(':')
                              .map((t: string) => Number(t || 0));
                            const isTimeValid =
                              endArray[0] > startArray[0] ||
                              (endArray[0] === startArray[0] &&
                                endArray[1] > startArray[1]);
                            const duration =
                              (endArray[0] - startArray[0]) * 60 +
                              (endArray[1] - startArray[1]); // minutes

                            const isDurationValid =
                              state.interval?.value === 1
                                ? duration >= 15 && duration <= 240 // 15 mins- 4hour
                                : duration >= 60 && duration <= 480; // 1hour - 8hour

                            return !value
                              ? locale?.strings?.validation_invalid_time
                              : !isTimeValid
                              ? locale?.strings?.validation_end_time_error
                              : !isDurationValid && state.interval?.value === 1
                              ? locale?.strings
                                  ?.validation_event_duration_range_error_for_dailys
                              : !isDurationValid
                              ? locale?.strings
                                  ?.validation_event_duration_range_error
                              : undefined;
                          }}>
                          {({ fieldProps, error }) => (
                            <Fragment>
                              <TimePicker
                                placeholder={'11:30'}
                                timeFormat="HH:mm"
                                timeIsEditable
                                {...fieldProps}
                              />
                              {error && <ErrorMessage>{error}</ErrorMessage>}
                            </Fragment>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </Body>
                <Footer>
                  <div className="form-footer">
                    <Button
                      appearance="subtle"
                      iconBefore={
                        <TrashIcon label="Delete icon" size="small" />
                      }
                      onClick={changeDeleteWarning}>
                      {locale?.strings?.delete}
                    </Button>
                  </div>
                  <span></span>

                  <div>
                    <Button className="secondary-btn" onClick={close}>
                      {locale?.strings?.cancel}
                    </Button>
                    <LoadingButton
                      appearance="primary"
                      type="submit"
                      isLoading={isLoading}>
                      Save
                    </LoadingButton>
                  </div>
                </Footer>
              </form>
            )}
          </Form>
        </ModalBackDrop>
      </Modal>
    </>
  );
};

export default EventModalEdit;
