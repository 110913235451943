import React from 'react';
import './CapaOverview.scss';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as CapacityService from 'services/capacity.service';
import {
  add as sprintCapacityAdd,
  clear as sprintCapacityClear,
} from 'store/slice/sprintCapacity';
import { sprintCapacityInterface } from 'store/slice/sprintCapacity/sprintCapacity.slice';
import useCapaCalculation from 'hooks/useCapaCalculation';
import { useReduxState } from 'hooks/useRedux';

const CapaOverview: React.FC = () => {
  const {
    locale,
    sprintCapacity: sprintCapacityState,
    project: { sprints, project: projectInfo },
  } = useReduxState();

  const dispatch = useDispatch();
  const useCapaCalculationHook = useCapaCalculation();

  const renderTotalText = useCapaCalculationHook?.renderStringExact();

  const storeCapacity = async () => {
    const newStoreCapacity = await sprints.map(async (item) => {
      const newCapacity = await CapacityService.getCapacityBySprint({
        sprintId: item.id,
        projectLink: projectInfo.projectLink.link,
      });

      const addData: sprintCapacityInterface = {
        sprintId: item.id,
        capacity: newCapacity.capacity,
        hashTags: newCapacity.hashTags,
        users: newCapacity.users,
        userSlots: newCapacity.userSlots,
      };

      dispatch(sprintCapacityAdd(addData));

      return addData;
    });
  };

  useEffect(() => {
    dispatch(sprintCapacityClear());
    storeCapacity();
  }, []);

  const renderCapacityTable = () => {
    const newArr = [...sprintCapacityState?.sprints];

    newArr?.sort((a, b) => a?.sprintId - b?.sprintId);

    return newArr?.map?.((item: sprintCapacityInterface, index: number) => {
      const capacity = useCapaCalculationHook?.calculateCapacity(
        item?.capacity,
        item.sprintId
      );

      return (
        <div className="capa-overview__item" key={item?.sprintId}>
          <span>{index + 1}</span>
          <span>{capacity}</span>
        </div>
      );
    });
  };

  return (
    <div className="capa-overview">
      <div className="capa-overview__title">
        <h4>{locale?.strings?.overview_of_capacity}</h4>
      </div>
      <div className="capa-overview-table">
        <div className="capa-overview--head">
          <span>Sprint</span>
          <span>{renderTotalText}</span>
        </div>
        <div className="capa-overview__block">{renderCapacityTable()}</div>
      </div>
    </div>
  );
};

export default CapaOverview;
