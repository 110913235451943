import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { firePageViewEvent } from 'tracking/pageview';

const ROUTES_ARRAY = [
  '/create-project',
  '/project/:projectId/:projectLink/events',
  '/project/:projectId/:projectLink/calendar',
  '/project/:projectId/:projectLink',
];

const PAGE_TITLE: Record<string, string> = {
  '/create-project': 'capa.team',
  '/project/:projectId/:projectLink/events': 'capa.team - events',
  '/project/:projectId/:projectLink/calendar': 'capa.team - calendar',
  '/project/:projectId/:projectLink': 'capa.team',
};

const Tracker = (props: any) => {
  const pathmatch = useRouteMatch(ROUTES_ARRAY);

  const path = pathmatch?.path ?? '';

  React.useEffect(() => {
    const pageTitle = PAGE_TITLE[path] || 'capa.team';
    document.title = pageTitle;
    firePageViewEvent({ url: path, title: pageTitle });
  }, [path]);

  return props.children;
};

export default Tracker;
