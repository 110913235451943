import React from 'react';
import styled from 'styled-components';
import Button, { LoadingButton } from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import EmojiIcon from '@atlaskit/icon/glyph/emoji';
import CopyIcon from '@atlaskit/icon/glyph/copy';
import { Link } from 'react-router-dom';
import { useReduxState } from 'hooks/useRedux';

const CompletedForm: React.FC = () => {
  const {
    locale,
    actions: {
      createProject: { project: projectState },
    },
  } = useReduxState();

  const [copied, setCopied] = React.useState<boolean>(false);
  const projectPath = `/project/${projectState?.id}/${projectState?.adminLink}`;
  const userLink: string =
    window.location.origin +
    `/project/${projectState?.id}/${projectState?.userLink}`;

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(userLink);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <Box>
      <EmojiIcon label="SmileIcon" size="xlarge" primaryColor="#FF991F" />
      <h2>{locale?.strings?.project_created_successfully}</h2>
      <div className="flex items-center content-center">
        <Link className="mb-0 secondary-btn" to={projectPath}>
          <LoadingButton appearance="primary" type="button">
            {locale?.strings?.go_to_project}
          </LoadingButton>
        </Link>
        <Tooltip content={locale?.strings?.copy_project_link}>
          <Button onClick={copyToClipboard} appearance="default" type="button">
            <CopyIcon label="copy" size="small" />
            {copied && (
              <p style={{ position: 'absolute', top: 0, right: '-66px' }}>
                {locale?.strings?.copied}
              </p>
            )}
          </Button>
        </Tooltip>
      </div>
      <div>
        <p
          style={{
            padding: '20px',
            color: '#888',
            lineHeight: '22px',
            fontSize: '12px',
          }}>
          {locale?.strings?.second_email_sent_text}
        </p>
      </div>
    </Box>
  );
};

const Box = styled.div`
  text-align: center;
  padding: 3rem 0 8rem;

  h2 {
    font-size: 18px;
    font-weight: 500;
    margin: 20px 0;
  }

  a {
    display: block;
    margin-bottom: 30px;
    line-height: 24px;
    color: #0052cc;
  }
`;

export default CompletedForm;
