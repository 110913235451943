import React, { Fragment, useEffect, useState } from 'react';
import Button, { LoadingButton, ButtonGroup } from '@atlaskit/button';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import { DatePicker } from '@atlaskit/datetime-picker';
import Form, {
  ErrorMessage,
  Field,
  FormFooter,
  HelperMessage,
} from '@atlaskit/form';
import Select from '@atlaskit/select';
import { ValidationState } from '@atlaskit/select/types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createSprint, SprintData } from 'store/slice/createSprint';
import { prev } from 'store/slice/createProjectOnboarding/createProjectOnboarding.slice';
import { useReduxState } from 'hooks/useRedux';
import {
  fireProjectCreatedTimeEvent,
  fireProjectCreationTimeOnCreateSprintForm,
} from 'tracking/createProject';
import { getJiraUser } from 'store/slice/JiraData';

const validate = (value: any) => (!value ? 'EMPTY' : undefined);

const getValidationState = (error: any, valid: boolean): ValidationState => {
  if (!error && !valid) {
    return 'default';
  }
  if (valid === true) {
    return 'success';
  }
  return 'error';
};

const today: Date = new Date();
const min = today.setDate(today.getDate() - 84);

const SprintForm: React.FC = () => {
  const {
    locale,
    actions: {
      createSprint: createSprintState,
      createProject: createProjectState,
      jiraDataUserLimit: { JiraProjectSprint, isJiraProject },
    },
  } = useReduxState();

  React.useEffect(() => {
    // when the confirm email form unmounts
    return function timerTrack() {
      // send time spend on confirm email form to the google analytics
      // send project creation time to the google analytics
      const newTime = Date.now();
      fireProjectCreationTimeOnCreateSprintForm(newTime);
      fireProjectCreatedTimeEvent(newTime);
    };
  }, []);

  const [sprintStartDate, setSprintStartDate] = useState<string>('');
  const [sprintIntervalTime, setSprintIntervalTime] = useState<number>(0);

  const intervalTimes = [
    { label: '1 ' + locale?.strings?.week, value: 1 },
    { label: '2 ' + locale?.strings?.weeks, value: 2 },
    { label: '3 ' + locale?.strings?.weeks, value: 3 },
    { label: '4 ' + locale?.strings?.weeks, value: 4 },
  ];

  let defaultSprintValueFromJira: { label: string; value: number };
  if (sprintIntervalTime > 0) {
    defaultSprintValueFromJira = {
      label: `${sprintIntervalTime} ` + locale?.strings?.weeks,
      value: sprintIntervalTime,
    };
  }

  const formDateLimit = () => {
    const date = new Date(min).toISOString().slice(0, 10);
    return date;
  };

  const dispatch = useDispatch();

  const handleSubmit = (data: SprintData): void => {
    const args: SprintData = {
      intervalTime: data.intervalTime,
      sprintStartDate: new Date(data.sprintStartDate).toISOString(),
      projectId: createProjectState?.project?.id,
      mailToken: createProjectState?.project?.mailToken,
    };
    dispatch(createSprint(args));
    isJiraProject && dispatch(getJiraUser());
  };

  const goBack = () => {
    dispatch(prev(true));
  };

  /* TODO: Test the below useEffect */
  useEffect(() => {
    if (JiraProjectSprint) {
      const jiraDate = new Date(JiraProjectSprint.sprintStart);
      const jiraMinDate = jiraDate.setDate(jiraDate.getDate());

      if (jiraMinDate < min) {
        setSprintStartDate(new Date().toISOString().slice(0, 10));
      } else {
        setSprintStartDate(JiraProjectSprint.sprintStart);
      }
      const sprintLength = JiraProjectSprint.sprintDuration / 7;
      setSprintIntervalTime(sprintLength);
    }
  }, [JiraProjectSprint]);

  return (
    <Form onSubmit={handleSubmit}>
      {({ formProps }) => (
        <form {...formProps}>
          <Field
            label={locale?.strings?.interval_length}
            name="intervalTime"
            isRequired
            validate={validate}
            defaultValue={isJiraProject && defaultSprintValueFromJira}>
            {({ fieldProps, error, meta: { valid } }: any) => (
              <Fragment>
                <Select
                  {...fieldProps}
                  options={intervalTimes}
                  placeholder={locale?.strings?.interval_length}
                  validationState={getValidationState(error, valid)}
                />
                <HelperMessage>
                  {locale?.strings?.interval_length_info}
                </HelperMessage>
                {error === 'EMPTY' && (
                  <ErrorMessage>
                    {locale?.strings?.validation_iteration_length}
                  </ErrorMessage>
                )}
              </Fragment>
            )}
          </Field>
          <Field
            label={locale?.strings?.sprint_start_date}
            name="sprintStartDate"
            isRequired
            validate={validate}
            defaultValue={sprintStartDate ? sprintStartDate : ''}>
            {({ fieldProps, error }: any) => (
              <Fragment>
                <DatePicker
                  id="datepicker-1"
                  {...fieldProps}
                  placeholder={locale?.strings?.date_placeholder}
                  dateFormat={locale?.strings?.date_format}
                  value={sprintStartDate ? sprintStartDate : ''}
                  onChange={(sprintStartDate) =>
                    setSprintStartDate(sprintStartDate)
                  }
                  weekStartDay={1}
                  minDate={formDateLimit()}
                />
                <HelperMessage>
                  {locale?.strings?.sprint_start_day}
                </HelperMessage>
                {error === 'EMPTY' && (
                  <ErrorMessage>
                    {locale?.strings?.validation_start_date}
                  </ErrorMessage>
                )}
              </Fragment>
            )}
          </Field>
          <FormFooter>
            <ButtonGroup>
              <Button
                iconBefore={<ChevronLeftLargeIcon label="ChevronLeft icon" />}
                onClick={goBack}>
                {locale?.strings?.back}
              </Button>
              <LoadingButton
                appearance="primary"
                type="submit"
                isLoading={createSprintState?.isLoading}
                isDisabled={createSprintState?.isLoading}>
                {locale?.strings?.create_sprint}
              </LoadingButton>
            </ButtonGroup>
          </FormFooter>
        </form>
      )}
    </Form>
  );
};

export default withRouter(SprintForm);
