import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import combinedReducer, { initialStoreState } from './slice';

const store = createStore(
  combinedReducer,
  initialStoreState,
  composeWithDevTools(applyMiddleware(thunk))
);

export type initialStoreInterface = ReturnType<typeof store.getState>;
export type dispatch = typeof store.dispatch;
export default store;
