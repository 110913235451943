import React from 'react';
import Button from '@atlaskit/button';
import { emojiFive } from 'assets/icons/icons';
import Icon from '@atlaskit/icon';
import { ModalBackDrop, Modal, Header, Footer, Body } from 'lib/Modal';
import { useReduxState } from 'hooks/useRedux';
import { EventBus, GET_STARTED_TUTORIAL } from 'Event';
import './getStarted.scss';

interface IGetStartedProps {
  close: () => void;
}
const GetStarted: React.FC<IGetStartedProps> = ({ close }) => {
  const { locale } = useReduxState();
  const startIntroductionTutorial = () => {
    EventBus.emit(GET_STARTED_TUTORIAL.FIRSTOPEN, {});
    close();
  };

  return (
    <Modal onClose={close} size="medium">
      <ModalBackDrop>
        <Header>&nbsp;</Header>
        <Body>
          <div className="startpage">
            <Icon glyph={emojiFive} label="Custom icon" size="large" />
            <h2 className="startpage__content-title">
              {locale?.strings?.welcome_message} <span className="red">Capa.team</span>
            </h2>
            <p className="startpage__content-desc">
              {locale?.strings?.welcome_message_help}
            </p>
            <div className="startpage__content-btn">
              <Button appearance="primary" onClick={startIntroductionTutorial}>
                {locale?.strings?.welcome_message_show}
              </Button>
            </div>
          </div>
        </Body>
        <Footer></Footer>
      </ModalBackDrop>
    </Modal>
  );
};

export default GetStarted;
