/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import InfoIcon from '@atlaskit/icon/glyph/info';
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import { WithOnBoardingTutorialTarget } from 'components/onBoarding/tutorial';
import { onBoardingTutorial } from 'types/onBoardingTutorial';
import { useReduxState } from 'hooks/useRedux';

interface ISprintGoalProps {
  enableEdit: () => void;
}
export const SprintGoal = ({ enableEdit }: ISprintGoalProps) => {
  const { locale } = useReduxState();
  return (
    <div className="name">
      <p>
      {locale?.strings?.sprint_goal}:
        <Button className="pos-inherit" spacing="none" onClick={enableEdit}>
          <EditFilledIcon
            label={'EditFilledIcon'}
            size={'small'}></EditFilledIcon>
        </Button>
      </p>
    </div>
  );
};

export const SprintGoalWithOnBoardingTutorial = WithOnBoardingTutorialTarget(
  SprintGoal,
  onBoardingTutorial.SPRINTGOAL
);

interface ISprintTicketReferenceProps {
  openReference: () => void;
}
export const SprintTicketReference = ({
  openReference,
}: ISprintTicketReferenceProps) => {
  const { locale } = useReduxState();

  return (
    <div className="sprint-ref">
      <div className="sprint-ref--btn" onClick={openReference}>
        <InfoIcon label="" size="small" primaryColor="#FFAB00"></InfoIcon>
        <p className="sprint-ref__title">{locale?.strings?.ticket_reference}</p>
      </div>
    </div>
  );
};

export const SprintTicketReferenceWithOnBoardingTutorial =
  WithOnBoardingTutorialTarget(
    SprintTicketReference,
    onBoardingTutorial.TICKETREFERENCE
  );

interface ISprintEventsProps {
  openSprintsOverviewOpen: () => void;
}
export const SprintEvents = ({
  openSprintsOverviewOpen,
}: ISprintEventsProps) => {
  return (
    <div className="sprint-events">
      <Button
        className="sprint-events__btn"
        spacing="compact"
        isSelected
        onClick={openSprintsOverviewOpen}>
        Sprint Events
      </Button>
    </div>
    // <div className="sprint-events">
    //   <div className="sprint-events__heading">
    //     <h3 className="sprint-events__heading-title">Events</h3>
    //     <Tooltip content="Create Event">
    //       <Button appearance="link" spacing="none" onClick={openEventModal}>
    //         <AddCircleIcon label="Add Events" size="small"></AddCircleIcon>
    //       </Button>
    //     </Tooltip>
    //   </div>
    //   <EventList
    //     sprintStart={new Date(sprintStartDate)}
    //     sprintLength={intervalTime}
    //   />
    // </div>
  );
};

export const SprintEventsWithOnBoardingTutorial = WithOnBoardingTutorialTarget(
  SprintEvents,
  onBoardingTutorial.CREATEEVENT
);

interface ISprintHashTagsOverviewProps {
  openHashTagOverviewOpen: () => void;
}
export const SprintHashTagsOverview = ({
  openHashTagOverviewOpen,
}: ISprintHashTagsOverviewProps) => {
  const { locale } = useReduxState();
  return (
    <div className="sprint-hashtag">
      <Button spacing="compact" onClick={openHashTagOverviewOpen}>
        {`#${locale?.strings?.hashtag_overview}`}
      </Button>
    </div>
  );
};

export const SprintHashTagsOverviewWithOnBoardingTutorial =
  WithOnBoardingTutorialTarget(
    SprintHashTagsOverview,
    onBoardingTutorial.HASHTAGOVERVIEW
  );
