import { environment } from 'utils/environment';
import axios from 'axios';
import store from 'store';
import { error as globalError } from 'store/slice/globalError/globaleError.slice';
import { EmailVerificationResponse } from 'models/ResponseModel';

export type eMailData = {
  mailToken: string;
  mailCode: number;
};

export const eMailConfirmation = async (data: eMailData) => {
  const response = await axios.get<EmailVerificationResponse>(
    `${environment.API_BASE_URL}project/token/${data.mailToken}/${data.mailCode}`
  );
  return response.data;
};

export const resendConfirmation = async (projectId: number) => {
  try {
    await axios.get<{ success: boolean }>(
      `${environment.API_BASE_URL}email/resendConfirmation/${projectId}`
    );
  } catch (error: any) {
    store.dispatch(
      globalError(error?.response?.data?.error || 'Server antwortet nicht!')
    );
    throw error;
  }
};
