import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  JiraAuthenticationCode,
  JiraProject,
  ProjectDetailsFromJira,
  JiraProjectSprint,
  JiraAuthenticationUrl,
  JiraUsersList,
} from 'models/jiraAuthenticationModel';
import { jiraCode } from 'services/jiradata.service';

export interface JiraAuthenticationCodeStoreState {
  jiraAuthCode: JiraAuthenticationCode;
  hasErrored: boolean;
  isLoading: boolean;
}

export interface JiraExtractedProject {
  jiraProject: JiraProject;
  ProjectDetailsFromJira: ProjectDetailsFromJira;
  JiraProjectSprint: JiraProjectSprint;
  jiraAuthenticationUrl: JiraAuthenticationUrl;
  jiraCode: jiraCode;
  hasErrored: boolean;
  isLoading: boolean;
  isJiraProject: boolean;
  JiraUsersList: JiraUsersList;
}

export const initialState: JiraExtractedProject = {
  jiraProject: {
    values: [{ id: '', name: '', key: '' }],
  },
  hasErrored: false,
  isLoading: false,
  isJiraProject: false,
  ProjectDetailsFromJira: {
    projectName: '',
    teamName: '',
    projectManager: '',
    emailAddress: '',
  },
  jiraCode: {
    code: '',
    state: '',
  },
  JiraProjectSprint: {
    sprintStart: '',
    sprintDuration: 0,
  },
  jiraAuthenticationUrl: {
    redirect_uri: '',
  },
  JiraUsersList: {
    users: [],
  },
};

const jiraDataSlice = createSlice({
  name: 'jiraData',
  initialState,
  reducers: {
    reset: () => initialState,
    error(state, action: PayloadAction<boolean>) {
      state.hasErrored = action.payload;
    },
    loading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    jiraProject(state, action: PayloadAction<boolean>) {
      state.isJiraProject = action.payload;
    },
    create(state, action: PayloadAction<JiraProject>) {
      state.jiraProject = action.payload;
    },
    updateJiraProject(state, action: PayloadAction<ProjectDetailsFromJira>) {
      state.ProjectDetailsFromJira = action.payload;
    },

    // here all the list of users from jira is saved
    allJiraUsers(state, action: PayloadAction<JiraUsersList>) {
      state.JiraUsersList = action.payload;
    },

    createJiraCode(state, action: PayloadAction<jiraCode>) {
      state.jiraCode = action.payload;
    },
    createJiraSprintData(state, action: PayloadAction<JiraProjectSprint>) {
      state.JiraProjectSprint = action.payload;
    },
    createJiraAuthenticationRedirectUrl(
      state,
      action: PayloadAction<JiraAuthenticationUrl>
    ) {
      state.jiraAuthenticationUrl = action.payload;
    },
  },
});

export const {
  create,
  error,
  loading,
  reset,
  updateJiraProject,
  createJiraCode,
  createJiraSprintData,
  createJiraAuthenticationRedirectUrl,
  jiraProject,
  allJiraUsers,
} = jiraDataSlice.actions;
export default jiraDataSlice.reducer;
