import { initialStoreInterface, dispatch } from 'store';
import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';

// aliasing and exporting dispatch and selector by adding our store type
export const useAppDispatch = () => useDispatch<dispatch>();
export const useAppSelector: TypedUseSelectorHook<initialStoreInterface> =
  useSelector;

// hooks that returns the redux state values
export const useReduxState = () => {
  return useAppSelector((state) => state?.global);
};
