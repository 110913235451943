import { User } from 'models/ProjectModel';
import moment from 'moment';

export interface SubEvent {
  id: number;
  time: Date;
  duration: number;
  inactive: boolean;
  link: string | null;
  event: {
    id: number;
    name: string;
    abbreviation: string;
    recurring: boolean;
    interval: number | null;
  };
  users: User[];
}
export type capafield = {
  id: number;
  capacity: number;
  apiId: number;
  timeStamp: moment.Moment | null;
  subevent: SubEvent[];
};

export type capafieldResponse = {
  id: number;
  capa: number;
  time: moment.Moment | null;
  events: SubEvent[];
};

export enum Capacity {
  Zero,
  Half,
  Full,
  Blocked,
}
