import React, { Fragment, useState } from 'react';
import ParticipantOption, {
  filterOptions,
  ParticipantOptions,
} from '../EventModal/Participants';
import {
  useParticipantsOptions,
  filterCalenderDate,
  getValidationState,
  validate,
} from '../EventModal/EventUtils';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { LoadingButton } from '@atlaskit/button';
import { DatePicker } from '@atlaskit/datetime-picker';
import Button from '@atlaskit/button';
import { Body, Header, Footer } from 'lib/Modal';
import { addOffDay } from 'store/slice/project';
import { OffDayType } from 'types/enum';
import { useDispatch } from 'react-redux';
import { useReduxState } from 'hooks/useRedux';
import { CheckboxSelect } from '@atlaskit/select';

export interface IFormValues {
  description: string;
  holiDate: string;
  affectedUsers: Array<ParticipantOptions>;
}

interface IAddHolidayProps {
  onClose: () => void;
}

const AddHoliday: React.FC<IAddHolidayProps> = ({ onClose }) => {
  const { locale } = useReduxState();
  const { OPTIONS: userIntervalOptions } = useParticipantsOptions(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (values: IFormValues) => {
    try {
      const startDate = new Date(values?.holiDate).toISOString();
      const holidayObject = {
        type: OffDayType.HOLIDAY,
        startDate,
        endDate: startDate,
        description: values?.description,
        affectedUsers: values?.affectedUsers.map((user) => {
          return user.value;
        }),
      };
      setIsLoading(true);
      await dispatch(addOffDay(holidayObject));
      setIsLoading(false);
      onClose();
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <>
      <Header>
        <h3>{locale?.strings?.add_holiday}</h3>{' '}
      </Header>
      <Form onSubmit={onSubmit}>
        {({ formProps }) => (
          <form {...formProps}>
            <Body>
              <div className="form-fields">
                <Field
                  isRequired
                  label={locale?.strings?.add_holiday_name}
                  name="description"
                  defaultValue=""
                  validate={(value) =>
                    value && value.length < 4
                      ? locale?.strings?.validation_min_characters_error.replace(
                          ':n',
                          '4'
                        )
                      : value && /^\S.*/.test(value) !== true
                      ? locale?.strings?.validation_no_space
                      : value && value.length > 20
                      ? locale?.strings?.validation_max_characters_error.replace(
                          ':n',
                          '20'
                        )
                      : undefined
                  }>
                  {({ fieldProps, error }) => (
                    <Fragment>
                      <Textfield {...fieldProps} />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </Fragment>
                  )}
                </Field>

                <Field
                  isRequired
                  defaultValue=''
                  label={locale?.strings?.add_holiday_date}
                  name="holiDate"
                  validate={(value) =>
                    !value
                      ? locale?.strings?.add_holiday_invalid_date
                      : filterCalenderDate(value)
                      ? locale?.strings?.add_holiday_invalid_date
                      : undefined
                  }>
                  {({ fieldProps, error }) => (
                    <Fragment>
                      <DatePicker
                        {...fieldProps}
                        placeholder={locale?.strings?.date_placeholder}
                        dateFormat={locale?.strings?.date_format}
                        disabledDateFilter={filterCalenderDate}
                        weekStartDay={1}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </Fragment>
                  )}
                </Field>

                <Field
                  isRequired
                  label={locale?.strings?.add_holiday_members}
                  name="affectedUsers"
                  validate={validate(1)}>
                  {({ fieldProps, error, meta: { valid } }: any) => {
                    if (userIntervalOptions.length < 1) {
                      error = 'NO_PARTICIPANT';
                    }
                    return (
                      <Fragment>
                        <CheckboxSelect
                          {...fieldProps}
                          inputId="checkbox-select-example"
                          className="checkbox-select"
                          classNamePrefix="select"
                          options={userIntervalOptions}
                          getOptionValue={filterOptions}
                          formatOptionLabel={ParticipantOption}
                          placeholder={locale?.strings?.add_holiday_members}
                          validationState={getValidationState(error, valid)}
                          shouldFitContainer
                        />
                        {error === 'NO_PARTICIPANT' && (
                          <ErrorMessage>
                            {
                              locale?.strings
                                ?.apply_leave_add_at_least_one_team_member
                            }
                          </ErrorMessage>
                        )}
                        {error === 'EMPTY' && (
                          <ErrorMessage>
                            {locale?.strings?.validation_participant_min_error.replace(
                              ':n',
                              '1'
                            )}
                          </ErrorMessage>
                        )}
                      </Fragment>
                    );
                  }}
                </Field>
              </div>
            </Body>
            <Footer>
              <span></span>
              <div>
                <Button className="secondary-btn" onClick={onClose}>
                {locale?.strings?.cancel}
                </Button>
                <LoadingButton
                  appearance="primary"
                  type="submit"
                  isLoading={isLoading}>
                  {locale?.strings?.create}
                </LoadingButton>
              </div>
            </Footer>
          </form>
        )}
      </Form>
    </>
  );
};

export default AddHoliday;
