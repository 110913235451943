import React, { Fragment, useState } from 'react';
import Select from '@atlaskit/select';
import Button, { LoadingButton } from '@atlaskit/button';
import { ModalBackDrop, Modal, Header, Body, Footer } from 'lib/Modal';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import { useReduxState } from 'hooks/useRedux';
import { useDispatch } from 'react-redux';
import '../ModalStyle.scss';
import { updateJiraProjectDetails } from 'store/slice/JiraData';

interface Props {
  close: () => void;
}

const JiraProjectModal: React.FC<Props> = (props) => {
  const { close } = props;

  const {
    locale,
    actions: {
      jiraDataUserLimit: { jiraProject },
    },
  } = useReduxState();

  const [jiraProjectModel, setjiraProjectModel] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const project = jiraProject.values;
  const useJiraProjectsOptions = () => {
    const OPTIONS = project.map((project) => {
      return {
        label: project?.name,
        value: project?.id,
        key: project?.key,
        filterValues: [project?.name, project?.key],
      };
    });

    return { OPTIONS };
  };

  const { OPTIONS: jiraProjectIntervalOption } = useJiraProjectsOptions();
  const onSubmit = async (values: any) => {
    try {
      const jiraProjectObject = {
        projectId: values?.jiraproject.value,
        projectKey: values?.jiraproject.key,
        projectName: values?.jiraproject.label,
      };
      setIsLoading(true);
      dispatch(updateJiraProjectDetails(jiraProjectObject));
      setIsLoading(false);
      setjiraProjectModel(false);
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <div>
      {jiraProjectModel && (
        <Modal onClose={close}>
          <ModalBackDrop>
            <Header>
              <h3> Jira Projects</h3>
            </Header>
            <Form onSubmit={onSubmit}>
              {({ formProps }) => (
                <form {...formProps}>
                  <Body>
                    <div className="form-fields">
                      <Field
                        name="jiraproject"
                        isRequired
                        label={locale?.strings?.select_jira_project}>
                        {({ fieldProps, error, meta: { valid } }: any) => (
                          <Fragment>
                            <Select
                              {...fieldProps}
                              name="jiraproject"
                              options={jiraProjectIntervalOption}
                              placeholder={locale?.strings?.jira_project}
                              shouldFitContainer
                            />
                            {error === 'EMPTY' && (
                              <ErrorMessage>
                                {locale?.strings?.validation_participant_min_error.replace(
                                  ':n',
                                  '2'
                                )}
                              </ErrorMessage>
                            )}
                          </Fragment>
                        )}
                      </Field>
                    </div>
                  </Body>
                  <Footer>
                    <span></span>
                    <div>
                      <Button className="secondary-btn" onClick={close}>
                        {locale?.strings?.cancel}
                      </Button>
                      <LoadingButton
                        appearance="primary"
                        type="submit"
                        isLoading={isLoading}>
                        Import
                      </LoadingButton>
                    </div>
                  </Footer>
                </form>
              )}
            </Form>
          </ModalBackDrop>
        </Modal>
      )}
    </div>
  );
};

export default JiraProjectModal;
