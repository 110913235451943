import React, { Fragment, useState } from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import Button from '@atlaskit/button';
import { ProjectNotFound } from 'pages/project-page/components/project-not-found/ProjectNotFound';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AddIcon from '@atlaskit/icon/glyph/add';
import EventModal from 'components/Modals/EventModal';
import { useReduxState } from 'hooks/useRedux';
import './Event.scss';
import { EventCard } from './EventCard';
import { Link } from 'react-router-dom';

const Event: React.FC = () => {
  const [eventOpen, setEventOpen] = useState<boolean>(false);

  const {
    locale,
    project: { isLoading, hasErrored, events },
  } = useReduxState();

  const closeEventModal = () => setEventOpen(false);

  const openEventModal = () => setEventOpen(true);

  const renderEventCard = () => {
    return new Array(3).fill(0).map((a, index) => {
      return (
        <GridColumn medium={4} key={index}>
          <div className="c-card">
            <div className="c-card__header">
              <h3>
                <Skeleton width={176} height={18} />
              </h3>
            </div>
            <div className="c-card__content">
              <div className="event-info">
                <div className="event-info__item event-info__interval">
                  <Skeleton width={160} height={12} />
                </div>
                <div className="event-info__calendar event-info__item">
                  <div className="event-info__calendar-date">
                    <Skeleton width={120} height={12} />
                  </div>
                  <div className="event-info__calendar-time">
                    <Skeleton width={120} height={12} />
                  </div>
                </div>
                <div className="event-info__item">
                  <h4>
                    <Skeleton width={88} height={18} />
                  </h4>
                  <span>
                    <Skeleton width={240} height={12} />
                  </span>
                </div>
                <div className="event-info__item">
                  <h4>
                    <Skeleton width={100} height={18} />
                  </h4>
                  <span>
                    <Skeleton circle={true} height={32} width={32} />
                    <Skeleton
                      style={{
                        position: 'relative',
                        left: '-10px',
                      }}
                      circle={true}
                      height={32}
                      width={32}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </GridColumn>
      );
    });
  };
  const renderSkeleton = () => {
    return (
      <SkeletonTheme color="#e8e7e7" highlightColor="#eee">
        <div className="ct-block">
          <div className="ct--sidebar">
            <ul className="ct__list">
              <li className="ct__list-item">
                <Button
                  className="ct__list-btn"
                  appearance="primary"
                  iconBefore={<AddIcon label="add icon" size="small" />}
                  onClick={openEventModal}>
                  {locale?.strings?.new_events}
                </Button>
              </li>
            </ul>
          </div>
          <div className="ct--content">
            <div className="ct-breadcrumb">
              <div className="ct-breadcrumb__item">
                <Link to={`/project/`}>Home</Link>
                <span>/</span>
                <Link to={`/project/calendar`}>{locale?.strings?.events}</Link>
              </div>
              <h2 className="ct-breadcrumb__title">{locale?.strings?.events}</h2>
            </div>

            <div className="events" style={{ margin: '0 -15px' }}>
              <Grid layout="fluid">{renderEventCard()}</Grid>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    );
  };

  const renderEvents = () => {
    return events?.map((e) => {
      const se = e?.subevent?.find((se) => {
        return new Date(se?.time) >= new Date();
      });
      if (se) {
        return <EventCard key={e?.id} event={e} subEvent={se} />;
      } else {
        return null;
      }
    });
  };

  if (isLoading) return renderSkeleton();

  if (hasErrored) return <ProjectNotFound />;

  return (
    <Fragment>
      {eventOpen && <EventModal close={closeEventModal} />}

      <div className="ct-block">
        <div className="ct--sidebar">
          <ul className="ct__list">
            <li className="ct__list-item">
              <Button
                className="ct__list-btn"
                appearance="primary"
                iconBefore={<AddIcon label="add icon" size="small" />}
                onClick={openEventModal}>
                {locale?.strings?.new_events}
              </Button>
            </li>
          </ul>
        </div>
        <div className="ct--content">
          <div className="ct-breadcrumb">
            <div className="ct-breadcrumb__item">
              <Link to={`/project/`}>Home</Link>
              <span>/</span>
              <Link to={`/project/calendar`}>{locale?.strings?.events}</Link>
            </div>
            <h2 className="ct-breadcrumb__title">{locale?.strings?.events}</h2>
          </div>

          <div className="events" style={{ margin: '0 -15px' }}>
            <Grid layout="fluid">
              {events?.length > 0 ? (
                renderEvents()
              ) : (
                <div className="sprint-events__empty">
                  <span>{locale?.strings?.no_events}</span>
                </div>
              )}
            </Grid>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Event;
