import Button from '@atlaskit/button';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../NewProjectPage.scss';
import VoucherValidateModal from 'components/Modals/VoucherModal';
import PaymentRequestModal from 'components/Modals/VoucherModal/paymentRequestIndex';
import { useReduxState } from 'hooks/useRedux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { next } from 'store/slice/createProjectOnboarding/createProjectOnboarding.slice';
import { useDispatch } from 'react-redux';

export const VoucherForm: React.FC = () => {
  const { locale } = useReduxState();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const code = queryParams.get('code');
  const dispatch = useDispatch();

  useEffect(() => {
    if (code) {
      dispatch(next(true));
    }
  }, [code]);

  const [voucherValidateModal, setvoucherValidateModal] = useState(false);
  const closevoucherValidateImport = () => setvoucherValidateModal(false);

  const [paymentRequestModal, setpaymentRequestModal] = useState(false);
  const closepaymentRequestModalImport = () => setpaymentRequestModal(false);

  const voucherModal = () => {
    setvoucherValidateModal(true);
  };

  const paymentModal = () => {
    setpaymentRequestModal(true);
  };
  return (
    <div>
      {voucherValidateModal && (
        <VoucherValidateModal close={closevoucherValidateImport} />
      )}

      {paymentRequestModal && (
        <PaymentRequestModal close={closepaymentRequestModalImport} />
      )}

      <ToastContainer
        pauseOnHover={false}
        autoClose={1000}
        hideProgressBar={true}
        toastStyle={{ color: 'green' }}
      />
      <div className="ct-box">
        {/* <h2 className="ct-box__logo">capa.team</h2> */}
        <div className="ct-box__title">
          {locale?.strings?.easy_capacity_planning}
        </div>
        <div className="ct-box__contact">
          <Button
            className="ct-box__contact-btn"
            appearance="primary"
            onClick={paymentModal}>
            {locale?.strings?.contact_us_now}
          </Button>
          <h4>{locale?.strings?.price}</h4>
        </div>
        <div className="ct-box__voucher">
          <p>{locale?.strings?.do_you_have_any_voucher}</p>
          <Button className="ct-box__voucher-btn" onClick={voucherModal}>
            {locale?.strings?.enter_voucher}
          </Button>
        </div>
      </div>
    </div>
  );
};
