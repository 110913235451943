import axios from 'axios';
import { environment } from 'utils/environment';
import { OffDayResponse } from 'models/ResponseModel';
import { OffDayType } from 'types/enum';

export interface createoffDayArgs {
  id?: number;
  startDate: string;
  endDate: string;
  type: OffDayType;
  description?: string;
  affectedUsers: Array<string | number>;
}

export const getOffDaySList = async (
  projectId: string | number,
  projectLink: string
) => {
  const { data } = await axios.get<OffDayResponse[]>(
    `${environment.API_BASE_URL}offDay/${projectId}?projectLink=${projectLink}`
  );
  return data;
};

export const createOffDay = async (
  args: createoffDayArgs,
  projectId: number,
  projectLink: string
) => {
  const { data } = await axios.post<OffDayResponse>(
    `${environment.API_BASE_URL}offDay/${projectId}?projectLink=${projectLink}`,
    { ...args }
  );
  return data;
};

export const updateOffDay = async (
  args: createoffDayArgs,
  projectId: number,
  projectLink: string
) => {
  const offDayid = args.id;
  delete args.id;
  const { data } = await axios.put<OffDayResponse>(
    `${environment.API_BASE_URL}offDay/${projectId}/${offDayid}?projectLink=${projectLink}`,
    { ...args }
  );
  return data;
};

export const deleteOffDay = async (
  args: number,
  projectId: number,
  projectLink: string,
  ) => {
    const offDayid = args;    
  const { data } = await axios.delete<OffDayResponse>(
    `${environment.API_BASE_URL}offDay/${projectId}/${offDayid}?projectLink=${projectLink}`
  );
  return data;
};
