import axios from 'axios';
import { environment } from 'utils/environment';
import { CapaFieldResponse } from 'models/ResponseModel';
export interface GetCapacityByUserArgs {
  userId: number;
  sprintId: number;
  projectLink: string;
}

export const getCapacityByUser = async (args: GetCapacityByUserArgs) => {
  const { data } = await axios.get<CapaFieldResponse>(
    `${environment.API_BASE_URL}user/${args.userId}/${args.sprintId}/capa?projectLink=${args.projectLink}`
  );
  return data;
};

export interface GetCapacityBySprintArgs {
  sprintId: number;
  projectLink: string;
}

export const getCapacityBySprint = async (args: GetCapacityBySprintArgs) => {
  const { data } = await axios.get<CapaFieldResponse>(
    `${environment.API_BASE_URL}sprint/${args.sprintId}/info?projectLink=${args.projectLink}`
  );
  return data;
};
