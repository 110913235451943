import { environment } from 'utils/environment';
import axios from 'axios';
import store from 'store';
import { error as globalError } from 'store/slice/globalError/globaleError.slice';

export interface postFeedbackBody {
  userEmail: string;
  text: string;
  topic: string;
  rating: number;
}

export interface postFeedbackArgs {
  body: postFeedbackBody;
  projectLink: string;
}

export const postFeedback: any = async (args: postFeedbackArgs) => {
  const res: any = await axios
    .post(
      `${environment.API_BASE_URL}feedback/?projectLink=${args.projectLink}`,
      JSON.stringify(args.body),
      {
        headers: {
          'Content-Type': 'application/json',
          responseType: 'json',
        },
      }
    )
    .then(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response) {
          store.dispatch(globalError('Server antwortet nicht!'));
        }
        if (error.request) {
          store.dispatch(globalError('Server antwortet nicht!'));
        }
        throw error;
      }
    );
  return res;
};
