import { environment } from 'utils/environment';
import axios from 'axios';
import {
  CreateSprintResponse,
  addSprintResponse,
  addSprintInfoResponse,
  getSprintEfficiencyAndCalculationResponse,
} from 'models/ResponseModel';
import { getSprintCapacity } from 'store/slice/sprintCapacity';

export type SprintData = {
  intervalTime: { label: string; value: number };
  sprintStartDate: string;
  projectId: number | null;
  mailToken: string;
};

export interface GetSprintArgs {
  id: string;
  projectLink: string;
}

export const postSprint = async (body: SprintData) => {
  const data = {
    intervalTime: body.intervalTime.value,
    sprintStartDate: body.sprintStartDate,
  };

  const response = await axios.post<CreateSprintResponse>(
    `${environment.API_BASE_URL}project/sprint/${body.projectId}?emailToken=${body.mailToken}`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json',
        responseType: 'json',
      },
    }
  );
  return response.data;
};

export interface AddSprintArgs {
  projectId: number;
  projectLink: string;
}

export interface AddSprintInfoInterface {
  sprintGoal?: string;
}

export const addSprint = async (args: AddSprintArgs) => {
  const { data } = await axios.post<addSprintResponse>(
    `${environment.API_BASE_URL}sprint/${args.projectId}?projectLink=${args.projectLink}`
  );
  return data;
};

export interface AddSprintInfoArgs {
  sprintId: number;
  projectLink: string;
  sprintGoal: string;
}

export interface UpdateSprintInfoArgs {
  sprintId: number;
  projectLink: string;
  coveredCapacity: number;
}

export interface UpdateCalculationTableArgsRequest {
  sprintId: number;
  projectId: number;
  projectLink: string;
  maxPersonDays: number;
  maxStoryPoints: number;
}

export interface UpdateCalculationTableArgs {
  sprintId: number;
  maxPersonDays: number;
  maxStoryPoints: number;
}

export const addSprintInfo = async (args: AddSprintInfoArgs) => {
  const { data } = await axios.put<addSprintInfoResponse>(
    `${environment.API_BASE_URL}sprint/${args.sprintId}?projectLink=${args.projectLink}`,
    {
      sprintGoal: args.sprintGoal,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        responseType: 'json',
      },
    }
  );

  return data;
};

export const updateCoveredCapacity = async (args: UpdateSprintInfoArgs) => {
  const { data } = await axios.put<addSprintInfoResponse>(
    `${environment.API_BASE_URL}sprint/covered/${args.sprintId}?projectLink=${args.projectLink}`,
    {
      coveredCapacity: args.coveredCapacity,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        responseType: 'json',
      },
    }
  );

  return data;
};

export const updateCalculationTable = async (
  args: UpdateCalculationTableArgsRequest
) => {
  const { data } = await axios.put<addSprintInfoResponse>(
    `${environment.API_BASE_URL}sprint/calculation/${args.projectId}/${args.sprintId}?projectLink=${args.projectLink}`,
    {
      maxPersonDays: args.maxPersonDays,
      maxStoryPoints: args.maxStoryPoints,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        responseType: 'json',
      },
    }
  );

  return data;
};

export const fetchAllSprintCapacity = async (
  sprints: any,
  projectLink: string,
  dispatch: any
) => {
  sprints.map(async (item: any) => {
    await dispatch(
      getSprintCapacity({
        sprintId: item.sprintId,
        projectLink: projectLink,
      })
    );
    return item;
  });
};

export const fetchSprintCapacity = async (
  sprintId: number,
  projectLink: string,
  dispatch: any
) => {
  await dispatch(
    getSprintCapacity({
      sprintId: sprintId,
      projectLink: projectLink,
    })
  );
};

export interface GetSprintEfficiencyAndCalculationArgs {
  projectId: number;
  projectLink: string;
}

export const getSprintEfficiencyAndCalculation = async (
  args: GetSprintEfficiencyAndCalculationArgs
) => {
  const { data } = await axios.get<getSprintEfficiencyAndCalculationResponse>(
    `${environment.API_BASE_URL}project/${args.projectId}/sprintVelocity?projectLink=${args.projectLink}`
  );
  return data;
};
