import { next } from 'store/slice/createProjectOnboarding/createProjectOnboarding.slice';

const english = {
  onboarding_create_project: 'Create Project',
  onboarding_confirm_email: 'Confirm E-Mail',
  onboarding_define_iteration: 'Define Iteration',
  onboarding_select_users: 'Select Users',
  onboarding_done: 'Done',
  new_project: 'New Project',
  create_new_project: 'Create a new project',
  project_name: 'Project Name',
  organization_name: 'Organization/Teamname',
  project_manager: 'Project Manager',
  email_address: 'E-Mail Address',
  confirm_email: 'Confirm',
  create_project: 'Create Project',
  second_email_sent_text:
    `We've just send you a mail which contains the admin and user link for your project.`,
  set_iteration: 'Set iteration',
  ready: 'Ready',
  field_types: 'Different field types',
  full_available: 'Fully available',
  half_available: 'Partially available',
  not_available: 'Not available',
  no_working_day: 'No working day',
  morning: 'Morning',
  evening: 'Evening',
  iteration: 'Iteration',
  percentage: 'Percentage',
  period: 'Period',
  create_team_member: 'Create team-member',
  button_add: 'Add',
  edit_team_member: 'Edit Team Member',
  user_capacity_conttribution: 'Capacity Contribution',
  username: 'Username',
  role_position: 'Role/Position',
  create: 'Create',
  close: 'Close',
  user_inactive: 'Inactive',
  remove_team_member: 'Remove Team Member',
  select_all: 'Select All',
  unselect_all: 'Unselect All',
  delete_user: 'Delete user',
  delete_user_warning: 'Deleted users cannot be restored afterwards!',
  cancel: 'Cancel',
  delete: 'Delete',
  save: 'Save',
  need_sprint_info:
    'We still need some information about your iterations/sprints',
  select_users_info: 'Select Users from the list',
  validation_not_empty: 'Field cannot be empty',
  validation_min_characters: 'min 4 characters',
  validation_too_short: 'too short',
  validation_too_long: 'too long',
  validation_no_space: 'must not start with a space',
  validation_no_special_characters: 'must not contain special characters',
  validation_email: 'please enter a valid e-mail address',
  name_and_surname_add_later: 'Name & Surname (can be added later)',
  confirmation_code: 'Confirmation Code',
  confimation_email_text: `We have just sent you a confirmation link with a confirmation code. 
  Please check your {0}Email inbox and enter the confirmation code in the field below
   (small hint: the code consists of numbers only)`,
  week: 'Week',
  weeks: 'Weeks',
  interval_length: 'Sprint length',
  interval_length_info: 'How many weeks equal one sprint? 1,2,3 or 4 weeks?',
  sprint_start_date: 'Sprint Start',
  sprint_start_day:
    'On which day does the first sprint or the next sprint start?',
  create_sprint: 'Create sprint',
  select_users: 'Select Users',
  validation_start_date: 'please choose a start date',
  validation_iteration_length: 'please choose a valid iteration length',
  next_sprint: 'Next Sprint',
  validation_all_characters: 'All characters allowed, min :num characters.',
  validation_max_20: 'must not contain more than 20 characters',
  validation_max_users: 'The limit of :n users has already been reached',
  overview_of_capacity: 'Overview of Capacity',
  person_day: 'Person Day',
  person_days: 'Person Days',
  story_point: 'Story Point',
  story_points: 'Story Points',
  email_not_received: `Didn't receive email?`,
  check_spam: '{0}Please check your spam folder or',
  resend_confirmation_code: 'Resend confirmation code',
  resend_confirmation_code_info:
    'Email has been resent. Please check your email',
  project_created_successfully: 'Project Created Successfully',
  go_to_project: 'Go to Project',
  back: 'Back',
  capa_estimation_method: 'Estimation method',
  capa_estimation_method_persondays: 'Persondays (PD)',
  capa_estimation_method_storypoints: 'Story Points (SP)',
  welcome_message: 'Welcome to',
  welcome_message_help:
    'Let’s find out how we can help you, in less than a minute.',
  welcome_message_show: 'Show me Around',
  welcome_add_team_member_intro: `Before we start our sprint, we'll first need to add some team  members here!`,
  welcome_add_team_member: 'Add Members',
  you_have_feedback: `You've got feedback?`,
  how_satisfied_with_capa: 'How satisfied are you currently with',
  specific_topic_for_feedback:
    'Is there a specific topic you want to give us feedback on?',
  button_send: 'Send',
  feedback_success_info: 'We received your feedback!',
  please_provide_detail_for_topic:
    'Please provide some details about the topic:',
  please_provide_feedback_placeholder: 'Please write down your feedback here',
  ticket_reference: 'Ticket Reference',
  add_ticket_reference: 'Add Ticket Reference',
  add_story_points: 'Add Story Points',
  ticket_reference_notes: `If your team has been estimating with hours or days, you can use
  this reference table to get a better feel for Story Point
  values. However, don't make the mistake of converting hours or days one-to-one into Story Points. Teams assign story points
  relative to work complexity, the amount of work, and risk or
  uncertainty. They are always a relative estimation.`,
  ticket_reference_note: 'Note',
  ticket_reference_add_info: `If you have user stories as a reference for story point
  values, you can enter them here:`,
  button_close: 'Close',
  feedback_thank_you: 'Thank you!',
  your_contact_email: 'Your contact E-Mail',
  please_select_one: 'Please select one',
  create_event: 'Create Event',
  edit_event: 'Edit Event',
  event_name: 'Name',
  event_short_code: 'Short Code',
  recurring_event: 'Recurring Event',
  event_recurring_interval: 'Recurring Interval',
  event_link: 'Enter a URL',
  start_time: 'Start',
  end_time: 'End',
  event_date: 'Date',
  event_start_time: 'Start Time',
  event_end_time: 'End Time',
  event_interval_info: 'How often does this event occur ? 1 ,2, 3 or 4 weeks',
  event_participants: 'Participants',
  participants: 'Participants',
  hashtags: 'Hashtags',
  validation_min_characters_error: 'too short, must be at least :n characters',
  validation_max_characters_error:
    'too long, must not contain more than :n characters',
  validation_invalid_date:
    'Invalid date, Date must be within the current sprint',
  validation_invalid_time: 'Invalid Time',
  validation_end_time_error: 'End Time must be greater than start time',
  validation_participant_min_error: 'Please select at least :n participants',
  validation_event_duration_range_error:
    'Meeting duration must be between 1 hour and 8 hours',
  event_repeat_weekly: 'Every week',
  event_repeat_every_n_weekly: 'Every :n week',
  event_repeat_second_weekly: 'Every Second Week',
  event_repeat_fourth_weekly: 'Every Fourth Week',
  event_repeat_once_month: 'Once in a month',
  validation_event_duration_range_error_for_dailys:
    'Meeting duration must be between 15 minutes and 4 hours',
  validation_url: 'Please enter a valid url',
  event_repeat_daily: 'Every Day',
  sprint_meeting: 'Sprint Meeting',
  event_occurance_every_day: 'Occurs every day',
  event_occurance_single_info: 'Occurs Every 1 week',
  event_occurance_multi_info: 'Occurs Every :n weeks',
  non_recurring_event_info: 'One Time Event',
  sprint_reference: 'Sprint reference',
  on_boarding_tutorial_tutorial_back: 'Back',
  on_boarding_tutorial_tutorial_next: 'Next',
  on_boarding_tutorial_tutorial_dismiss: 'Dismiss',
  on_boarding_tutorial_tutorial_end: 'End',
  on_boarding_tutorial_add_new_team_member:
    'Click here to add a team member to your project.',
  on_boarding_tutorial_add_sprint_goal:
    'Here you can add the current Sprint Goal.',
  on_boarding_tutorial_reference_tickets:
    'Here you can add links to previous tickets/user stories plus the estimation in story points to display your team members references for upcoming estimations.',
  on_boarding_tutorial_add_feedback:
    'What can we improve? Click here to share your feedback with us.',
  on_boarding_tutorial_add_hashtags:
    'Add hashtags to the individual team members in order to filter and search for their specific role.',
  on_boarding_tutorial_overview_hashtags:
    'Here you can view the overview of the hashtags in this sprint ',
  on_boarding_tutorial_add_events: 'Click here to add Events to the calendar.',
  on_boarding_tutorial_update_capacity:
    'Click here to adjust the capacity of your team members.',
  add_holiday: 'Add Holiday',
  update_holiday: 'Update Holiday',
  add_holiday_date: 'Date',
  add_holiday_name: 'Name',
  add_holiday_members: 'Members',
  apply_leave: 'Apply Leave',
  update_leave: 'Update Leave',
  leave_start_date: 'Start Date',
  leave_end_date: 'End Date',
  add_holiday_invalid_date: 'Invalid date',
  apply_leave_end_dategreater_than_start_error:
    'End date must be greater or equal to Start date',
  apply_leave_add_at_least_one_team_member:
    'Please add at least one team member to add holidays or leave',
  date_format: 'MM/DD/YYYY',
  date_placeholder: '02/01/2021',
  jira_project: 'Jira Projects',
  select_jira_project: 'Select Jira Project',
  next: 'Next',
  users_limit_heading: '*Currently, a maximum of 11 users can be selected. In a future version more slots will be available.',
  select_users_limit: 'Please Select the Users.',
  max_users_limit: 'You have maximum numbers of user selection.',
  actual_story_points_completed:
    'How many Storypoints were completed actually?',
  story_points_value: 'Click to enter value',
  new_events: 'New Events',
  no_events: 'No Events.',
  upcoming_holidays: 'Upcoming Holidays',
  copied_clipboard: 'Copied to Clipboard',
  hashtag_overview: 'HashTag Overview',
  hashtag_overview_of_sprint: 'HashTag Overview of',
  getting_started: 'Getting Started',
  add_hashtags: 'Add Hashtags',
  enter_keyword: 'Enter a new hastag',
  create_new_user: 'Create New User',
  add_sprint_goal: 'Add Sprint Goal',
  sprint_goal: 'Sprint Goal',
  add_reference_tickets: 'Add Reference Tickets',
  add_events: 'Add Events',
  no_sprint_goal: 'No Sprint Goal',
  integrate_jira: 'Integrate Jira Data',
  events_overview_of: 'Events Overview of',
  no_capacities: 'There are no capacities yet.',
  feedback: 'Feedback',
  all_hashtags: 'All Hashtags',
  add_new_hashtag: 'Add New Hashtag',
  unselect_contribute_total_capacity: '*Unselect to not contribute in total capacity.',
  add_member: 'Add Member',
  edit: 'Edit',
  project_link: 'Project Link',
  create_project_new: 'Create New Project',
  voucher: 'Voucher',
  message: 'Message',
  request_voucher: 'How can we help you?',
  request: 'Request',
  validate_voucher: 'Validate Voucher',
  validate: 'Validate',
  easy_capacity_planning: 'Easy Capacity Planning',
  contact_us_now: 'Contact Us Now',
  price: 'only 4.99€/month',
  do_you_have_any_voucher: 'Do you have a voucher code?',
  enter_voucher: 'Enter Voucher',
  invalid_voucher: 'Invalid Voucher',
  voucher_email: 'Voucher Request Email has been successfully sent',
  events: 'Events',
  event: 'Event',
  calendar: 'Calendar',
  delete_holiday: 'Delete Holiday',
  delete_holiday_message: 'Deleted holiday cannot be restored afterwards!',
  delete_event: 'Delete event',
  delete_event_message: 'Deleted events cannot be restored afterwards!',
  save_details: 'Save details',
  save_particular_meeting: 'Save for all or for this particular meeting',
  holiday: 'holiday',
  leave: 'leave',
  update_capacity: 'Update Capacity',
  copied: 'Copied!!!',
  copy_project_link:'Copy Project Link',
  show_prev_sprints: 'Show Prev Sprints',
  hide_prev_sprints: 'Hide Prev Sprints',
  suggestions: 'Suggestions',
  others: 'Others',
};

export default english;