import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LocalizedStrings from 'react-localization';
import english from 'locales/en';
import deutsch from 'locales/de';

export const strings: any = new LocalizedStrings({
  en: english,
  de: deutsch,
});

const DEFAULT_LOCALE = 'de';

export function getClientLanguagePreference(): string {
  return localStorage.getItem('locale') || DEFAULT_LOCALE;
}

export function setClientLanguagePreference(localeValue: string): void {
  localStorage.setItem('locale', localeValue);
}

export interface LocalState {
  language: string;
  strings: Record<string, string>;
}

const initialLocale = getClientLanguagePreference();
// initially set strings to user preference locale
strings.setLanguage(initialLocale);

export const initialState: LocalState = {
  language: initialLocale,
  strings: strings,
};

const locale = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    update(state, action: PayloadAction<string>) {
      const localeValue = action.payload;
      setClientLanguagePreference(localeValue);
      strings.setLanguage(localeValue);
      state.language = localeValue;
    },
  },
});

export const { update } = locale.actions;
export default locale.reducer;
