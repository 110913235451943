import React, { useState } from 'react';
import Button, { LoadingButton, ButtonGroup } from '@atlaskit/button';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import styled from 'styled-components';
import TextField from '@atlaskit/textfield';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as emailService from 'services/email.service';
import { prev } from 'store/slice/createProjectOnboarding/createProjectOnboarding.slice';
import Form, {
  ErrorMessage,
  Field,
  FormFooter,
  HelperMessage,
} from '@atlaskit/form';
import { confirmEmail } from 'store/slice/confirmEmail';
import { useReduxState } from 'hooks/useRedux';
import { strings } from 'store/slice/locale/locale.slice';
import { fireProjectCreationTimeOnConfirmEmailForm } from 'tracking/createProject';
import { updateConfirmFormCompletedTime } from 'store/slice/createProject/createProject.slice';

const ConfirmEmailForm: React.FC = () => {
  const {
    locale,
    actions: {
      confirmEmail: confirmEmailState,
      createProject: {
        project: { mailToken, id: projectId },
      },
    },
  } = useReduxState();
  React.useEffect(() => {
    // when the confirm email form unmounts
    return function timerTrackAndUpdateRedux() {
      // send time spend on confirm email form to the google analytics
      // also update the redux state with the time at which this form is completed
      const newtime = Date.now();
      fireProjectCreationTimeOnConfirmEmailForm(newtime);
      dispatch(updateConfirmFormCompletedTime(newtime));
    };
  }, []);

  const dispatch = useDispatch();

  const handleSubmit = (data: emailService.eMailData) => {
    const args = {
      mailToken: mailToken,
      mailCode: data?.mailCode,
    };
    dispatch(confirmEmail(args));
    return;
  };
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [showResendInfo, setshowResendInfo] = useState(false);

  const resendCodeHandler = async () => {
    setIsResendLoading(true);
    try {
      await emailService?.resendConfirmation(projectId as unknown as number);
      setshowResendInfo(true);
    } catch (err) {
      setshowResendInfo(false);
    }

    setIsResendLoading(false);
  };

  const goBack = () => {
    dispatch(prev(true));
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {({ formProps }) => (
          <form {...formProps}>
            {/* <Fragment>{this.props.hasErrored && <h1></h1>}</Fragment> */}
            <Field
              name="mailCode"
              label={locale?.strings?.confirmation_code}
              isRequired
              defaultValue="">
              {({ fieldProps }) => (
                <Fragment>
                  <TextField autoComplete="off" {...fieldProps} />
                  {confirmEmailState?.error && (
                    <ErrorMessage>{confirmEmailState?.error}</ErrorMessage>
                  )}
                  <HelperMessage>
                    <CustomHelperMessage>
                      {strings.formatString(
                        locale?.strings?.confimation_email_text,
                        <span style={{ color: 'red' }}>*</span>
                      )}
                    </CustomHelperMessage>
                  </HelperMessage>
                </Fragment>
              )}
            </Field>

            <ResendDiv>
              <ResendBlock>
                <HelperMessage>
                  {locale?.strings?.email_not_received}
                  {strings.formatString(
                    locale?.strings?.check_spam,
                    <span style={{ color: 'red' }}> &nbsp;*</span>
                  )}
                </HelperMessage>
                <LoadingButton
                  className="link-btn"
                  appearance="link"
                  spacing="none"
                  isLoading={isResendLoading}
                  onClick={resendCodeHandler}>
                  {locale?.strings?.resend_confirmation_code}
                </LoadingButton>
              </ResendBlock>

              {showResendInfo && !isResendLoading && (
                <HelperMessage>
                  {locale?.strings?.resend_confirmation_code_info}
                </HelperMessage>
              )}
            </ResendDiv>
            <FormFooter>
              <ButtonGroup>
                <Button
                  iconBefore={<ChevronLeftLargeIcon label="ChevronLeft icon" />}
                  onClick={goBack}>
                  {locale?.strings?.back}
                </Button>
                <LoadingButton
                  isDisabled={confirmEmailState?.isLoading}
                  isLoading={confirmEmailState?.isLoading}
                  appearance="primary"
                  type="submit">
                  {locale?.strings?.confirm_email}
                </LoadingButton>
              </ButtonGroup>
            </FormFooter>
          </form>
        )}
      </Form>
    </div>
  );
};

const ResendBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  s .link-btn {
    margin-top: 4px;
    padding-left: 8px;
    font-size: 13px;
    text-decoration: underline;
  }
`;
const ResendDiv = styled.div`
  margin: 0.6rem 0 4rem;
  flex-direction: column;
  display: flex;
  align-items: center;
`;

const CustomHelperMessage = styled.div`
  display: inline;
`;

export default withRouter(ConfirmEmailForm);
