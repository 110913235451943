import React, { Fragment, useState } from 'react';
import Button from '@atlaskit/button';
import { ModalBackDrop, Modal, Header, Body, Footer } from 'lib/Modal';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import { LoadingButton } from '@atlaskit/button';
import Textfield from '@atlaskit/textfield';
import { useReduxState } from 'hooks/useRedux';
import { useDispatch } from 'react-redux';
import '../ModalStyle.scss';
import { voucher } from 'store/slice/voucherPayment';

interface Props {
  close: () => void;
}

const VoucherValidateModal: React.FC<Props> = (props) => {
  const { close } = props;

  const {
    locale,
    validateVoucher: { hasErrored },
  } = useReduxState();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = (values: any) => {
    try {
      const voucherObject = {
        voucher: values?.voucher,
      };
      setIsLoading(true);
      dispatch(voucher(voucherObject));
      setIsLoading(false);
    } catch (err) {
      console.log({ err });
    }
  };

  return (
        <Modal onClose={close}>
          <ModalBackDrop>
            <Header>
              <h3> {locale?.strings?.validate_voucher}</h3>
            </Header>
            <Form onSubmit={onSubmit}>
              {({ formProps }) => (
                <form {...formProps}>
                  <Body>
                    <div className="form-fields">
                      <Field
                        isRequired
                        label={locale?.strings?.voucher}
                        name="voucher"
                        defaultValue=""
                        validate={(value) =>
                          value && value.length < 1
                            ? locale?.strings?.validation_too_short
                            : value && value.length > 30
                            ? locale?.strings?.validation_too_long
                            : undefined
                        }>
                        {({ fieldProps, error }) => (
                          <Fragment>
                            <Textfield {...fieldProps} />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                            {hasErrored && (
                              <ErrorMessage>
                                {locale?.strings?.invalid_voucher}
                              </ErrorMessage>
                            )}
                          </Fragment>
                        )}
                      </Field>
                    </div>
                  </Body>
                  <Footer>
                    <span></span>
                    <div>
                      <Button className="secondary-btn" onClick={close}>
                        {locale?.strings?.cancel}
                      </Button>
                      <LoadingButton
                        appearance="primary"
                        type="submit"
                        isLoading={isLoading}>
                        {locale?.strings?.validate}
                      </LoadingButton>
                    </div>
                  </Footer>
                </form>
              )}
            </Form>
          </ModalBackDrop>
        </Modal>
  );
};

export default VoucherValidateModal;
