import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CreateProjectOnboardingStoreState {
  selectedIndex: number;
}

export const initialState: CreateProjectOnboardingStoreState = {
  selectedIndex: 0,
};

const projectOnboarding = createSlice({
  name: 'onBoarding',
  initialState,
  reducers: {
    reset: () => initialState,
    prev(state, action: PayloadAction<boolean>) {
      const goPrev = action.payload;
      state.selectedIndex =
        goPrev && state?.selectedIndex > 0
          ? state.selectedIndex - 1
          : state.selectedIndex;
    },
    next(state, action: PayloadAction<boolean>) {
      const goNext = action.payload;
      state.selectedIndex = goNext
        ? state.selectedIndex + 1
        : state.selectedIndex;
    },
  },
});

export const { next, prev, reset } = projectOnboarding.actions;
export default projectOnboarding.reducer;
