import { ModalBackDrop, Modal, Header, Footer, Body } from 'lib/Modal';
import React, { Fragment, useState } from 'react';

import { useReduxState } from 'hooks/useRedux';
import Button from '@atlaskit/button';

import { SubEvent } from 'models/ProjectModel';
import {
  getGermanFormatDate,
  getStartEndEventTime,
  getSprintEndDate,
} from 'utils/date';
import { EventBus, EVENT_INFO_POP_UP_MODAL } from 'Event';

import Tooltip from '@atlaskit/tooltip';
import AddIcon from '@atlaskit/icon/glyph/add';
import WatchIcon from '@atlaskit/icon/glyph/watch';

interface SprintEventsOverviewProps {
  close: () => void;
  openEventModal: () => void;
  sprintLength: number;
  sprintStart: Date;
  sprintIndex: number;
}

interface EventList {
  id: number;
  name: string;
  abbreviation: string;
  recurring: boolean;
  interval: number | null;
  subevent: SubEvent;
}

export const SprintEventsOverview: React.FC<SprintEventsOverviewProps> = (
  props
) => {
  const { sprintStart, sprintIndex, close, openEventModal, sprintLength } =
    props;
  const {
    project: { events },
    locale,
  } = useReduxState();
  const sprintEndDate = getSprintEndDate(sprintStart.toString(), sprintLength);
  const sprintEvents: EventList[] = events?.reduce((t: any[], event): any => {
    const subev: EventList[] = event?.subevent
      .filter((e) => {
        const eDate = new Date(new Date(e.time).toDateString());
        if (
          eDate >= new Date(new Date(sprintStart).toDateString()) &&
          eDate <= new Date(new Date(sprintEndDate).toDateString())
        ) {
          return true;
        }
      })
      .map((e) => {
        return {
          ...event,
          subevent: e,
        };
      });
    if (subev) {
      t = [...t, ...subev];
    }
    return t;
  }, []);

  const openEventPopUpModal = (index: number) => {
    EventBus.emit(EVENT_INFO_POP_UP_MODAL.OPEN, {
      event: null,
      todaysEventList: [sprintEvents[index]],
    });
  };

  const renderEventsList = () => {
    return sprintEvents?.map((e, index) => {
      const { startTime, endTime } = getStartEndEventTime(
        e?.subevent?.time,
        e?.subevent?.duration
      );

      return (
        <tr style={{ fontSize: '14px' }} key={e?.id + e?.subevent?.id}>
          <td>{e?.abbreviation}</td>
          <td>{e?.name}</td>

          <td scope="row">
            {getGermanFormatDate(e?.subevent?.time)}, {startTime} - {endTime}{' '}
          </td>
          <td>
            <Tooltip content="View">
              <Button
                spacing="none"
                onClick={(e) => openEventPopUpModal(index)}>
                <WatchIcon label="View Events" size="small"></WatchIcon>
              </Button>
            </Tooltip>
          </td>
        </tr>
      );
    });
  };

  return (
    <Modal onClose={close}>
      <ModalBackDrop onClick={close}>
        <Header>
          <h3>{locale?.strings?.events_overview_of} Sprint {sprintIndex + 1}</h3>
        </Header>
        <Body>
          <div className="table-event-list">
            <table className="c-table c-table--bordered">
              <thead>
                <tr>
                  <th align="left" scope="col">
                    Abbrev
                  </th>
                  <th align="left" scope="col">
                    Name
                  </th>
                  <th align="left" scope="col">
                    Date
                  </th>
                  <th align="left" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {sprintEvents?.length > 0 ? (
                  renderEventsList()
                ) : (
                  <div className="sprint-events__empty">
                    <span>{locale?.strings?.no_events}</span>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </Body>
        <Footer>
          <span></span>
          <div>
            <Button
              className="secondary-btn"
              appearance="primary"
              onClick={openEventModal}>
              <AddIcon label="Add Events" size="small"></AddIcon>
              {locale?.strings?.button_add}
            </Button>
            {/* <Button appearance="default" onClick={close}>
              {locale?.strings?.button_close}
            </Button> */}
          </div>
        </Footer>
      </ModalBackDrop>
    </Modal>
  );
};
export default SprintEventsOverview;
