import useCapaCalculation from 'hooks/useCapaCalculation';
import React from 'react';
import { Capacity } from 'types/capacity';
import './SumField.scss';
export interface SumFieldProps {
  capaSum: Capacity;
  irrelevantCapa: boolean;
  sprintId: number;
}

export const SumField: React.FC<SumFieldProps> = (props) => {
  const { capaSum, irrelevantCapa, sprintId } = props;
  const useCapaCalculationHook = useCapaCalculation();

  const capaMethodShort = useCapaCalculationHook?.renderStringShort();
  const capacity = useCapaCalculationHook?.calculateCapacity(capaSum, sprintId);

  return (
    <div className="sumFieldBorder">
      <div
        className={irrelevantCapa ? 'sumField sumField--disable' : 'sumField'}>
        {capacity} {capaMethodShort}
      </div>
    </div>
  );
};
