import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ValidateVoucher, PaymentRequest } from 'models/voucherPayment';

export interface VoucherStoreStare {
  validateVoucher: ValidateVoucher;
  paymentRequest: PaymentRequest;
  hasErrored: boolean;
  isLoading: boolean;
}

export const initialState: VoucherStoreStare = {
  validateVoucher: { voucher: '' },
  paymentRequest: { userEmail: '', text: '' },
  hasErrored: false,
  isLoading: false,
};

const voucherSlice = createSlice({
  name: 'voucherValidate',
  initialState,
  reducers: {
    reset: () => initialState,
    error(state, action: PayloadAction<boolean>) {
      state.hasErrored = action.payload;
    },
    loading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    validateVoucher(state, action: PayloadAction<ValidateVoucher>) {
      state.validateVoucher = action.payload;
    },
    paymentRequest(state, action: PayloadAction<PaymentRequest>) {
      state.paymentRequest = action.payload;
    },
  },
});

export const { error, loading, validateVoucher, paymentRequest } =
  voucherSlice.actions;
export default voucherSlice.reducer;
