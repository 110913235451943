import { User } from 'models/ProjectModel';

export const sortDeletedUsers = (userLists: User[]) => {
  const activeUsers: User[] = [];
  const deletedUsers: User[] = [];
  userLists.forEach((user: User) => {
    const deltedUser = Boolean(user?.deletedDate);
    if (!deltedUser) {
      activeUsers.push(user);
    } else {
      deletedUsers.push(user);
    }
  });
  return [...activeUsers, ...deletedUsers];
};
