import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Capacity } from 'types/capacity';

export interface sprintCapacityInterface {
  sprintId: number;
  capacity: Capacity;
  hashTags: hashTagCapa[];
  users: userCapa[];
  userSlots: userSlots[];
}

export interface hashTagCapa {
  name: string;
  capacity: Capacity;
}

export interface userCapa {
  id: number;
  capacity: Capacity;
}

export interface userSlots {
  userId: number;
  slots: any[];
}

export interface sprintCapa {
  sprints: Array<sprintCapacityInterface>;
}

export const initialState: sprintCapa = {
  sprints: [],
};

const sprintCapacitySlice = createSlice({
  name: 'sprintCapacity',
  initialState,
  reducers: {
    add(state, action: PayloadAction<sprintCapacityInterface>) {
      const capa = action.payload;
      const index = state?.sprints?.findIndex(
        (e: sprintCapacityInterface) => e?.sprintId === capa?.sprintId
      );

      if (index < 0) {
        state.sprints?.push(action.payload);
      }
    },
    clear(state) {
      state.sprints = [];
    },
    update(state, action: PayloadAction<sprintCapacityInterface[]>) {
      state.sprints = action.payload;
    },
  },
});

export const { add, update, clear } = sprintCapacitySlice.actions;
export default sprintCapacitySlice.reducer;
