import React from 'react';
import { ModalBackDrop, Modal } from 'lib/Modal';
import { OffDay } from 'models/ProjectModel';
import AddHoliday from './addHoliday';
import UpdateHoliday from './updateHoliday';
import HolidayInformation from './Information';

interface Props {
  close: () => void;
  data: OffDay | null;
}

const HolidayModal: React.FC<Props> = ({ data, close }) => {
  const [edit, setEdit] = React.useState<boolean>(false);

  const openEditForm = () => {
    setEdit(true);
  };

  return (
    <Modal onClose={close}>
      <ModalBackDrop>
        {data &&
          (!edit ? (
            <HolidayInformation
              close={close}
              openEditForm={openEditForm}
              data={data}
            />
          ) : (
            <UpdateHoliday onClose={close} data={data} />
          ))}
        {!data && !edit && <AddHoliday onClose={close} />}
      </ModalBackDrop>
    </Modal>
  );
};

export default HolidayModal;
