import React from 'react';
import { useDispatch } from 'react-redux';
import Select from '@atlaskit/select';
import { update as methodUpdate } from 'store/slice/capaMethod/capaMethod.slice';
import { useReduxState } from 'hooks/useRedux';
import { CapacityType, CAPA_METHODS_TYPE } from 'types/capacity';

import './CapaFieldLegende.scss';

const useMethodOptions = () => {
  const { locale } = useReduxState();
  const METHOD_OPTIONS = [
    {
      label: locale?.strings?.capa_estimation_method_persondays,
      value: CAPA_METHODS_TYPE.PERSON_TAGE,
    },
    {
      label: locale?.strings?.capa_estimation_method_storypoints,
      value: CAPA_METHODS_TYPE.STORY_POINTS,
    },
  ];
  return METHOD_OPTIONS;
};

interface SelectInterface {
  label: string;
  value: CapacityType;
}

const CapaFieldLegende: React.FC = () => {
  const {
    locale,
    capaMethod: { method: capaMethod },
  } = useReduxState();

  const dispatch = useDispatch();

  const selectChange = (value: SelectInterface | null) => {
    if (value?.value) {
      dispatch(methodUpdate(value?.value));
    }
  };
  const METHOD_OPTIONS = useMethodOptions();

  const currentValue: SelectInterface =
    METHOD_OPTIONS?.find((e: SelectInterface) => e?.value === capaMethod) ||
    METHOD_OPTIONS[0];

  return (
    <div className="time">
      <div className="time__calc">
        <label htmlFor="select-calc">
          {locale?.strings?.capa_estimation_method}:
        </label>
        <Select
          id="select-calc"
          className="time__calc-select"
          onChange={selectChange}
          classNamePrefix="react-select"
          options={METHOD_OPTIONS}
          value={currentValue}
        />
      </div>
    </div>
  );
};

export default CapaFieldLegende;
