import React, { Fragment } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { useDispatch } from 'react-redux';
import { error as throwGlobalError } from 'store/slice/globalError/globaleError.slice';
import { useReduxState } from 'hooks/useRedux';
import Alert, { Actions } from 'lib/Alert';

export const GlobalErrorWarning: React.FC = () => {
  const {
    globalError: { error: globalError },
  } = useReduxState();

  const dispatch = useDispatch();

  const close = (): void => {
    dispatch(throwGlobalError(null));
  };

  const actions: Actions[] = [
    { text: 'schließen', onClick: close, appearance: 'warning' },
  ];

  return (
    <Fragment>
      {globalError === null ? (
        true
      ) : (
        <div>
          {globalError && (
            <Alert
              appearance="warning"
              actions={actions}
              onClose={close}
              heading="Error">
              {globalError}
            </Alert>
          )}
        </div>
      )}
    </Fragment>
  );
};
