import store from 'store';
import { TAGMANAGER_EVENTS } from './constants';

export const PAGE_USER_INTERACTION_EVENTS = {
  LANDING_PAGE: {
    PAGE: 'landingPage',
    GETTING_STARTED_TUTORIAL: {},
    SHOW_PREV_SPRINTS: 'showprevevents',
    ADD_USER: 'addteammate',
    EDIT_USER: 'editteammate',
    EDIT_USER_CONTRIBUTION: 'editusercontribution',
    REMOVE_USER: 'removeteammember',
    SET_USER_HASHTAGS: 'setteammatehashtag',
    HASH_TAGS: {
      CREATE: 'createhashtag',
      DELETE: 'deletehashtag',
    },
    SPRINT: {
      SLOT_UPDATE: 'slotupdate',
      SPRINT_GOAL: 'addsprintgoal',
      SPRINT_TICKET_REFERENCE: 'editsprintticketreference',
      SPRINT_SP2PD_REFERENCE: 'sp2pdreference',
      SET_SPRINT_COMPLETED_ACTUAL_SP: 'setsprintcompletedsp',
      EVENTS: {
        CREATE: 'createevent',
        SLOT: {
          CLICK: 'eventclickfromslot',
          EDIT: 'editeventfromslot',
          DELETE: 'deleteeventfromslot',
        },
        SPRINT_ITERATION: {
          CLICK: 'eventclickfromiterationmenu',
          EDIT: 'editeventfromiterationmenu',
          DELETE: 'deleteeventfromiterationmenu',
        },
      },
    },
    ADD_SPRINTS: 'addsprints',
  },
  EVENTS_PAGE: {
    PAGE: 'eventsPage',
    CREATE: 'createevent',
    EDIT: 'editevent',
    DELETE: 'deleteevent',
  },
  CALENDAR_PAGE: {
    PAGE: 'calendarPage',
  },
  FEEDBACK: 'submitfeedback',
  LANGUAGE_CHANGE: 'changelanguagepreference',
};

interface ProjectInteractionsProps {
  url: string;
  action: string;
  sprintIteration: null | number;
}

export const fireUserInteractionEvents = (props: ProjectInteractionsProps) => {
  if (global.window && global.window.dataLayer) {
    const {
      global: {
        project: {
          project: {
            id,
            projectLink: { type },
          },
        },
      },
    } = store.getState();
    window.dataLayer.push({
      event: TAGMANAGER_EVENTS.PAGE_INTERACTION,
      interaction: {
        ...props,
        projectId: id,
        user: type,
      },
    });
  }
};
