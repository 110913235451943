const colors: string[] = ['red', 'yellow', 'green', 'blue'];

const getRandomColor = () => {
  let lastColor = '';
  return function getRandomClass(isDeleted = false): string {
    if (isDeleted) {
      return 'deleted';
    }
    const randomindex: number = Math.round(Math.random() * (colors.length - 1));
    const color = colors[randomindex];
    if (color === lastColor) {
      return getRandomClass(isDeleted);
    }
    lastColor = color;
    return color;
  };
};

export const getRandomColorClass = getRandomColor();
