import React, { Fragment, useState } from 'react';
import { OffDayType } from 'types/enum';
import { Header, Body, Footer } from 'lib/Modal';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Button from '@atlaskit/button';
import { LoadingButton } from '@atlaskit/button';
import { addOffDay } from 'store/slice/project';
import {
  useParticipantsOptions,
  filterCalenderDate,
  getValidationState,
  validate,
  isDateEqualAfter,
} from '../../EventModal/EventUtils';
import { useReduxState } from 'hooks/useRedux';
import { DatePicker } from '@atlaskit/datetime-picker';
import { CheckboxSelect } from '@atlaskit/select';
import ParticipantOption, {
  filterOptions,
  ParticipantOptions,
} from '../../EventModal/Participants';
import { useDispatch } from 'react-redux';

export interface IFormValues {
  startDate: string;
  endDate: string;
  affectedUsers: Array<ParticipantOptions>;
}

interface IAddLeaveProps {
  close: () => void;
}

const AddLeave: React.FC<IAddLeaveProps> = ({ close }) => {
  const { locale } = useReduxState();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const { OPTIONS: userIntervalOptions } = useParticipantsOptions(false);

  const startDateRef = React.useRef<string>();

  const onSubmit = async (values: IFormValues) => {
    try {
      const startDate = new Date(values?.startDate).toISOString();
      const endDate = new Date(values?.endDate).toISOString();
      const holidayObject = {
        startDate,
        endDate,
        type: OffDayType.LEAVE,
        affectedUsers: values?.affectedUsers.map((user) => {
          return user.value;
        }),
      };
      setIsLoading(true);
      await dispatch(addOffDay(holidayObject));
      setIsLoading(false);
      close();
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <>
      <Header>
        <h3>{locale?.strings?.apply_leave}</h3>
      </Header>
      <Form onSubmit={onSubmit}>
        {({ formProps }) => (
          <form {...formProps}>
            <Body>
              <div className="form-fields">
                <Field
                  isRequired
                  defaultValue=''         
                  label={locale?.strings?.leave_start_date}
                  name="startDate"
                  validate={(value) =>
                    !value
                      ? locale?.strings?.add_holiday_invalid_date
                      : undefined
                  }>
                  {({ fieldProps, error }) => (
                    <Fragment>
                      <DatePicker
                        {...fieldProps}
                        placeholder={locale?.strings?.date_placeholder}
                        dateFormat={locale?.strings?.date_format}
                        disabledDateFilter={filterCalenderDate}
                        weekStartDay={1}
                        onChange={(value) => {
                          startDateRef.current = value;
                          fieldProps.onChange(value);
                        }}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </Fragment>
                  )}
                </Field>

                <Field
                  isRequired
                  defaultValue=''
                  label={locale?.strings?.leave_end_date}
                  name="endDate"
                  validate={(value) =>
                    !value
                      ? locale?.strings?.add_holiday_invalid_date
                      : !isDateEqualAfter(value, startDateRef.current as string)
                      ? locale?.strings
                          ?.apply_leave_end_dategreater_than_start_error
                      : undefined
                  }>
                  {({ fieldProps, error }) => (
                    <Fragment>
                      <DatePicker
                        {...fieldProps}
                        placeholder={locale?.strings?.date_placeholder}
                        dateFormat={locale?.strings?.date_format}
                        disabledDateFilter={filterCalenderDate}
                        weekStartDay={1}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </Fragment>
                  )}
                </Field>

                <Field
                  isRequired
                  label={locale?.strings?.add_holiday_members}
                  name="affectedUsers"
                  validate={validate(1)}>
                  {({ fieldProps, error, meta: { valid } }: any) => {
                    if (userIntervalOptions.length < 1) {
                      error = 'NO_PARTICIPANT';
                    }

                    return (
                      <Fragment>
                        <CheckboxSelect
                          {...fieldProps}
                          options={userIntervalOptions}
                          getOptionValue={filterOptions}
                          formatOptionLabel={ParticipantOption}
                          placeholder={locale?.strings?.add_holiday_members}
                          validationState={getValidationState(error, valid)}
                          shouldFitContainer
                        />

                        {error === 'NO_PARTICIPANT' && (
                          <ErrorMessage>
                            {
                              locale?.strings
                                ?.apply_leave_add_at_least_one_team_member
                            }
                          </ErrorMessage>
                        )}
                        {error === 'EMPTY' && (
                          <ErrorMessage>
                            {locale?.strings?.validation_participant_min_error.replace(
                              ':n',
                              '1'
                            )}
                          </ErrorMessage>
                        )}
                      </Fragment>
                    );
                  }}
                </Field>
              </div>
            </Body>
            <Footer>
              <span></span>
              <div>
                <Button className="secondary-btn" onClick={close}>
                {locale?.strings?.cancel}
                </Button>
                <LoadingButton
                  appearance="primary"
                  type="submit"
                  isLoading={isLoading}>
                  {locale?.strings?.create}
                </LoadingButton>
              </div>
            </Footer>
          </form>
        )}
      </Form>
    </>
  );
};

export default AddLeave;
