import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useReduxState } from 'hooks/useRedux';
import moment from 'moment';
import * as SlotService from 'services/slot.service';
import { capafield, capafieldResponse } from 'types/capafield';
import CapaField, {
  CapaFieldWithOnBoardingTutorial,
} from './capa-field/CapaField';
import { SumField } from '../sum-field/SumField';
import { User } from 'models/ProjectModel';
import { getSprintCapacity } from 'store/slice/sprintCapacity/';
import { sprintCapacityInterface } from 'store/slice/sprintCapacity/sprintCapacity.slice';
import { activeSprintIndexCalculate } from 'utils/getActiveSprint';
import './CapaFields.scss';

export interface CapaFieldsProps {
  sprintLength: number;
  sprintStart: moment.Moment;
  user2projectId: number;
  user: User;
  sprintId: number;
  projectLink: string;
  disabledCapaFields: boolean;
  irrelevantCapa: boolean;
  sprintIndex: number;
  userIndex: number;
}

// export interface CapaFieldsState {
//   capaFields: capafield[];
// }

// const generateCapafields = (sprintLengthInWeeks: number): capafield[] => {
//   const length = sprintLengthInWeeks * 14;
//   const capaFields: capafield[] = [];
//   for (let i = 0; i < length; i++) {
//     capaFields.push({
//       id: i,
//       capacity: 2,
//       apiId: -1,
//       timeStamp: null,
//       subevent: [],
//     });
//   }
//   return capaFields;
// };

const CapaFields: React.FC<CapaFieldsProps> = (props) => {
  const { disabledCapaFields, irrelevantCapa, sprintId, sprintIndex } = props;

  const {
    sprintCapacity,
    project: { events, sprints },
  } = useReduxState();

  const sprintCapa = sprintCapacity.sprints.find(
    (e: sprintCapacityInterface) => e?.sprintId === sprintId
  );

  const userSlots = sprintCapa?.userSlots.find(
    (us) => us.userId === props.user2projectId
  );

  const userCapa = sprintCapa?.users.find((user) => user.id === props.user.id)
    ?.capacity || { SP: 0, PT: 0 };

  const dateToIds = (timeStamp: moment.Moment): number => {
    const difference = timeStamp.diff(props.sprintStart, 'days');

    if (timeStamp.format('A') === 'AM') {
      return difference * 2;
    } else {
      return difference * 2 + 1;
    }
  };

  const newCapaFields: capafield[] = userSlots?.slots.reduce(
    (total: capafield[], capafield: capafieldResponse): capafield[] => {
      const timeStamp: moment.Moment = moment(capafield.time).utc();
      const newCapaId: number = dateToIds(timeStamp);

      const test = capafield.events.map((subevent) => {
        const findEventDetails = events.find((ev) =>
          ev.subevent.find((sb) => sb.id === subevent.id)
        );

        if (findEventDetails) {
          return { ...subevent, event: findEventDetails };
        }
        return subevent;
      });

      const newCapa: capafield = {
        id: newCapaId,
        capacity: capafield.capa,
        apiId: capafield.id,
        timeStamp: timeStamp,
        subevent: test,
      };

      total[newCapaId] = newCapa;
      return total;
    },
    []
  );

  const dispatch = useDispatch();

  const changeCapacity = async (capafield: capafield, isEvent = false) => {
    const id = capafield.id;
    const capacity = capafield.capacity;
    const allowedCapa = isEvent ? [1, 2] : [0, 1, 2, 3]; // do not allow 0 and 1 value for slots having events

    const capaIndex = allowedCapa.findIndex((el) => el === capacity);

    if (capaIndex === -1) {
      if (capacity !== 1 && capacity !== 0 && !isEvent) return;
      // unknown capa values
    }
    const newCapa = allowedCapa[(capaIndex + 1) % allowedCapa.length];

    newCapaFields[id].capacity = newCapa;

    const slotData: SlotService.slotData = {
      capacity: newCapaFields[id].capacity,
      id: newCapaFields[id].apiId,
      projectLink: props.projectLink,
    };
    await SlotService.putSlot(slotData);

    dispatch(
      getSprintCapacity({
        sprintId: props?.sprintId,
        projectLink: props?.projectLink,
      })
    );
  };

  const activeSprintIndex = activeSprintIndexCalculate(sprints, new Date());

  return (
    <React.Fragment>
      <div className="capaFields">
        {newCapaFields?.map((capafield) =>
          props.sprintIndex === activeSprintIndex && props.userIndex === 1 ? (
            <CapaFieldWithOnBoardingTutorial
              disabledCapa={disabledCapaFields}
              changeCapacity={changeCapacity}
              capafield={capafield}
              sprintIndex={sprintIndex}
              key={capafield.id}
            />
          ) : (
            <CapaField
              disabledCapa={disabledCapaFields}
              changeCapacity={changeCapacity}
              capafield={capafield}
              sprintIndex={sprintIndex}
              key={capafield.id}
            />
          )
        )}
      </div>
      <SumField
        capaSum={userCapa}
        irrelevantCapa={irrelevantCapa}
        sprintId={props.sprintId}></SumField>
    </React.Fragment>
  );
};

export default CapaFields;
