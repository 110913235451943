import axios from 'axios';
import { environment } from 'utils/environment';
import { getSlotsResponse, PutSlotResponse } from 'models/ResponseModel';

export interface GetSlotsArgs {
  user2projectId: number;
  sprintId: number;
  projectLink: string;
}
export const getSlots = async (args: GetSlotsArgs) => {
  const { data } = await axios.get<getSlotsResponse>(
    `${environment.API_BASE_URL}user/${args.user2projectId}/${args.sprintId}?projectLink=${args.projectLink}`
  );
  return data;
};

export type slotData = {
  capacity: number;
  id: number;
  projectLink: string;
};

export const putSlot = async (body: slotData) => {
  const { data } = await axios.put<PutSlotResponse>(
    `${environment.API_BASE_URL}slot/${body.id}?projectLink=${body.projectLink}`,
    JSON.stringify({
      capa: body.capacity,
    }),
    {
      headers: {
        'Content-Type': 'application/json',
        responseType: 'json',
      },
    }
  );
  return data;
};
