import CreateSprintModel from 'models/CreateSprintModel';
import { createAsyncThunk } from '@reduxjs/toolkit';
import store from 'store';
import * as sprintService from 'services/sprint.service';
import { next } from '../createProjectOnboarding/createProjectOnboarding.slice';
import { error as globalError } from '../globalError/globaleError.slice';
import { create, error, loading } from './createSprint.slice';

export type SprintData = {
  intervalTime: { label: string; value: number };
  sprintStartDate: string;
  projectId: number | null;
  mailToken: string;
};

export const createSprint = createAsyncThunk(
  'sprint/create',
  async (args: SprintData) => {
    try {
      store.dispatch(error(false));
      store.dispatch(loading(true));
      const data = await sprintService.postSprint(args);
      const sprint: CreateSprintModel = {
        intervalTime: data.sprints[0].intervalTime,
        sprintStartDate: data.sprints[0].sprintStartDate,
      };
      store.dispatch(create(sprint));
      store.dispatch(next(true));
    } catch (err: any) {
      store.dispatch(error(true));
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);
