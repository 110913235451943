import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { App } from './App';
import { Provider } from 'react-redux';
import store from 'store';
import { environment } from 'utils/environment';
import { ENV_TYPES } from 'types/environment';
import TagManager from 'react-gtm-module';

declare global {
  interface Window {
    dataLayer: any;
  }
}

(function overrideBrowserConsoles(console: Console) {
  const overriden: any = {};
  if (environment.NODE_ENV === ENV_TYPES.PRODUCTION) {
    for (const key of Object.keys(console)) {
      overriden[key] = () => {
        // do nothing
      };
    }
    // override console object for all keys(log,info,error,...) for production
    // so that no outputs are displayed in the window console
    window.console = overriden;
  }
})(window.console);

// initialize google tag manager
const tagManagerOptions = {
  gtmId: environment.GTM_ID,
};
TagManager.initialize(tagManagerOptions);
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
