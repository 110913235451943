export enum onBoardingTutorial {
  CREATENEWUSER = 'new-user',
  ADDNEWMEMBER = 'add-new-team-member',
  CREATEEVENT = 'create-event',
  CREATEHASHTAG = 'create-hashtag',
  HASHTAGOVERVIEW = 'hashtag-overview',
  SPRINTGOAL = 'sprint-goal',
  TICKETREFERENCE = 'ticket-reference',
  FEEDBACK = 'feedback',
  UPDATE_CAPACITY = 'update-slot-capacity',
}
