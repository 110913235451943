import React, { Fragment, useEffect, useState } from 'react';
import { TimePicker, DatePicker } from '@atlaskit/datetime-picker';
import Select from '@atlaskit/select';
import Toggle from '@atlaskit/toggle';
import Button from '@atlaskit/button';
import { ModalBackDrop, Modal, Header, Body, Footer } from 'lib/Modal';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { parseISO } from 'date-fns';
import { CheckboxSelect } from '@atlaskit/select';
import { LoadingButton } from '@atlaskit/button';
import { useReduxState } from 'hooks/useRedux';
import { getSprintEndDate, getXDaysBeforeDate } from 'utils/date';
import ParticipantOption, { filterOptions } from './Participants';
import {
  useParticipantsOptions,
  useRecurringIntervalOptions,
  filterCalenderDate,
  getValidationState,
  validate,
  IFormValues,
} from './EventUtils';
import { createEvent } from 'store/slice/project';
import { useDispatch } from 'react-redux';
import '../ModalStyle.scss';

interface Props {
  close: () => void;
  sprintStartDate?: string;
  intervalTime?: number;
}

const EventModal: React.FC<Props> = (props) => {
  const { close, sprintStartDate, intervalTime } = props;

  const { locale } = useReduxState();

  const [isLoading, setIsLoading] = useState(false);

  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState<string>('');
  useEffect(() => {
    checked ? setValue('true') : setValue('');
  }, [checked]);

  const handleClick = () => {
    setChecked(!checked);
  };

  const dispatch = useDispatch();

  const onSubmit = async (values: IFormValues) => {
    try {
      const startTime = values?.eventStartTime.split(':').map((e) => Number(e));
      const endTime = values?.eventEndTime.split(':').map((e) => Number(e));
      const date = new Date(
        new Date(values?.eventStartDate).setHours(startTime[0])
      ).setMinutes(startTime[1]);
      const duration =
        (endTime[0] - startTime[0]) * 60 + (endTime[1] - startTime[1]); // minutes
      const eventObject = {
        name: values?.name,
        abbreviation: values?.abbreviation,
        recurring: values?.recurring ? true : false,
        interval: values?.recurring ? values?.interval?.value : undefined,
        duration,
        time: new Date(date).toISOString(),
        users: values?.participants.map((user) => {
          return user.value;
        }),
        link: values?.link,
      };
      setIsLoading(true);
      await dispatch(createEvent(eventObject));
      setIsLoading(false);
      close();
    } catch (err) {
      console.log({ err });
    }
  };

  const recurringIntervalOptions = useRecurringIntervalOptions();
  const { OPTIONS: userIntervalOptions, defaultSelectedParticipant } =
    useParticipantsOptions();

  return (
    <Modal onClose={close}>
      <ModalBackDrop>
        <Header>
          <h3>{locale?.strings?.create_event}</h3>
        </Header>
        <Form onSubmit={onSubmit}>
          {({ formProps }) => (
            <form {...formProps}>
              <Body>
                <div className="form-fields">
                  <Field
                    isRequired
                    label={locale?.strings?.event_name}
                    name="name"
                    defaultValue=""
                    validate={(value) =>
                      value && value.length < 4
                        ? locale?.strings?.validation_min_characters_error.replace(
                            ':n',
                            '4'
                          )
                        : value && /^\S.*/.test(value) !== true
                        ? locale?.strings?.validation_no_space
                        : value && value.length > 20
                        ? locale?.strings?.validation_max_characters_error.replace(
                            ':n',
                            '20'
                          )
                        : undefined
                    }>
                    {({ fieldProps, error }) => (
                      <Fragment>
                        <Textfield {...fieldProps} />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </Fragment>
                    )}
                  </Field>

                  <Field
                    isRequired
                    label={locale?.strings?.event_short_code}
                    name="abbreviation"
                    defaultValue=""
                    validate={(value) =>
                      value && value.length < 2
                        ? locale?.strings?.validation_min_characters_error.replace(
                            ':n',
                            '2'
                          )
                        : value && /^\S.*/.test(value) !== true
                        ? locale?.strings?.validation_no_space
                        : value && value.length > 5
                        ? locale?.strings?.validation_max_characters_error.replace(
                            ':n',
                            '5'
                          )
                        : undefined
                    }>
                    {({ fieldProps, error }) => (
                      <Fragment>
                        <Textfield {...fieldProps} />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </Fragment>
                    )}
                  </Field>
                  <Field
                    isRequired
                    label={locale?.strings?.event_participants}
                    name="participants"
                    validate={validate()}>
                    {({ fieldProps, error, meta: { valid } }: any) => (
                      <Fragment>
                        <CheckboxSelect
                          {...fieldProps}
                          isMulti
                          defaultValue={[defaultSelectedParticipant]}
                          getOptionValue={filterOptions}
                          formatOptionLabel={ParticipantOption}
                          options={userIntervalOptions}
                          placeholder={locale?.strings?.event_participants}
                          validationState={getValidationState(error, valid)}
                          shouldFitContainer
                        />
                        {error === 'EMPTY' && (
                          <ErrorMessage>
                            {locale?.strings?.validation_participant_min_error.replace(
                              ':n',
                              '2'
                            )}
                          </ErrorMessage>
                        )}
                      </Fragment>
                    )}
                  </Field>
                  <Field
                    isRequired
                    label={locale?.strings?.recurring_event}
                    name="recurring"
                    defaultValue={value}>
                    {({ fieldProps }) => (
                      <Fragment>
                        <Toggle {...fieldProps} onChange={handleClick} />
                      </Fragment>
                    )}
                  </Field>

                  {checked ? (
                    <Field
                      isRequired
                      label={locale?.strings?.event_recurring_interval}
                      name="interval"
                      validate={(value, state: any) => {
                        if (state?.recurring) {
                          return validate()(value);
                        }
                        return undefined;
                      }}>
                      {({ fieldProps, error, meta: { valid } }: any) => {
                        return (
                          <Fragment>
                            <Select
                              {...fieldProps}
                              placeholder={
                                locale?.strings?.event_recurring_interval
                              }
                              options={recurringIntervalOptions}
                              validationState={getValidationState(error, valid)}
                            />
                            {error === 'EMPTY' && (
                              <ErrorMessage>
                                {locale?.strings?.validation_iteration_length}
                              </ErrorMessage>
                            )}
                          </Fragment>
                        );
                      }}
                    </Field>
                  ) : null}

                  <div className="form-row">
                    <div className="form-row__col">
                      <Field
                        isRequired
                        label={locale?.strings?.event_date}
                        defaultValue=""
                        name="eventStartDate"
                        validate={(value) =>
                          !value
                            ? locale?.strings?.validation_invalid_date
                            : filterCalenderDate(value)
                            ? locale?.strings?.validation_invalid_date
                            : undefined
                        }>
                        {({ fieldProps, error }) => (
                          <Fragment>
                            {sprintStartDate && intervalTime ? (
                              <DatePicker
                                {...fieldProps}
                                placeholder={new Date().toLocaleDateString()}
                                minDate={getXDaysBeforeDate(sprintStartDate, 1)}
                                maxDate={getSprintEndDate(
                                  sprintStartDate,
                                  intervalTime
                                )}
                                disabledDateFilter={filterCalenderDate}
                                weekStartDay={1}
                              />
                            ) : (
                              <DatePicker
                                {...fieldProps}
                                placeholder={locale?.strings?.date_placeholder}
                                dateFormat={locale?.strings?.date_format}
                                parseInputValue={(date: string) =>
                                  parseISO(date)
                                }
                                disabledDateFilter={filterCalenderDate}
                                weekStartDay={1}
                              />
                            )}
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </Fragment>
                        )}
                      </Field>
                    </div>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <div className="form-row__col">
                      <Field
                        isRequired={true}
                        label={locale?.strings?.event_start_time}
                        name="eventStartTime"
                        defaultValue="9:00">
                        {({ fieldProps, error }) => (
                          <Fragment>
                            <TimePicker
                              placeholder="09:00"
                              timeFormat="HH:mm"
                              timeIsEditable
                              {...fieldProps}
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </Fragment>
                        )}
                      </Field>
                    </div>

                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <div className="form-row__col">
                      <Field
                        isRequired={true}
                        label={locale?.strings?.event_end_time}
                        name="eventEndTime"
                        defaultValue={'11:00'}
                        validate={(value, state: any) => {
                          const startArray = (
                            state?.eventStartTime
                              ? state?.eventStartTime
                              : '0:0'
                          )
                            .split(':')
                            .map((t: string) => Number(t || 0));
                          const endArray = (value ? value : '0:0')
                            .split(':')
                            .map((t: string) => Number(t || 0));
                          const isTimeValid =
                            endArray[0] > startArray[0] ||
                            (endArray[0] === startArray[0] &&
                              endArray[1] > startArray[1]);
                          const duration =
                            (endArray[0] - startArray[0]) * 60 +
                            (endArray[1] - startArray[1]); // minutes
                          const isDurationValid =
                            state.interval?.value === 1
                              ? duration >= 15 && duration <= 240 // 15 mins- 4hour
                              : duration >= 60 && duration <= 480; // 1hour - 8hour
                          return !value
                            ? locale?.strings?.validation_invalid_time
                            : !isTimeValid
                            ? locale?.strings?.validation_end_time_error
                            : !isDurationValid && state.interval?.value === 1
                            ? locale?.strings
                                ?.validation_event_duration_range_error_for_dailys
                            : !isDurationValid
                            ? locale?.strings
                                ?.validation_event_duration_range_error
                            : undefined;
                        }}>
                        {({ fieldProps, error }) => (
                          <Fragment>
                            <TimePicker
                              placeholder="11:00"
                              timeFormat="HH:mm"
                              timeIsEditable
                              {...fieldProps}
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </Fragment>
                        )}
                      </Field>
                    </div>
                  </div>

                  <Field
                    label={locale?.strings?.event_link}
                    name="link"
                    defaultValue=""
                    validate={(value) =>
                      value &&
                      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
                        value
                      ) !== true
                        ? locale?.strings?.validation_url
                        : undefined
                    }>
                    {({ fieldProps, error }) => (
                      <Fragment>
                        <Textfield {...fieldProps} />

                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </Fragment>
                    )}
                  </Field>
                </div>
              </Body>
              <Footer>
                <span></span>
                <div>
                  <Button className="secondary-btn" onClick={close}>
                    {locale?.strings?.cancel}
                  </Button>
                  <LoadingButton
                    appearance="primary"
                    type="submit"
                    isLoading={isLoading}>
                    {locale?.strings?.create}
                  </LoadingButton>
                </div>
              </Footer>
            </form>
          )}
        </Form>
      </ModalBackDrop>
    </Modal>
  );
};

export default EventModal;
