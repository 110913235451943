import React, { MouseEventHandler } from 'react';
import ReactPortal from 'lib/Portal';
import Button from '@atlaskit/button';
import './Alert.scss';
import { Warning, Danger } from 'assets/icons/icons';

export interface Actions {
  appearance?: 'warning' | 'danger' | undefined;
  text: string;
  onClick: MouseEventHandler<Element> | undefined;
}

const ICONS_OPTIONS = {
  warning: <Warning />,
  danger: <Danger />,
};
interface Props {
  heading: string;
  actions: Actions[];
  onClose: (...args: any) => void;
  appearance: 'warning' | 'danger' | undefined;
}

const renderButtonGroups = (buttons: Actions[]) => {
  return buttons?.map((btn, index) => {
    const props = btn.appearance ? { appearance: btn.appearance } : {};

    return (
      <Button key={index} {...props} onClick={btn.onClick}>
        {btn.text}
      </Button>
    );
  });
};

const Alert: React.FC<Props> = (props) => {
  React.useEffect(() => {
    // unset overflow effect on modal popup (mounting modal)
    document.body.style.overflow = 'hidden';

    // remove the overflow effect (unmounting modal)
    return function unsetOverflow() {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <ReactPortal>
      <div className="ct-alert">
        <div className="ct-alert__backdrop" onClick={props.onClose}></div>
        <div className="ct-alert-wrapper">
          <div className="ct-alert__header">
            <span>{props.appearance && ICONS_OPTIONS[props.appearance]}</span>
            {props.heading}
          </div>
          <div className="ct-alert__body">{props.children}</div>
          <div className="ct-alert__footer">
            {renderButtonGroups(props.actions)}
          </div>
        </div>
      </div>
    </ReactPortal>
  );
};

export default Alert;
