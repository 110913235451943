import Avatar from './UserAvatar';
import UserDropDown from './UserDropdown';
import { IAvatarUserGroup } from 'utils/interfaces';
import './UserAvatar.scss';

const USER_LIMIT = 5;

const UserAvatarGroup: React.FC<IAvatarUserGroup> = ({ data }) => {
  const AvatarList = data.slice(0, USER_LIMIT + 1);
  const DropDownList = data.slice(USER_LIMIT);
  return (
    <div className="c-avatar c-avatar--group">
      <Avatar data={AvatarList} />
      {DropDownList.length > 0 && <UserDropDown data={DropDownList} />}
    </div>
  );
};

export default UserAvatarGroup;
