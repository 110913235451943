import axios from 'axios';
import { environment } from 'utils/environment';
import {
  CreateProjectResponse,
  FetchProjectResponse,
} from 'models/ResponseModel';
import { Event, User } from 'models/ProjectModel';

export type ProjectData = {
  companyName: string;
  projectName: string;
  username: string;
  email: string;
  voucher: string;
};

export const postProject = async (body: ProjectData) => {
  const { data } = await axios.post<CreateProjectResponse>(
    `${environment.API_BASE_URL}project`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
        responseType: 'json',
      },
    }
  );
  return data;
};

export interface GetProjectArgs {
  projectLink: string;
  projectId: number;
}

export const getProject = async (args: GetProjectArgs) => {
  const { data } = await axios.get<FetchProjectResponse>(
    `${environment.API_BASE_URL}project/${args.projectId}?projectLink=${args.projectLink}`
  );
  return data;
};

export const getProjectUsers = async (args: GetProjectArgs) => {
  const { data } = await axios.get<User[]>(
    `${environment.API_BASE_URL}project/${args.projectId}/users?projectLink=${args.projectLink}`
  );
  return data;
};

export const getProjectEvents = async (args: GetProjectArgs) => {
  const { data } = await axios.get<Event[]>(
    `${environment.API_BASE_URL}project/${args.projectId}/events?projectLink=${args.projectLink}`
  );
  return data;
};

export interface EditProjectReferenceArgs {
  projectId: number;
  projectLink: string;
  sprintReference: string;
}
export const updateProjectSprintReference = async (
  args: EditProjectReferenceArgs
) => {
  const { data } = await axios.put<FetchProjectResponse>(
    `${environment.API_BASE_URL}project/${args.projectId}?projectLink=${args.projectLink}`,
    { sprintReference: args.sprintReference },
    {
      headers: {
        'Content-Type': 'application/json',
        responseType: 'json',
      },
    }
  );

  return data;
};
