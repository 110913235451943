import moment from 'moment';
import React, { Fragment } from 'react';
import { Sprint, User } from 'models/ProjectModel';
import CapaFields from '../capa-fields/CapaFields';
import SprintCard from '../sprint-card/SprintCard';
import Weekdays from '../weekdays/Weekdays';
import IntroBlock from 'components/IntroBlock/IntroBlock';
import IntroBlockNoCapacities from 'components/IntroBlockNoCapacities/IntroBlockNoCapacities';
import { useReduxState } from 'hooks/useRedux';

interface SprintWrapperProps {
  sprintIndex: number;
  projectLink: string;
  projectName: string;
  sprint: Sprint;
  users: User[];
  disabledSprint: boolean;
  showIntroBlock: boolean;
}

const SprintWrapper: React.FC<SprintWrapperProps> = (props) => {
  const {
    projectLink,
    projectName,
    sprint,
    users,
    sprintIndex,
    disabledSprint,
    showIntroBlock,
  } = props;

  const { filteredHashTags } = useReduxState();

  const hasUser = users?.some((e: User) => e?.role?.type === 'user');

  const renderCapaforEachUser = () => {
    const userLists = [...users];

    const hashTagsList = filteredHashTags.hashTags.map((ht) => ht.id);

    return userLists.map((user: User, userIndex: number) => {

      if (user.role.type !== 'user') return null;

      if (
        hashTagsList.length > 0 &&
        !user.hashTags.some((ht) => hashTagsList.includes(ht.id))
      )
        return null;

      const irrelevant =
        user.sprints?.[sprintIndex]?.capacontribution?.relevant === false;

      const disabled = disabledSprint || Boolean(user?.deletedDate);

      return (
        <div key={userIndex}>
          <CapaFields
            irrelevantCapa={irrelevant}
            disabledCapaFields={disabled}
            sprintLength={Number(sprint.intervalTime)}
            sprintStart={moment(sprint.sprintStartDate)}
            user2projectId={user.id}
            user={user}
            sprintId={sprint.id}
            sprintIndex={sprintIndex}
            userIndex={userIndex}
            projectLink={projectLink}></CapaFields>
        </div>
      );
    });
  };

  const renderCapaFields = () => {
    if (hasUser) {
      return (
        <div className="main__project-content">
          <div className="main__team-member">
            <div className="plan">{renderCapaforEachUser()}</div>
          </div>
        </div>
      );
    }

    if (showIntroBlock) return <IntroBlock />;
    return <IntroBlockNoCapacities />;
  };

  return (
    <Fragment>
      <div className="main__sprint">
        <div className="main__project-dtl">
          <div className="sprintCard">
            <SprintCard
              sprintGoal={sprint.sprintGoal}
              sprintId={sprint.id}
              projectLink={projectLink}
              sprintIndex={sprintIndex}
              projectName={projectName + ' ' + (sprintIndex + 1)}
              sprintStartDate={sprint.sprintStartDate}
              intervalTime={Number(sprint.intervalTime)}></SprintCard>
          </div>
          <div className="week">
            <Weekdays
              sprintLength={Number(sprint.intervalTime)}
              sprintStart={moment(sprint.sprintStartDate)}></Weekdays>
          </div>
        </div>
        {renderCapaFields()}
      </div>
    </Fragment>
  );
};

export default SprintWrapper;
