import * as React from 'react';
import './ProjectNotFound.scss';

export const ProjectNotFound: React.FC = () => {
  return (
    <div className="error-img-container">
      <div className="error-img-container__img"></div>
    </div>
  );
};
