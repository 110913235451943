import { LoadingButton } from '@atlaskit/button';
import EditorFeedbackIcon from '@atlaskit/icon/glyph/editor/feedback';
import {
  default as ModalDialog,
  ModalFooter,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import TextArea from '@atlaskit/textarea';
import Textfield from '@atlaskit/textfield';
import React, { useState, Fragment } from 'react';
import * as FeedbackService from 'services/feedback.service';
import styled from 'styled-components';
import EmojiIcon from '@atlaskit/icon/glyph/emoji';
import Icon from '@atlaskit/icon';
import { RadioGroup } from '@atlaskit/radio';
import { useReduxState } from 'hooks/useRedux';
import Form, { Field, ErrorMessage } from '@atlaskit/form';
import { EMOJI_LIST, SUGGESTIONS } from 'utils/feedback';

import './FeedbackButton.scss';

const FeedbackButton: React.FC = () => {
  const {
    project: {
      project: {
        projectLink: { link: projectLink },
      },
    },
    locale,
  } = useReduxState();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [emojiValue, setEmojiValue] = useState<number>(0);

  const [showInfo, setShowInfo] = useState<boolean>(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    setShowInfo(false);
  };

  const onFormSubmit = async (data: FeedbackService.postFeedbackBody) => {
    const args: FeedbackService.postFeedbackArgs = {
      body: data,
      projectLink,
    };

    try {
      setLoading(true);
      await FeedbackService.postFeedback(args);
      setIsOpen(false);
      setShowInfo(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const onEmojiClick = (value: number) => {
    setEmojiValue(value);
  };

  const renderEmojis = () => {
    return EMOJI_LIST.map((item: any) => {
      return (
        <div
          key={item?.label}
          className={`feedback__emoji-item  ${
            emojiValue === item?.value ? 'checked' : ''
          } `}
          onClick={() => onEmojiClick(item?.value)}>
          <Icon glyph={item?.component} label={item?.label} size="large" />
        </div>
      );
    });
  };

  return (
    <div>
      <div className="feedback">
        <div className="feedback-container">
          <div className="feedback-container-button" onClick={() => open()}>
            <p>{locale?.strings?.feedback} </p>
            <EditorFeedbackIcon label="" size="medium"></EditorFeedbackIcon>
          </div>
        </div>
      </div>

      <ModalTransition>
        {/* Modal to create a User */}

        {isOpen && (
          <ModalDialog
            heading={locale.strings.you_have_feedback}
            onClose={close}>
            <Form onSubmit={onFormSubmit}>
              {({ formProps }) => (
                <form {...formProps}>
                  <div className="feedback-block">
                    <div className="feedback__item">
                      <h5>
                        {locale.strings.how_satisfied_with_capa}{' '}
                        <span className="feedback__logo">capa.team</span>?
                      </h5>

                      <Field
                        name="rating"
                        defaultValue={emojiValue}
                        validate={(value) =>
                          value && value > 0
                            ? undefined
                            : locale.strings.please_select_one
                        }>
                        {({ error }) => (
                          <Fragment>
                            <div className="feedback__emoji">
                              {renderEmojis()}
                            </div>
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </Fragment>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="feedback__item">
                    <Field
                      label={locale.strings.specific_topic_for_feedback}
                      name="topic"
                      defaultValue=""
                      isRequired>
                      {({ fieldProps }) => (
                        <RadioGroup {...fieldProps} options={SUGGESTIONS} />
                      )}
                    </Field>
                  </div>
                  <Field
                    label={locale.strings.your_contact_email}
                    name="userEmail"
                    isRequired
                    defaultValue=""
                    validate={(value) =>
                      value &&
                      //FIXME: Validator in Funktion auslagern --> No-useless-escape
                      // RegEx from -> https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
                      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                        value
                      ) !== true
                        ? locale.strings.validation_email
                        : undefined
                    }>
                    {({ fieldProps, error }) => (
                      <Fragment>
                        <Textfield type="email" {...fieldProps} />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </Fragment>
                    )}
                  </Field>
                  <Field<string, HTMLTextAreaElement>
                    name="text"
                    defaultValue=""
                    label={locale.strings.please_provide_detail_for_topic}
                    isRequired
                    validate={(value) =>
                      value && value.length < 10
                        ? locale.strings.validation_too_short
                        : undefined
                    }>
                    {({ fieldProps, error }) => (
                      <Fragment>
                        <TextArea
                          placeholder={
                            locale.strings.please_provide_feedback_placeholder
                          }
                          {...fieldProps}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </Fragment>
                    )}
                  </Field>

                  <ModalFooter className="modalFooter">
                    <span />
                    <LoadingButton
                      appearance="primary"
                      type="submit"
                      isLoading={loading}>
                      {locale?.strings?.button_send}
                    </LoadingButton>
                  </ModalFooter>
                </form>
              )}
            </Form>
          </ModalDialog>
        )}

        {showInfo && (
          <ModalDialog
            heading={locale?.strings?.feedback_thank_you}
            onClose={close}>
            <Box className="">
              <EmojiIcon
                label="SmileIcon"
                size="xlarge"
                primaryColor="#FF991F"
              />
              <h2>{locale?.strings?.feedback_success_info}</h2>
            </Box>
            <ModalFooter>
              <span />
              <LoadingButton appearance="primary" onClick={close}>
                {locale?.strings?.button_close}
              </LoadingButton>
            </ModalFooter>
          </ModalDialog>
        )}
      </ModalTransition>
    </div>
  );
};

export default FeedbackButton;
const Box = styled.div`
  text-align: center;
  padding: 2rem 0 2rem;

  h2 {
    font-size: 18px;
    font-weight: 500;
    margin: 20px 0;
  }
`;
