import store from 'store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { error as globalError } from '../globalError/globaleError.slice';
import * as ProjectModel from 'models/ProjectModel';
import * as ProjectService from 'services/project.service';
import * as PreferenceService from 'services/preference.service';
import * as SprintService from 'services/sprint.service';
import * as HashtagService from 'services/hashtag.service';
import * as offDayService from 'services/offDay.service';

import * as UserService from 'services/user.service';
import {
  initialState,
  update,
  error,
  loading,
  updateProjectWithItsUser,
  updateProjectWithItsEvents,
  updateProjectWithItsSprints,
  updateProjectWithItsHashTags,
  updateProjectWithItsOffDays,
} from './project.slice';
import * as CapacityService from 'services/capacity.service';
import * as EventService from 'services/event.service';
import {
  update as sprintCapacityUpdate,
  sprintCapacityInterface,
} from '../sprintCapacity/sprintCapacity.slice';
import { cloneDeep } from 'lodash';
import { SubEvent } from 'components/Modals/EventDetailModal/Info';

import { update as sprintEfficiencyAndCalculationUpdate } from '../sprintEfficiencyAndCalculation/sprintEfficiencyAndCalculation.slice';

export { initialState, update, error, loading };

export const getProjectDetails = createAsyncThunk(
  'project/fetch',
  async (args: ProjectService.GetProjectArgs) => {
    try {
      store.dispatch(error(false));
      store.dispatch(loading(true));
      const data = await ProjectService.getProject(args);
      // find the relevant project link of the user from response
      const link = data.projectLinks.find((item) => {
        return item.link === args.projectLink;
      });

      const project: ProjectModel.Project = {
        id: data.id,
        companyName: data.companyName,
        projectName: data.projectName,
        capacityMethode: data?.methode,
        sprintReference: data?.sprintReference,
        projectLink: link as ProjectModel.ProjectLink,
      };
      store.dispatch(update(project));
      store.dispatch(updateProjectWithItsSprints(data.sprints));

      if (data.hashTags) {
        store.dispatch(updateProjectWithItsHashTags(data.hashTags));
      }
    } catch (err: any) {
      store.dispatch(error(true));
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const getProjectUsers = createAsyncThunk(
  'project/fetch/users',
  async (args: ProjectService.GetProjectArgs) => {
    try {
      const data = await ProjectService.getProjectUsers(args);
      const users = data;

      store.dispatch(updateProjectWithItsUser(users));
    } catch (err: any) {
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);

export const getProjectEvents = createAsyncThunk(
  'project/fetch/users',
  async (args: ProjectService.GetProjectArgs) => {
    try {
      const data = await ProjectService.getProjectEvents(args);
      const events = data;

      store.dispatch(updateProjectWithItsEvents(events));
    } catch (err: any) {
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);

export const addUser = createAsyncThunk(
  'project/user/add',
  async (args: UserService.UserData) => {
    try {
      const sprintCapacity: sprintCapacityInterface[] = [];
      Object.assign(
        sprintCapacity,
        store.getState().global.sprintCapacity.sprints
      );
      const prevData = [...store.getState().global.project.users];

      store.dispatch(error(false));
      store.dispatch(loading(true));
      const data = await UserService.postUser(args);

      prevData.push(data);
      store.dispatch(updateProjectWithItsUser(prevData));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const addSprint = createAsyncThunk('project/sprint/add', async () => {
  try {
    const project: ProjectModel.Project = {
      ...store.getState().global.project.project,
    };

    store.dispatch(error(false));
    store.dispatch(loading(true));
    const data = await SprintService.addSprint({
      projectId: project.id,
      projectLink: project.projectLink.link,
    });

    store.dispatch(updateProjectWithItsSprints(data.sprints));
    store.dispatch(updateProjectWithItsEvents(data.events));
  } catch (err: any) {
    if (err.response) {
      store.dispatch(
        globalError(err.response.data.error || err.response.statusText)
      );
      throw err;
    }
    if (err.request) {
      store.dispatch(globalError('Server antwortet nicht!'));
    }
  } finally {
    store.dispatch(loading(false));
  }
});

export const deleteUser = createAsyncThunk(
  'project/user/delete',
  async (args: UserService.DeleteUserArgs) => {
    try {
      const sprintCapacity: sprintCapacityInterface[] = [];
      Object.assign(
        sprintCapacity,
        store.getState().global.sprintCapacity.sprints
      );

      store.dispatch(error(false));
      store.dispatch(loading(true));

      const data = await UserService.deleteUser(args);

      const users = cloneDeep(store.getState().global.project.users);
      const index = users?.findIndex((e: any) => e?.id === args?.userId);

      if (index > -1) {
        users[index].deletedDate = data.deletedDate;
        store.dispatch(updateProjectWithItsUser(users));
      }
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const updateSprint = createAsyncThunk(
  'project/sprint/update',
  async (args: SprintService.AddSprintInfoArgs) => {
    try {
      const data = await SprintService.addSprintInfo(args);
      const sprints: ProjectModel.Sprint[] = [
        ...store.getState().global.project.sprints,
      ];

      const index = sprints?.findIndex((el: any) => el?.id === args.sprintId);

      if (index > -1) {
        sprints[index] = data;
      }

      store.dispatch(updateProjectWithItsSprints(sprints));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || 'Server antwortet nicht!')
        );
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);

export const updateSprintCalculationTable = createAsyncThunk(
  'project/sprint/updateCalculationTable',
  async (args: SprintService.UpdateCalculationTableArgs) => {
    try {
      const project = store.getState().global.project.project;

      const data = await SprintService.updateCalculationTable({
        ...args,
        projectId: project.id,
        projectLink: project.projectLink.link,
      });
      const sprints: ProjectModel.Sprint[] = [
        ...store.getState().global.project.sprints,
      ];

      const index = sprints?.findIndex((el: any) => el?.id === args.sprintId);

      if (index > -1) {
        sprints[index] = data;
      }

      store.dispatch(updateProjectWithItsSprints(sprints));

      const updatedEffeciency =
        await SprintService.getSprintEfficiencyAndCalculation({
          projectLink: project.projectLink.link,
          projectId: project.id,
        });

      store.dispatch(sprintEfficiencyAndCalculationUpdate(updatedEffeciency));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || 'Server antwortet nicht!')
        );
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);

export const updateSprintCoveredCapacity = createAsyncThunk(
  'project/sprint/updateCoveredCapacity',
  async (args: SprintService.UpdateSprintInfoArgs) => {
    try {
      const data = await SprintService.updateCoveredCapacity(args);
      const sprints: ProjectModel.Sprint[] = [
        ...store.getState().global.project.sprints,
      ];

      const projectId = store.getState().global.project.project.id;

      const index = sprints?.findIndex((el: any) => el?.id === args.sprintId);

      if (index > -1) {
        sprints[index] = data;
      }

      store.dispatch(updateProjectWithItsSprints(sprints));

      const updatedEffeciency =
        await SprintService.getSprintEfficiencyAndCalculation({
          projectLink: args.projectLink,
          projectId,
        });

      store.dispatch(sprintEfficiencyAndCalculationUpdate(updatedEffeciency));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || 'Server antwortet nicht!')
        );
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);

export const updateSprintReference = createAsyncThunk(
  'project/sprint/update-ref',
  async (sprintReference: string) => {
    try {
      const project: ProjectModel.Project = {
        ...store.getState().global.project.project,
      };

      const updatedProject = await ProjectService.updateProjectSprintReference({
        projectId: project?.id,
        projectLink: project?.projectLink?.link,
        sprintReference,
      });

      project.sprintReference = updatedProject?.sprintReference;
      store.dispatch(update(project));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || 'Server antwortet nicht!')
        );
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);

export const editUser = createAsyncThunk(
  'project/user/update',
  async (args: UserService.UserInfoEdit) => {
    try {
      const projectStore = cloneDeep(store.getState().global.project);
      const projectLink = projectStore.project.projectLink.link;
      const data = await UserService.editTeamMember({ ...args, projectLink });
      const users = projectStore?.users;
      const index = users?.findIndex((e: any) => e?.id === args?.userId);
      const oldUser = {
        ...store.getState().global.project.users?.[index],
      };

      const updatedUser = {
        ...oldUser,
        hashTags: data.hashTags || [],
        username: args?.username,
        role: {
          ...oldUser.role,
          projectRole: args?.role,
        },
      };
      users[index] = updatedUser;
      store.dispatch(updateProjectWithItsUser(users));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
      throw err;
    }
  }
);

export const updateSprintCapacityCalculationPreferences = createAsyncThunk(
  'project/user/sprint/preference',
  async (args: PreferenceService.PreferenceDataArgs) => {
    try {
      store.dispatch(error(false));
      const capaContributeUpdateData =
        await PreferenceService.updateCapaContributionOfProject(args);

      const projectUsers = cloneDeep(store.getState().global.project.users);

      const userIndex = projectUsers.findIndex(
        (user) => user.id === args.userId
      );
      if (userIndex >= 0) {
        const sprintIndextoUpdate = projectUsers[userIndex].sprints.findIndex(
          (sprint) => sprint.id === args.sprintId
        );

        if (sprintIndextoUpdate >= 0) {
          projectUsers[userIndex].sprints[
            sprintIndextoUpdate
          ].capacontribution = capaContributeUpdateData;
        }
      }

      store.dispatch(updateProjectWithItsUser(projectUsers));

      // update the edited sprint in sprintCapacity
      const sprintData = await CapacityService.getCapacityBySprint({
        projectLink: args.projectLink,
        sprintId: args.sprintId,
      });
      const sprintCapacity: sprintCapacityInterface[] = [];
      Object.assign(
        sprintCapacity,
        store.getState().global.sprintCapacity.sprints
      );

      const index = sprintCapacity?.findIndex(
        (e: sprintCapacityInterface) => e?.sprintId === args?.sprintId
      );
      if (index >= 0) {
        sprintCapacity[index] = {
          sprintId: args?.sprintId,
          capacity: sprintData.capacity,
          hashTags: sprintData.hashTags,
          users: sprintData.users,
          userSlots: sprintData.userSlots,
        };
      }
      store.dispatch(sprintCapacityUpdate(sprintCapacity));
    } catch (err: any) {
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
      throw err;
    }
  }
);

export const updateSprintCapacityCalculationPreferencesAllSprints =
  createAsyncThunk(
    'project/user/sprintall/preference',
    async (args: PreferenceService.PreferenceDataArgsAllSprints) => {
      try {
        store.dispatch(error(false));
        const capaContributeUpdateData =
          await PreferenceService.updateCapaContributionOfProjectAllSprints(
            args
          );

        const projectUsers = cloneDeep(store.getState().global.project.users);

        const userIndex = projectUsers.findIndex(
          (user) => user.id === args.userId
        );
        if (userIndex >= 0) {
          projectUsers[userIndex].sprints = capaContributeUpdateData;
        }

        store.dispatch(updateProjectWithItsUser(projectUsers));

        // update the edited sprint in sprintCapacity
        const sprints: ProjectModel.Sprint[] = [
          ...store.getState().global.project.sprints,
        ];

        for (let i = 0; i < sprints.length; i++) {
          const sprintCapacity: sprintCapacityInterface[] = [];

          Object.assign(
            sprintCapacity,
            store.getState().global.sprintCapacity.sprints
          );
          const sprintId = sprintCapacity[i].sprintId;

          const sprintData = await CapacityService.getCapacityBySprint({
            projectLink: args.projectLink,
            sprintId: sprintCapacity[i].sprintId,
          });

          sprintCapacity[i] = {
            sprintId: sprintId,
            capacity: sprintData.capacity,
            hashTags: sprintData.hashTags,
            users: sprintData.users,
            userSlots: sprintData.userSlots,
          };
          store.dispatch(sprintCapacityUpdate(sprintCapacity));
        }
      } catch (err: any) {
        if (err.request) {
          store.dispatch(globalError('Server antwortet nicht!'));
        }
        throw err;
        // throw rejectWithValue(err);
      }
    }
  );

export const createEvent = createAsyncThunk(
  'project/event/create',
  async (args: EventService.eventData) => {
    try {
      store.dispatch(error(false));
      store.dispatch(loading(true));
      const data = await EventService.createEvent(args);
      const events = [...store.getState().global.project.events];
      events.push(data);
      store.dispatch(updateProjectWithItsEvents(events));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const editEvent = createAsyncThunk(
  'project/event/edit',
  async (args: EventService.eventData) => {
    try {
      store.dispatch(error(false));
      store.dispatch(loading(true));
      const data = await EventService.editEvent(args);

      const events = [...store.getState().global.project.events];

      const eventIndex = events.findIndex((ev) => ev.id === data.id);

      if (eventIndex > -1) {
        events[eventIndex] = data;
      }

      store.dispatch(updateProjectWithItsEvents(events));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const deleteEvent = createAsyncThunk(
  'project/event/delete',
  async (eventId: number) => {
    try {
      store.dispatch(error(false));
      store.dispatch(loading(true));
      const data = await EventService.deleteEvent(eventId);

      const events = [...store.getState().global.project.events];

      const eventIndex = events.findIndex((ev) => ev.id === data.id);

      if (eventIndex > -1) {
        events[eventIndex] = data;
      }

      store.dispatch(updateProjectWithItsEvents(events));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const editSubevent = createAsyncThunk(
  'project/subevent/edit',
  async (args: EventService.subeventData) => {
    try {
      store.dispatch(error(false));
      store.dispatch(loading(true));
      const data = await EventService.editSubevent(args);

      const events = [...store.getState().global.project.events];

      const eventIndex = events.findIndex((ev) => ev.id === data.event?.id);
      if (eventIndex > -1) {
        const event = { ...events[eventIndex] };
        const subevents = [...event.subevent];

        const subeventIndex = subevents.findIndex((sb) => sb.id === data.id);

        if (subeventIndex > -1) {
          subevents[subeventIndex] = data;

          if (subevents[subeventIndex].event) {
            delete subevents[subeventIndex].event;
          }
          event.subevent = subevents;
        }
        events[eventIndex] = event;
      }

      store.dispatch(updateProjectWithItsEvents(events));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const deleteSubevent = createAsyncThunk(
  'project/subevent/delete',
  async (subevent: SubEvent) => {
    try {
      store.dispatch(error(false));
      store.dispatch(loading(true));
      const data = await EventService.deleteSubevent(subevent.id);

      const events = [...store.getState().global.project.events];

      const eventIndex = events.findIndex((ev) => ev.id === data.event?.id);
      if (eventIndex > -1) {
        const event = { ...events[eventIndex] };
        const subevents = [...event.subevent];

        const subeventIndex = subevents.findIndex((sb) => sb.id === data.id);

        if (subeventIndex > -1) {
          subevents[subeventIndex] = data;
          event.subevent = subevents;
        }
        events[eventIndex] = event;
      }

      store.dispatch(updateProjectWithItsEvents(events));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
      throw err;
    } finally {
      store.dispatch(loading(false));
    }
  }
);

export const addHashTag = createAsyncThunk(
  'project/hashtag/add',
  async (hashTag: string) => {
    try {
      const hashTags = [...store.getState().global.project.hashTags];
      const projectDetails = store.getState().global.project.project;

      const args = {
        projectId: projectDetails.id,
        projectLink: projectDetails.projectLink.link,
        hashTag,
      };

      const data = await HashtagService.createHashTag(args);
      hashTags.push(data);
      store.dispatch(updateProjectWithItsHashTags(hashTags));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
        throw err;
      } else {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);

export const removeHashTagFromStore = createAsyncThunk(
  'project/hashTag/remove',
  async (hashTagId: number) => {
    try {
      const hashTags = [...store.getState().global.project.hashTags];
      const delIndex = hashTags.findIndex((ht) => ht.id === hashTagId);
      const projectDetails = store.getState().global.project.project;

      const args = {
        projectId: projectDetails.id,
        projectLink: projectDetails.projectLink.link,
        hashTagId,
      };

      await HashtagService.deleteHashTag(args);

      if (delIndex > -1) {
        hashTags.splice(delIndex, 1);
      }
      store.dispatch(updateProjectWithItsHashTags(hashTags));

      const prevData = store.getState().global.project.users;

      const newData = prevData.map((user) => {
        const newUserUser = { ...user };

        const delIndex = newUserUser.hashTags.findIndex(
          (ht) => ht.id === hashTagId
        );

        if (delIndex > -1) {
          const newHash = [...newUserUser.hashTags];
          newHash.splice(delIndex, 1);
          newUserUser.hashTags = newHash;
        }

        return { ...user, user: newUserUser };
      });

      store.dispatch(updateProjectWithItsUser(newData));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
        throw err;
      } else {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);

export const getSprintEfficiencyAndCalculation = createAsyncThunk(
  'project/fetch/sprintEfficiencyAndCalculation',
  async (args: SprintService.GetSprintEfficiencyAndCalculationArgs) => {
    try {
      const data = await SprintService.getSprintEfficiencyAndCalculation(args);

      store.dispatch(sprintEfficiencyAndCalculationUpdate(data));
    } catch (err: any) {
      if (err.response) {
        throw err;
      }
      if (err.request) {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);

export const getProjectOffDaysList = createAsyncThunk(
  'project/offDay/fetch',
  async (args: { projectId: string | number; projectLink: string }) => {
    try {
      const data = await offDayService.getOffDaySList(
        args.projectId,
        args.projectLink
      );
      store.dispatch(updateProjectWithItsOffDays(data));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
        throw err;
      } else {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);

export const addOffDay = createAsyncThunk(
  'project/offDay/add',
  async (offDay: offDayService.createoffDayArgs) => {
    try {
      const offDays = [...store.getState().global.project.offDays];
      const projectDetails = store.getState().global.project.project;
      const data = await offDayService.createOffDay(
        offDay,
        projectDetails.id,
        projectDetails.projectLink.link
      );
      offDays.push(data);
      store.dispatch(updateProjectWithItsOffDays(offDays));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
        throw err;
      } else {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);

export const updateOffDay = createAsyncThunk(
  'project/offDay/update',
  async (offDay: offDayService.createoffDayArgs) => {
    try {
      const offDays = [
        ...store
          .getState()
          .global.project.offDays.filter((it) => it.id !== offDay.id),
      ];
      const projectDetails = store.getState().global.project.project;
      const data = await offDayService.updateOffDay(
        offDay,
        projectDetails.id,
        projectDetails.projectLink.link
      );
      offDays.push(data);
      store.dispatch(updateProjectWithItsOffDays(offDays));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
        throw err;
      } else {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);

export const deleteOffDay = createAsyncThunk(
  'project/offDay/delete',
  async (args: number) => {
    try {
      const offDays = [
        ...store
          .getState()
          .global.project.offDays.filter((it) => it.id !== args),
      ];

      const projectDetails = store.getState().global.project.project;
      await offDayService.deleteOffDay(
        args,
        projectDetails.id,
        projectDetails.projectLink.link
      );

      store.dispatch(updateProjectWithItsOffDays(offDays));
    } catch (err: any) {
      if (err.response) {
        store.dispatch(
          globalError(err.response.data.error || err.response.statusText)
        );
        throw err;
      } else {
        store.dispatch(globalError('Server antwortet nicht!'));
      }
    }
  }
);
