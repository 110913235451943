import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CreateSprintModel from 'models/CreateSprintModel';

export interface CreateSprintStoreState {
  sprint: CreateSprintModel;
  hasErrored: boolean;
  isLoading: boolean;
}

export const initialState: CreateSprintStoreState = {
  sprint: {
    intervalTime: '',
    sprintStartDate: '',
  },
  hasErrored: false,
  isLoading: false,
};

const createSprintSlice = createSlice({
  name: 'sprint',
  initialState,
  reducers: {
    reset: () => initialState,
    error(state, action: PayloadAction<boolean>) {
      state.hasErrored = action.payload;
    },
    loading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    create(state, action: PayloadAction<CreateSprintModel>) {
      state.sprint = action.payload;
    },
  },
});

export const { create, error, loading, reset } = createSprintSlice.actions;
export default createSprintSlice.reducer;
