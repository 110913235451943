import React, { useState } from 'react';
import { GridColumn } from '@atlaskit/page';
import Button from '@atlaskit/button';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import EmojiFrequentIcon from '@atlaskit/icon/glyph/emoji/frequent';
import OverviewIcon from '@atlaskit/icon/glyph/overview';
import { renderParticipants } from 'components/Modals/EventDetailModal/Info';
import { Event, SubEvent } from 'models/ProjectModel';
import { useReduxState } from 'hooks/useRedux';
import { getGermanFormatDate, getStartEndEventTime } from 'utils/date';
import './Event.scss';
import EventModalEdit from 'components/Modals/EventModal/edit';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';

interface Props {
  event: Event;
  subEvent: SubEvent;
}
export const EventCard: React.FC<Props> = (props) => {
  const { event, subEvent } = props;

  const [editEvent, setEditEvent] = useState<boolean>(false);

  const changeEditEvent = () => setEditEvent(!editEvent);
  const { locale } = useReduxState();

  const renderSubEvents = () => {
    const formattedDate = getGermanFormatDate(subEvent.time);
    const { startTime, endTime } = getStartEndEventTime(
      subEvent.time,
      subEvent.duration
    );

    return (
      <div key={subEvent.id}>
        <div className="event-info__calendar event-info__item">
          <div className="event-info__calendar-date">
            <CalendarIcon label="Date" size="medium"></CalendarIcon>
            <span>{formattedDate}</span>
          </div>
          <div className="event-info__calendar-time">
            <EmojiFrequentIcon label="Time" size="medium"></EmojiFrequentIcon>
            <span>
              {startTime} - {endTime}
            </span>
          </div>
        </div>
        {subEvent.link && (
          <div className="event-info__item">
            <h4>Event URL</h4>
            <a
              href={
                subEvent.link.indexOf('http://') == 0 ||
                subEvent.link.indexOf('https://') == 0
                  ? subEvent.link
                  : 'http://' + subEvent.link
              }
              target="_blank"
              rel="noreferrer">
              {subEvent.link}
            </a>
          </div>
        )}
        <div className="event-info__item">
          <h4>{locale?.strings?.participants}</h4>
          {renderParticipants(subEvent?.users)}
        </div>
      </div>
    );
  };

  return (
    <>
      {editEvent && (
        <EventModalEdit
          close={changeEditEvent}
          eventDetails={event}
          subEvent={subEvent}
        />
      )}
      <GridColumn medium={4} key={event?.id}>
        <div className="c-card">
          <div className="c-card__header">
            <div className="flex">
              <h3>{event?.abbreviation}</h3>
              <span className="abbrev">- {event?.name}</span>
            </div>
          </div>
          <div className="c-card__content">
            <div className="event-info">
              <div className="event-info__item event-info__interval">
                <OverviewIcon label="Interval" size="medium"></OverviewIcon>
                {event?.recurring && event?.interval ? (
                  <span>
                    {event?.interval === 1
                      ? locale?.strings?.event_occurance_every_day
                      : event?.interval === 7
                      ? locale?.strings?.event_occurance_single_info
                      : locale?.strings?.event_occurance_multi_info.replace(
                          ':n',
                          event?.interval.toString()
                        )}
                  </span>
                ) : (
                  <span>{locale?.strings?.non_recurring_event_info}</span>
                )}
              </div>
              {renderSubEvents()}
              <Button
                appearance="subtle"
                iconBefore={<EditFilledIcon label="Edit icon" size="small" />}
                onClick={changeEditEvent}>
                {locale?.strings?.edit}
              </Button>
            </div>
          </div>
        </div>
      </GridColumn>
    </>
  );
};
