import { environment } from 'utils/environment';
import axios from 'axios';
import {
  CapaContributionResponse,
  SprintsCapaContributionAllResponse,
} from 'models/ResponseModel';

export type PreferenceDataArgs = {
  projectId: number;
  sprintId: number;
  projectLink: string;
  userId: number;
  relevant: boolean;
};

export type PreferenceDataArgsAllSprints = {
  projectId: number;
  projectLink: string;
  userId: number;
  relevant: boolean;
};

export const updateCapaContributionOfProject = async (
  args: PreferenceDataArgs
) => {
  const { data } = await axios.put<CapaContributionResponse>(
    `${environment.API_BASE_URL}capa/${args?.projectId}/${args?.sprintId}/?projectLink=${args.projectLink}`,
    {
      userId: args?.userId,
      relevant: args?.relevant,
    }
  );
  return data;
};

export const updateCapaContributionOfProjectAllSprints = async (
  args: PreferenceDataArgsAllSprints
) => {
  const { data } = await axios.put<SprintsCapaContributionAllResponse[]>(
    `${environment.API_BASE_URL}capa/all/${args?.projectId}/?projectLink=${args.projectLink}`,
    {
      userId: args?.userId,
      relevant: args?.relevant,
    }
  );
  return data;
};
