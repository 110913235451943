import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addUser } from 'store/slice/project';
import TeamMemberModal from 'components/Modals/TeamMemberModal';
import { useReduxState } from 'hooks/useRedux';
import Alert from 'lib/Alert';
import { CustomAddIcon, CustomButton } from './NewUserUtils';
import './NewUser.scss';

export interface Props {
  fromIntroBlock?: boolean;
}

export interface FormData {
  username: string;
  role: string;
  hashTags?: number[];
}

interface PostData {
  projectId: number;
  projectLink: string;
  username: string;
  role: string;
  hashTags?: number[];
}

const NewUser: React.FC<Props> = (props) => {
  const { fromIntroBlock } = props;

  const {
    project: { users, project },
    locale,
  } = useReduxState();

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [maxUsers, setMaxUsers] = useState<boolean>(false);

  const open = () => {
    //FIXME: hardcoded user limitation
    userCount() >= 20 && setMaxUsers(true);
    setIsOpen(true);
  };
  const close = () => setIsOpen(false);
  const onFormSubmit = (data: FormData) => {
    const postData: PostData = {
      projectId: project?.id,
      projectLink: project?.projectLink.link,
      username: data.username,
      role: data.role,
      hashTags: data.hashTags,
    };

    // FIXME: Cant perfrom async awaits in cc of React --> No Solution yet
    // Workaround -> Action from redux
    // this.props.updateProject(UserService.postUser(postData));
    dispatch(addUser(postData));
    setIsOpen(false);
  };

  const userCount = (): number => {
    let counter = 0;
    for (const user of users) {
      if (user.role.type === 'user') {
        counter++;
      }
    }
    return counter;
  };

  return (
    <div>
      {fromIntroBlock && <CustomButton open={open} />}
      {users?.length > 1 && !fromIntroBlock && <CustomAddIcon open={open} />}

      {/* Modal to create a User */}

      {isOpen && !maxUsers ? (
        <TeamMemberModal close={close} onFormSubmit={onFormSubmit} />
      ) : (
        //Modal if the maximum User Limit is reached

        isOpen && (
          <Alert
            actions={[{ text: 'schließen', onClick: close }]}
            onClose={close}
            heading="Userlimit erreicht"
            appearance="warning">
            {locale?.strings?.validation_max_users.replace(':n', '20')}
          </Alert>
        )
      )}
    </div>
  );
};

export default NewUser;
