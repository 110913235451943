import React, { Fragment, useState } from 'react';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import EmojiFrequentIcon from '@atlaskit/icon/glyph/emoji/frequent';
import OverviewIcon from '@atlaskit/icon/glyph/overview';
import Button from '@atlaskit/button';
import { useReduxState } from 'hooks/useRedux';
import { User } from 'models/ProjectModel';
import { getGermanFormatDate, getStartEndEventTime } from 'utils/date';
import Modal, { Header, Body, Footer, ModalBackDrop } from 'lib/Modal';
import '../ModalStyle.scss';
import Textfield from '@atlaskit/textfield';
import Form, { ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
import {
  filterCalenderDate,
  getValidationState,
  useParticipantsOptions,
  validate,
} from '../EventModal/EventUtils';
import { DatePicker, TimePicker } from '@atlaskit/datetime-picker';
import Select from '@atlaskit/select';
import ParticipantOption, {
  filterOptions,
  ParticipantOptions,
} from '../EventModal/Participants';
import { useDispatch } from 'react-redux';
import {
  deleteSubevent,
  editEvent,
  editSubevent,
  getProjectDetails,
} from 'store/slice/project';
import Alert, { Actions } from 'lib/Alert';
import moment from 'moment';
import UserAvatarGroup from 'components/UserAvatarGroup';
export interface EventIds {
  parentId: number | null;
  id: number | null;
}

interface IFormValues {
  eventStartDate: string;
  eventStartTime: string;
  eventEndTime: string;
  participants: Array<ParticipantOptions>;
  link?: string;
}

export interface SubEvent {
  id: number;
  duration: number;
  link?: string;
  time: Date;
  inactive: boolean;
  users: User[];
}

export interface EventState {
  event: EventIds[] | null;
  todaysEventList: EventList[] | null;
}

interface WarningState {
  show: boolean;
  values: IFormValues | undefined;
}

interface EventList {
  id: number;
  name: string;
  abbreviation: string;
  recurring: boolean;
  interval?: number;
  subevent: SubEvent;
}
interface Props {
  close: () => void;
  eventState: EventState;
}

export const renderParticipants = (participants: User[]) => {
  const userGroup = participants?.map((user) => {
    return {
      key: user.id,
      email: user.email,
      name: user.username,
    };
  });

  return <UserAvatarGroup data={userGroup} />;
};

const EventDetailModal: React.FC<Props> = (props) => {
  const {
    close,
    eventState: { event, todaysEventList: defaultTodaysEventList },
  } = props;

  const {
    locale,
    project: {
      events,
      project: { id: projectId, projectLink },
    },
  } = useReduxState();

  const dispatch = useDispatch();

  const [edit, setEdit] = useState<boolean>(false);
  const [editWarning, setEditWarning] = useState<WarningState>({
    show: false,
    values: undefined,
  });

  const closeEditWarning = () =>
    setEditWarning({ show: false, values: undefined });

  const [deleteWarning, setDeleteWarning] = useState<boolean>(false);
  const changeDeleteWarning = () => setDeleteWarning(!deleteWarning);

  const onDeleteSubevent = async () => {
    if (todaysFirstEvent) {
      try {
        dispatch(deleteSubevent(todaysFirstEvent.subevent));

        dispatch(
          getProjectDetails({
            projectLink: projectLink.link,
            projectId: projectId,
          })
        );
      } catch (err) {
        console.log({ err });
      }
    }
  };

  const actions: Actions[] = [
    {
      appearance: 'danger',
      text: locale?.strings?.delete,
      onClick: onDeleteSubevent,
    },
    {
      text: locale?.strings?.cancel,
      onClick: changeDeleteWarning,
    },
  ];

  const changeForAll = async () => {
    if (todaysFirstEvent && editWarning.values) {
      try {
        const startTime = editWarning.values?.eventStartTime
          .split(':')
          .map((e) => Number(e));
        const endTime = editWarning.values?.eventEndTime
          .split(':')
          .map((e) => Number(e));
        const date = new Date(
          new Date(editWarning.values?.eventStartDate).setHours(startTime[0])
        ).setMinutes(startTime[1]);
        const duration =
          (endTime[0] - startTime[0]) * 60 + (endTime[1] - startTime[1]); // minutes
        const eventObject = {
          duration,
          time: new Date(date).toISOString(),
          users: editWarning.values?.participants.map((user) => {
            return user.value;
          }),
          link: editWarning.values?.link,
          eventId: todaysFirstEvent.id,
          name: todaysFirstEvent.name,
          abbreviation: todaysFirstEvent.abbreviation,
          recurring: todaysFirstEvent.recurring,
          interval: todaysFirstEvent.recurring
            ? todaysFirstEvent.interval
            : undefined,
        };

        await dispatch(editEvent(eventObject));
      } catch (err) {
        console.log({ err });
      }
    }
  };

  const changeForSingle = async () => {
    if (todaysFirstEvent && editWarning.values) {
      changeSingle(editWarning.values);
    }
  };

  const changeSingle = async (values: IFormValues) => {
    if (todaysFirstEvent) {
      try {
        const startTime = values?.eventStartTime
          .split(':')
          .map((e) => Number(e));
        const endTime = values?.eventEndTime.split(':').map((e) => Number(e));
        const date = new Date(
          new Date(values?.eventStartDate).setHours(startTime[0])
        ).setMinutes(startTime[1]);
        const duration =
          (endTime[0] - startTime[0]) * 60 + (endTime[1] - startTime[1]); // minutes
        const eventObject = {
          duration,
          time: new Date(date).toISOString(),
          users: values?.participants.map((user) => {
            return user.value;
          }),
          link: values?.link,
          subeventId: todaysFirstEvent.subevent.id,
        };

        await dispatch(editSubevent(eventObject));
      } catch (err) {
        console.log({ err });
      }
    }
  };

  const actionsEditWarning: Actions[] = [
    {
      appearance: 'warning',
      text: 'Save for all',
      onClick: changeForAll,
    },
    {
      text: 'Save',
      onClick: changeForSingle,
    },
  ];

  const changeEdit = () => setEdit(!edit);

  const todaysEventList: EventList[] | undefined =
    defaultTodaysEventList ||
    event?.reduce((t: any[], event): any => {
      let tdEvent = {};
      const ev = events?.find((e) => {
        return event.parentId === e.id;
      });
      if (ev) {
        const subev = ev.subevent.find((e) => e.id === event.id);
        tdEvent = { ...ev, subevent: subev };
        if (subev) {
          t.push(tdEvent);
        }
      }
      return t;
    }, []);

  const { OPTIONS: userIntervalOptions } = useParticipantsOptions();

  const todaysFirstEvent = todaysEventList?.[0];

  const onSubmitEdit = async (values: IFormValues) => {
    if (!todaysFirstEvent?.recurring) {
      changeSingle(values);
    } else {
      setEditWarning({ show: true, values });
    }
  };

  const renderEventsDetails = () => {
    if (!todaysFirstEvent) return null;

    const formattedDate = getGermanFormatDate(todaysFirstEvent.subevent.time);
    const { startTime, endTime } = getStartEndEventTime(
      todaysFirstEvent.subevent.time,
      todaysFirstEvent.subevent.duration
    );

    return (
      <div className="event-info" key={todaysFirstEvent?.id}>
        <div className="event-info__calendar event-info__item">
          <div className="event-info__calendar-date">
            <CalendarIcon label="Date" size="medium"></CalendarIcon>
            <span>{formattedDate}</span>
          </div>
          <div className="event-info__calendar-time">
            <EmojiFrequentIcon label="Time" size="medium"></EmojiFrequentIcon>
            <span>
              {startTime} - {endTime}
            </span>
          </div>
        </div>
        {todaysFirstEvent?.recurring && todaysFirstEvent?.interval && (
          <div className="event-info__item event-info__interval">
            <OverviewIcon label="Interval" size="medium"></OverviewIcon>
            <span>
              {todaysFirstEvent?.interval === 1
                ? locale?.strings?.event_occurance_every_day
                : todaysFirstEvent?.interval === 7
                ? locale?.strings?.event_occurance_single_info
                : locale?.strings?.event_occurance_multi_info.replace(
                    ':n',
                    todaysFirstEvent?.interval.toString()
                  )}
            </span>
          </div>
        )}
        {todaysFirstEvent?.subevent?.link && (
          <div className="event-info__item">
            <h4>Event URL</h4>
            <a
              href={
                todaysFirstEvent.subevent.link.indexOf('http://') == 0 ||
                todaysFirstEvent.subevent.link.indexOf('https://') == 0
                  ? todaysFirstEvent.subevent.link
                  : 'http://' + todaysFirstEvent.subevent.link
              }
              target="_blank"
              rel="noreferrer">
              {todaysFirstEvent.subevent.link}
            </a>
          </div>
        )}
        <div className="event-info__item">
          <h4>{locale?.strings?.participants}</h4>
          {renderParticipants(todaysFirstEvent?.subevent?.users)}
        </div>
      </div>
    );
  };

  const renderEventsEdit = () => {
    if (!todaysFirstEvent) return null;

    const { startTime, endTime } = getStartEndEventTime(
      todaysFirstEvent.subevent.time,
      todaysFirstEvent.subevent.duration
    );

    const defaultSelectedParticipant = userIntervalOptions.filter((option) => {
      if (
        todaysFirstEvent.subevent.users.find((user) => user.id === option.value)
      )
        return true;

      return false;
    });

    return (
      <div className="form-fields">
        <div className="form-row">
          <div className="form-row__col">
            <Field
              isRequired
              label={locale?.strings?.event_date}
              name="eventStartDate"
              defaultValue={new Date(
                todaysFirstEvent.subevent.time
              ).toISOString()}
              validate={(value) =>
                !value
                  ? locale?.strings?.validation_invalid_date
                  : filterCalenderDate(value)
                  ? locale?.strings?.validation_invalid_date
                  : undefined
              }>
              {({ fieldProps, error }) => (
                <Fragment>
                  <DatePicker
                    {...fieldProps}
                    placeholder={new Date().toLocaleDateString()}
                    disabledDateFilter={filterCalenderDate}
                    weekStartDay={1}
                  />

                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </Fragment>
              )}
            </Field>
          </div>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <div className="form-row__col">
            <Field
              isRequired={true}
              label={locale?.strings?.start_time}
              defaultValue={startTime}
              name="eventStartTime"
              validate={(value) =>
                !value ? locale?.strings?.validation_invalid_time : undefined
              }>
              {({ fieldProps, error }) => (
                <Fragment>
                  <TimePicker
                    placeholder={'9:30'}
                    timeFormat="HH:mm"
                    timeIsEditable
                    {...fieldProps}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </Fragment>
              )}
            </Field>
          </div>

          <span>&nbsp;&nbsp;&nbsp;</span>
          <div className="form-row__col">
            <Field
              isRequired={true}
              label={locale?.strings?.end_time}
              name="eventEndTime"
              defaultValue={endTime}
              validate={(value, state: any) => {
                const startArray = (
                  state?.eventStartTime ? state?.eventStartTime : '0:0'
                )
                  .split(':')
                  .map((t: string) => Number(t || 0));
                const endArray = (value ? value : '0:0')
                  .split(':')
                  .map((t: string) => Number(t || 0));
                const isTimeValid =
                  endArray[0] > startArray[0] ||
                  (endArray[0] === startArray[0] &&
                    endArray[1] > startArray[1]);
                const duration =
                  (endArray[0] - startArray[0]) * 60 +
                  (endArray[1] - startArray[1]); // minutes

                const isDurationValid =
                  todaysFirstEvent.interval === 1
                    ? duration >= 15 && duration <= 240 // 15 mins- 4hour
                    : duration >= 60 && duration <= 480; // 1hour - 8hour
                return !value
                  ? locale?.strings?.validation_invalid_time
                  : !isTimeValid
                  ? locale?.strings?.validation_end_time_error
                  : !isDurationValid && todaysFirstEvent.interval === 1
                  ? locale?.strings
                      ?.validation_event_duration_range_error_for_dailys
                  : !isDurationValid
                  ? locale?.strings?.validation_event_duration_range_error
                  : undefined;
              }}>
              {({ fieldProps, error }) => (
                <Fragment>
                  <TimePicker
                    placeholder={'11:30'}
                    timeFormat="HH:mm"
                    timeIsEditable
                    {...fieldProps}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </Fragment>
              )}
            </Field>
          </div>
        </div>

        <Field
          isRequired
          label={locale?.strings?.participants}
          name="participants"
          defaultValue={defaultSelectedParticipant}
          validate={validate()}>
          {({ fieldProps, error, meta: { valid } }: any) => (
            <Fragment>
              <Select
                {...fieldProps}
                isMulti
                defaultValue={defaultSelectedParticipant}
                getOptionValue={filterOptions}
                formatOptionLabel={ParticipantOption}
                options={userIntervalOptions}
                placeholder={locale?.strings?.event_participants}
                validationState={getValidationState(error, valid)}
                shouldFitContainer
              />
              {error === 'EMPTY' && (
                <ErrorMessage>
                  {locale?.strings?.validation_participant_min_error.replace(
                    ':n',
                    '2'
                  )}
                </ErrorMessage>
              )}
            </Fragment>
          )}
        </Field>

        <Field
          label={locale?.strings?.event_link}
          name="link"
          defaultValue={todaysFirstEvent?.subevent.link || ''}
          validate={(value) =>
            value &&
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
              value
            ) !== true
              ? locale?.strings?.validation_url
              : undefined
          }>
          {({ fieldProps, error }) => (
            <Fragment>
              <Textfield {...fieldProps} />

              {error && <ErrorMessage>{error}</ErrorMessage>}
            </Fragment>
          )}
        </Field>
      </div>
    );
  };

  return (
    <>
      {deleteWarning && (
        <Alert
          appearance="danger"
          actions={actions}
          onClose={changeDeleteWarning}
          heading={locale?.strings?.delete_event}>
          {locale?.strings?.delete_event_message}
        </Alert>
      )}

      {editWarning.show && (
        <Alert
          appearance="warning"
          actions={actionsEditWarning}
          onClose={closeEditWarning}
          heading={locale?.strings?.save_details}>
          {locale?.strings?.save_particular_meeting}
        </Alert>
      )}

      {edit ? (
        <Modal onClose={close}>
          <ModalBackDrop>
            <Header>
              <div className="flex">
                <h3>{todaysFirstEvent?.abbreviation}</h3>
                <span className="abbrev">- {todaysFirstEvent?.name}</span>
              </div>
            </Header>
            <Form onSubmit={onSubmitEdit}>
              {({ formProps }) => (
                <form {...formProps}>
                  <Body>{renderEventsEdit()}</Body>
                  <Footer>
                    <span></span>
                    <div>
                      <Button className="secondary-btn" onClick={changeEdit}>
                        {locale?.strings?.back}
                      </Button>
                      <Button type="submit" appearance="primary">
                        {locale?.strings?.save}
                      </Button>
                    </div>
                  </Footer>
                </form>
              )}
            </Form>
          </ModalBackDrop>
        </Modal>
      ) : (
        <Modal onClose={close} size="small">
          <ModalBackDrop onClick={close}>
            <Header>
              <h3>
                {todaysFirstEvent?.abbreviation}
                <span className="abbrev">- {todaysFirstEvent?.name}</span>
              </h3>
            </Header>
            <Body>{renderEventsDetails()}</Body>

            <Footer>
              {todaysFirstEvent &&
                moment(todaysFirstEvent.subevent.time)
                  .startOf('day')
                  .toISOString() >= moment().startOf('day').toISOString() && (
                  <div className="ct-modal__footer--border">
                    <div>
                      <Button
                        appearance="subtle"
                        iconBefore={
                          <EditFilledIcon label="Edit icon" size="small" />
                        }
                        onClick={changeEdit}>
                        {locale?.strings?.edit}
                      </Button>
                      <Button
                        appearance="subtle"
                        iconBefore={
                          <TrashIcon label="Delete icon" size="small" />
                        }
                        onClick={changeDeleteWarning}>
                        {locale?.strings?.delete}
                      </Button>
                    </div>
                  </div>
                )}
            </Footer>
          </ModalBackDrop>
        </Modal>
      )}
    </>
  );
};

export default EventDetailModal;
