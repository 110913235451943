import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SprintEfficiencyAndCalculationInterface {
  id: number;
  recommendedSprintVelocityPercentile: number;
  maxPersonDays: number;
  maxStoryPoints: number;
}

export interface SprintEfficiency {
  sprints: Array<SprintEfficiencyAndCalculationInterface>;
}

export const initialState: SprintEfficiency = {
  sprints: [],
};

const sprintEfficiencyAndCalculationSlice = createSlice({
  name: 'sprintEfficiencyAndCalculation',
  initialState,
  reducers: {
    clear(state) {
      state.sprints = [];
    },
    update(
      state,
      action: PayloadAction<SprintEfficiencyAndCalculationInterface[]>
    ) {
      state.sprints = action.payload;
    },
  },
});

export const { update, clear } = sprintEfficiencyAndCalculationSlice.actions;
export default sprintEfficiencyAndCalculationSlice.reducer;
