import React from 'react';
import Button from '@atlaskit/button';
import Select from '@atlaskit/select';
import { useReduxState } from 'hooks/useRedux';
import { update as updateLocale } from 'store/slice/locale/locale.slice';
import { useDispatch } from 'react-redux';
import './Navbar.scss';
import { useLocation, Link } from 'react-router-dom';
import { ProjectLink } from 'pages/project-page/components/project-link/ProjectLink';

type langOpts = {
  label: string;
  value: string;
};

const LANGUAGE_OPTIONS: langOpts[] = [
  { label: 'de', value: 'de' },
  { label: 'en', value: 'en' },
];

export const Navbar: React.FC = () => {
  const location = useLocation();
  const {
    locale,
    project: {
      project: {
        id,
        projectLink: { link },
      },
    },
  } = useReduxState();
  const dispatch = useDispatch();

  const changeLanguageHandler = (option: langOpts | null) => {
    if (option?.value) {
      dispatch(updateLocale(option.value));
    }
  };

  const newValue = LANGUAGE_OPTIONS?.find?.(
    (el: langOpts) => el?.value === locale?.language
  );

  return (
    <div className="container-navbar">
      <div className="container-navbar__left">
        <h2 className="container-navbar__logo">
          {link ? (
            <Link to={`/project/${id}/${link}`}>capa.team</Link>
          ) : (
            <Link to={`/`}>capa.team</Link>
          )}
        </h2>
        {link && (
          <div className="main-menu">
            {/* pleas make dynamic active class */}
            <div className="main-menu__item">
              <Link
                to={`/project/${id}/${link}/events`}
                className={
                  location.pathname.includes('/events') ? 'active' : ''
                }>
                <Button appearance="subtle">{locale?.strings?.events}</Button>
              </Link>
            </div>

            <div className="main-menu__item">
              <Link
                to={`/project/${id}/${link}/calendar`}
                className={
                  location.pathname.includes('/calendar') ? 'active' : ''
                }>
                <Button appearance="subtle">{locale?.strings?.calendar}</Button>
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className="container-navbar__right">
        <div className="container-navbar__item">
          <Select
            className="single-select"
            onChange={changeLanguageHandler}
            classNamePrefix="react-select"
            options={LANGUAGE_OPTIONS}
            value={newValue}
          />
        </div>
        <div className="container-navbar__item">
          <ProjectLink />
        </div>
      </div>
    </div>
  );
};
