import { ModalBackDrop, Modal, Header, Footer, Body } from 'lib/Modal';
import React from 'react';

import { useReduxState } from 'hooks/useRedux';
import { Capacity } from 'types/capacity';
import useCapaCalculation from 'hooks/useCapaCalculation';
import Button from '@atlaskit/button';

export interface ContainerProps {
  children: React.ReactNode;
  className?: string;
}
interface HashTagModalOverviewProps {
  close: () => void;
  sprintIndex: number;
  sprintId: number;
}
export const HashTagModalOverview: React.FC<HashTagModalOverviewProps> = (
  props
) => {
  const { close, sprintIndex, sprintId } = props;

  const {
    sprintCapacity: sprintCapacityState,
    project: { hashTags, users },
    filteredHashTags,
    locale,
  } = useReduxState();

  const useCapaCalculationHook = useCapaCalculation();

  const renderHashtags = () => {
    return hashTags.map((ht) => {
      if (
        filteredHashTags.hashTags.length > 0 &&
        !filteredHashTags.hashTags.some((fht) => fht.id === ht.id)
      )
        return null;

      const usersInThisHashTag = users
        .filter((user) =>
          user.hashTags.some((htUser) => htUser.name === ht.name)
        )
        .map((user) => user.id);

      const capacity: Capacity = { SP: 0, PT: 0 };

      usersInThisHashTag.forEach((userId: number) => {
        const capa = sprintCapacityState.sprints
          .find((sprint) => sprintId === sprint.sprintId)
          ?.users.find((user) => user.id === userId);

        if (capa) {
          capacity.SP = capacity.SP + capa.capacity.SP;
          capacity.PT = capacity.PT + capa.capacity.PT;
        }
      });

      const calculatedCapacity = useCapaCalculationHook?.calculateCapacity(
        capacity,
        sprintId
      );

      return (
        <div className="capa-overview__item" key={ht.name}>
          <span># {ht.name}</span>
          <span>{calculatedCapacity}</span>
        </div>
      );
    });
  };
  const renderTotalText = useCapaCalculationHook?.renderStringExact();

  return (
    <Modal onClose={close}>
      <ModalBackDrop onClick={close}>
        <Header>
          <h3>{locale?.strings?.hashtag_overview_of_sprint} Sprint {sprintIndex + 1}</h3>
        </Header>
        <Body>
          <div className="capa-overview-table">
            <div className="capa-overview--head m-0">
              <span>Name</span>
              <span>{renderTotalText}</span>
            </div>
            <div className="capa-overview__block">{renderHashtags()}</div>
          </div>
        </Body>
        <Footer>
          <span></span>
          <Button appearance="default" onClick={close}>
            {locale?.strings?.button_close}
          </Button>
        </Footer>
      </ModalBackDrop>
    </Modal>
  );
};
export default HashTagModalOverview;
