import { environment } from 'utils/environment';
import axios from 'axios';
import { EditUserResponse, DeleteUserResponse } from 'models/ResponseModel';
import { HashTags, User } from 'models/ProjectModel';

export type UserData = {
  projectId: number;
  projectLink: string;
  username: string;
  role: string;
  hashTags?: number[];
};
export type UserInfo = {
  userId: number;
  username: string;
  role: string;
  hashTags: HashTags[];
};

export type UserInfoEdit = {
  userId: number;
  username: string;
  role: string;
  hashTags: number[];
};

export const postUser = async (body: UserData) => {
  const data = {
    username: String(body.username),
    projectRole: String(body.role),
    hashTags: body.hashTags,
  };

  const res = await axios.post<User>(
    `${environment.API_BASE_URL}project/user/${body.projectId}?projectLink=${body.projectLink}`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json',
        responseType: 'json',
      },
    }
  );
  return res.data;
};

export interface EditTeamMemberInterface {
  userId: number;
  projectLink: string;
  username: string;
  role: string;
  hashTags?: number[];
}
/**
 *
 * @param {editTeamMember } args team member data
 */
export const editTeamMember = async (args: EditTeamMemberInterface) => {
  const res = await axios.put<EditUserResponse>(
    `${environment.API_BASE_URL}user/${args.userId}?projectLink=${args.projectLink}`,
    {
      projectRole: args?.role,
      username: args?.username,
      hashTags: args.hashTags,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        responseType: 'json',
      },
    }
  );
  return res.data;
};

export interface DeleteUserArgs {
  userId: number;
  projectLink: string;
}

export const deleteUser = async (args: DeleteUserArgs) => {
  const res = await axios.delete<DeleteUserResponse>(
    `${environment.API_BASE_URL}user/${args.userId}?projectLink=${args.projectLink}`,
    {
      headers: {
        'Content-Type': 'application/json',
        responseType: 'json',
      },
    }
  );
  return res.data;
};
