import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getProjectDetails,
  loading as projectIsLoading,
  editUser,
} from 'store/slice/project';
import TeamMemberEditModal from 'components/Modals/TeamMemberModal/Edit';
import { UserInfo, UserInfoEdit } from 'services/user.service';
import { useReduxState } from 'hooks/useRedux';
import Alert, { Actions } from 'lib/Alert';
import { getInitials } from 'utils/string';
import { getRandomColorClass } from 'utils/colors';
import { useUserProfileColors } from 'hooks/useLocalStorage';
import './Avatar.scss';

export interface AvatarProps {
  handleDelete: () => void;
  userInfo: UserInfo;
  isUserDeleted: boolean; // use this to faint or disable the ui for deleted user
}

const Avatar: React.FC<AvatarProps> = (props) => {

  const { userInfo } = props;

  const dispatch = useDispatch();

  const {
    locale,
    project: {
      project: { id: projectId, projectLink },
    },
  } = useReduxState();

  const { getUserColor, saveUserColor } = useUserProfileColors(projectId);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  const [colorClass, setColorClass] = useState<string>('');

  const openDelete = () => {
    if (projectLink.type === 'admin') {
      setIsDeleteOpen(true);
    }
  };

  const closeDelete = () => {
    setIsDeleteOpen(false);
  };

  const openEdit = () => {
    setIsEditOpen(true);
  };

  const closeEdit = () => {
    setIsEditOpen(false);
  };

  const editClick = async (data: UserInfoEdit) => {
    const submitData = { ...userInfo, ...data };
    try {
      dispatch(editUser(submitData));
    } catch (err) {
      console.log({ err });
    }
  };

  const handleDelete = async () => {
    dispatch(projectIsLoading(true));
    await props.handleDelete();

    closeDelete();
    dispatch(
      getProjectDetails({
        projectLink: projectLink.link,
        projectId: projectId,
      })
    );
  };

  const actions: Actions[] = [
    {
      appearance: 'danger',
      text: locale?.strings?.delete,
      onClick: handleDelete,
    },
    {
      text: locale?.strings?.cancel,
      onClick: closeDelete,
    },
  ];

  //Enables styles for the user deletion functionality
  const getUserTypeClass = (): string => {
    if (projectLink.type === 'admin') {
      return '-admin';
    }
    return '-user';
  };

  useEffect(() => {
    let colorClass = getUserColor(userInfo.userId);
    if (!colorClass) {
      colorClass = getRandomColorClass(props.isUserDeleted);
      saveUserColor(userInfo.userId, colorClass);
    }
    setColorClass(colorClass);
  }, []);

  return (
    <Fragment>
      <div className={'avatarContainer' + getUserTypeClass()}>
        {projectLink.type === 'admin' && !props.isUserDeleted && (
          <div className="editUser" onClick={openEdit}>
            <EditFilledIcon
              label={'EditFilledIcon'}
              size={'small'}></EditFilledIcon>
          </div>
        )}
        <div className={'avatar ' + colorClass}>
          <p>{getInitials(userInfo?.username)}</p>
        </div>
      </div>

      {isEditOpen && (
        <TeamMemberEditModal
          close={closeEdit}
          handleDelete={openDelete}
          userInfo={userInfo}
          onFormSubmit={editClick}
        />
      )}

      {isDeleteOpen && (
        <Alert
          actions={actions}
          onClose={closeDelete}
          heading={locale?.strings?.delete_user}
          appearance="danger">
          {locale?.strings?.delete_user_warning}
        </Alert>
      )}
    </Fragment>
  );
};

export default Avatar;
