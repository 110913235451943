import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { getInitials } from 'utils/string';
import { IAvatarUserGroup } from 'utils/interfaces';
import { getRandomColorClass } from 'utils/colors';
import { useUserProfileColors } from 'hooks/useLocalStorage';
import { useReduxState } from 'hooks/useRedux';

const CustomAvatar: React.FC<IAvatarUserGroup> = ({ data }) => {
  const {
    project: {
      project: { id: projectId },
    },
  } = useReduxState();
  const { getUserColor, saveUserColor } = useUserProfileColors(projectId);
  return (
    <React.Fragment>
      {data?.map((datum) => {
        let colorClass = getUserColor(datum.key);
        if (!colorClass) {
          colorClass = getRandomColorClass();
          saveUserColor(datum.key, colorClass);
        }
        return (
          <div className="c-avatar c-avatar--group" key={datum?.key}>
            <div className="c-avatar__item">
              <Tooltip content={datum.name}>
                <p className={`c-avatar__item ${colorClass} `}>
                  {getInitials(datum.name)}
                </p>
              </Tooltip>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default CustomAvatar;
