import React, { useState } from 'react';
import Button, { LoadingButton, ButtonGroup } from '@atlaskit/button';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import Form, { FormFooter } from '@atlaskit/form';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useReduxState } from 'hooks/useRedux';
import { prev } from 'store/slice/createProjectOnboarding/createProjectOnboarding.slice';
import { Checkbox } from '@atlaskit/checkbox';
import { postJiraUsers } from 'store/slice/JiraData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export interface IJiraRouteParams {
  code: string;
  state: string;
}

interface UserDetails {
  displayName: string;
  emailAddress: string;
}

const jiraUserLimit = 11;

const UsersForm: React.FC = () => {
  const {
    locale,
    actions: {
      jiraDataUserLimit: { JiraUsersList, isLoading },
    },
  } = useReduxState();

  const [userListData, setUserListData] = useState<UserDetails[]>([]);
  const dispatch = useDispatch();

  const goBack = () => {
    dispatch(prev(true));
  };

  const handleUserCheckBox = (e: any) => {
    const { value, checked } = e.target;

    if (checked) {
      if (userListData.length + 1 <= jiraUserLimit) {
        const data = Object.values(JiraUsersList).find((item) => {
          return item.displayName === value;
        });
        if (data) {
          const userData: UserDetails = {
            displayName: data.displayName,
            emailAddress: data.emailAddress,
          };
          setUserListData((prevData) => [...prevData, userData]);
        }
      } else {
        e.target.checked = false;
        toast(locale?.strings?.max_users_limit);
        return;
      }
    } else {
      setUserListData((prevData) =>
        [...prevData].filter((item) => item.displayName !== value)
      );
    }
  };

  const handleSubmit = () => {
    if (userListData.length > 0) {
      dispatch(postJiraUsers(userListData));
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {({ formProps }) => (
          <form {...formProps}>
            <div className="form-sprint-info">
              <p>{locale?.strings?.users_limit_heading}</p>
              <div className="sprint-list">
                {Object.values(JiraUsersList).map((item, index) => {
                  return (
                    <Checkbox
                      key={index}
                      value={item.displayName}
                      label={item.displayName}
                      onClick={handleUserCheckBox}
                      size="medium"
                      name="checkbox-default"
                      testId="cb-default"
                    />
                  );
                })}
              </div>
              <FormFooter>
                <ButtonGroup>
                  <Button
                    iconBefore={
                      <ChevronLeftLargeIcon label="ChevronLeft icon" />
                    }
                    onClick={goBack}>
                    {locale?.strings?.back}
                  </Button>
                  <LoadingButton
                    appearance="primary"
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={isLoading}>
                    {locale?.strings?.select_users}
                  </LoadingButton>
                </ButtonGroup>
              </FormFooter>
            </div>
          </form>
        )}
      </Form>
      <ToastContainer
        pauseOnHover={false}
        autoClose={3000}
        hideProgressBar={true}
        toastStyle={{ color: 'red' }}
      />
    </>
  );
};

export default withRouter(UsersForm);
