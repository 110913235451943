import store from 'store';
import { TAGMANAGER_EVENTS } from './constants';

interface PageViewProps {
  url: string;
  title: string;
}

export const firePageViewEvent = (props: PageViewProps) => {
  if (global.window && global.window.dataLayer) {
    const {
      global: {
        project: {
          project: {
            projectLink: { type },
          },
        },
      },
    } = store.getState();
    window.dataLayer.push({
      event: TAGMANAGER_EVENTS.PAGE_VIEW,
      page: {
        url: props.url,
        title: props.title,
        user: type,
      },
    });
  }
};
